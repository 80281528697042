<!--how it work section starts-->

<div class="container-fluid how-it-work-section">
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <div class="main-heading">{{"howItWorks"|language}}</div>
    </div>

    <div class="col-lg-3 col-md-4 col-sm-12 align-self-center first-h3">
      <div class="img-place">
        <img src="/assets/how-it-works/place-order.png" alt="place order" class="img-size">
      </div>
      <div class="place-order">
        <h3>{{"placeOrder"|language}}</h3>
        <p>{{"pickShapeSize"|language}}</p>

      </div>
    </div>
    <div class="col-lg-3 col-md-4 col-sm-12 align-self-center">
      <div class="img-place">
        <img src="/assets/how-it-works/review-artwork.png" alt="review artwork" class="img-size">
      </div>
      <div class="place-order">
        <h3>{{"reviewArtwork"|language}}</h3>
        <p>
         {{"reviewStickers"|language}}
        </p>
      </div>
    </div>

    <div class="col-lg-3 col-md-4 col-sm-12 align-self-center">
      <div class="img-place">
        <img src="/assets/how-it-works/payment.png" alt="review artwork" class="img-size">
      </div>
      <div class="place-order">
        <h3>{{"payment"|language}}</h3>
        <p>
          {{"proceedToPayment"|language}}
        </p>
      </div>
    </div>
    <div class="col-lg-3 col-md-4 col-sm-12 align-self-center last-h3">
      <div class="img-place">
        <img src="/assets/how-it-works/receive-order.png" alt="receive order" class="img-size">
      </div>
      <div class="place-order">
        <h3>{{"receiveOrder"|language}}</h3>
        <p>{{"enjoyTimelyShipping"|language}}</p>
      </div>
    </div>

  </div>
</div>


<!--how it works section ends-->
