<!--Change canvas bg-->
<div *ngIf="builder.canvas">
  <div class="card">
    <div class="card-body">
      <div class="custom-item" *ngIf="!builder.canvas.props.canvasImage">
        <div class="custom-item-title color-text">
          {{ "backgroundColor" | language }}
        </div>
        <div class="custom-item-body">
          <span
            class="set-color"
            [style.background-color]="builder.canvas.props.canvasFill"
          ></span>
          <input
            type="text"
            id="cp"
            class="form-control new-input"
            [cpToggle]="'true'"
            [cpWidth]="'auto'"
            [cpPosition]="'bottom'"
            [cpDialogDisplay]="'inline'"
            [(colorPicker)]="builder.canvas.props.canvasFill"
            (colorPickerChange)="setCanvasFill()"
            (change)="colorChange(builder.canvas.props.canvasFill)"
            [value]="prefilledColor"
            (keypress)="colorKeyup($event)"
            [(ngModel)]="prefilledColor"
            readonly
          />
          <!-- [value]="builder.canvas.props.canvasFill" -->
        </div>
      </div>
    </div>
  </div>
</div>
