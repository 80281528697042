import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ApiService} from '../services/api.service';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute, private api: ApiService) {
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(param => {
      console.log('these are params', param);
      this.getConfirmSnippet(param.oid);
    }, error => {
      console.log('Error in params');
    });
    console.log('In confirmation');
  }

  getConfirmSnippet(oid) {
    this.api.confirmKlarna(oid).subscribe((data: any) => {
      console.log('this is confirm data', data);
      if (data.status === 1) {
        this.checkout(data.result);
      }
    }, error => {

    });
  }

  checkout(snippet) {
    const checkoutContainer = document.getElementById('my-checkout-container');

    checkoutContainer.innerHTML = snippet;
    const scriptsTags = checkoutContainer.getElementsByTagName('script');
    // This is necessary otherwise the scripts tags are not going to be evaluated
    for (let i = 0; i < scriptsTags.length; i++) {
      const parentNode = scriptsTags[i].parentNode;
      const newScriptTag = document.createElement('script');
      newScriptTag.type = 'text/javascript';
      newScriptTag.text = scriptsTags[i].text;
      parentNode.removeChild(scriptsTags[i]);
      parentNode.appendChild(newScriptTag);
    }
  }


}
