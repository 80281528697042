<div>
  <!--  <div class="drag-text">Drag and Drop any files</div>-->
  <!--  <div class="or-text">Or</div>-->
  <div class="text-center mt-5">
    <label for="myFile" class="btn choose-btn">{{"chooseLocalFile"|language}}</label>
    <input (change)="readUrl($event);" (click)="resetFile($event)" accept=".jpg,.jpeg,.png,.tiff,.tif,.svg,.pdf"
      type="file" id="myFile" style="visibility: hidden;width: 100%" name="filename">

    <!--    <button class="btn choose-btn">Choose a local file</button>-->
  </div>

</div>