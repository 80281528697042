<app-header></app-header>

<!--children sticker component starts  -->
<app-children-sticker-banner></app-children-sticker-banner>
<!--children sticker component ends -->
<script></script>
<div class="container-fluid stickyParentDiv">
  <div
    class="row mt-1 child-sticker-canvas"
    id="destination"
    style="padding: 10px"
  >
    <!--  <div class="col-5 ">-->
    <div
      [class]="util.isMobile ? 'col-12 stickyImageSec' : 'col-auto'"
      class="mt-5"
    >
      <angular-editor-fabric-js
        #canvas
        style="display: block"
      ></angular-editor-fabric-js>
      <!--  </div>-->
      <!--  display:none-->
      <!--    <h3>{{texts.line1.text.length}}</h3>-->

      <div
        class="mt-5"
        [class]="util.isMobile ? 'col-12 stickyImageSec' : 'col-auto'"
        style="visibility: hidden; position: absolute"
      >
        <div
          id="child-sticker-parent"
          [ngStyle]="{
            'background-color': color,
            'background-image': 'url(' + bgUrl + ')',
            'background-repeat': 'no-repeat',
            height: ' 200px',
            width: '600px',
            'background-size': '100%',
            margin: '5px',
            'max-width': '600px'
          }"
          class="row text-center"
          style="
            min-height: 200px;
            border: 1px solid black;
            width: 100%;
            border-radius: 12px;
          "
          *ngIf="!util.isMobile"
        >
          <!--      <div class="row" *ngIf="color || bgUrl || pikaImg ||firstText.value ||secondText.value||thirdText.value"-->
          <!--         [ngStyle]="{'background-color': color, 'background-image': 'url('+bgUrl+')', 'background-repeat': 'no-repeat', -->
          <!--         'height':' 300px', 'width':'100%', 'background-size': '100%', 'margin-left': '21px'}">-->

          <div id="text-section" class="col-12 align-self-center">
            <div
              *ngIf="pikaImg.length > 1"
              class="align-self-center"
              [ngClass]="!noText() ? 'col-4' : 'col-12'"
            >
              <img
                [ngClass]="!noText() ? 'pikaImg' : 'fullpikaImg'"
                [src]="pikaImg"
                alt=""
                id="child-sticker-image"
              />
            </div>
            <div
              [ngClass]="pikaImg.length > 1 ? 'col-8' : 'col-12'"
              class="align-self-center"
              [style.text-align]="textAlign"
              [style.justify-content]="textAlign"
            >
              <div
                *ngIf="texts.line1.text"
                id="child-sticker-text"
                [ngClass]="[
                  isItalicText === true
                    ? 'isItalicText line1text'
                    : 'noItalic line1text'
                ]"
                [style.text-align]="textAlign"
                [ngStyle]="{ fontFamily: fontFamily, color: textColor }"
                [style.font-weight]="isBold ? 'bold' : '100'"
                [style.font-size]="texts.line1.size"
                [style.line-height]="texts.line1.lineHeight"
              >
                {{ texts.line1.text }}
              </div>
              <div
                *ngIf="texts.line2.text"
                [ngClass]="[
                  isItalicText === true ? 'isItalicText' : 'noItalic'
                ]"
                [style.text-align]="textAlign"
                [ngStyle]="{ fontFamily: fontFamily, color: textColor }"
                [style.font-weight]="isBold ? 'bold' : '100'"
                [style.font-size]="texts.line2.size"
                class="{{ util.getMarginClass() }}"
              >
                {{ texts.line2.text }}
              </div>
              <div
                *ngIf="texts.line3.text"
                [ngClass]="[
                  isItalicText === true ? 'isItalicText' : 'noItalic'
                ]"
                [style.text-align]="textAlign"
                [ngStyle]="{ fontFamily: fontFamily, color: textColor }"
                [style.font-weight]="isBold ? 'bold' : '100'"
                [style.font-size]="texts.line3.size"
                class="{{ util.getMarginClass() }}"
              >
                {{ texts.line3.text }}
              </div>
            </div>
          </div>
        </div>

        <div
          [ngStyle]="{
            'background-color': color,
            'background-image': 'url(' + bgUrl + ')',
            'background-repeat': 'no-repeat',
            height: ' 150px',
            width: '100%',
            'background-size': '100%',
            margin: '5px'
          }"
          class="row text-center"
          id="child-sticker-parent"
          style="
            min-height: 150px;
            border: 1px solid black;
            width: 100%;
            border-radius: 12px;
          "
          *ngIf="util.isMobile"
        >
          <div class="col-12 align-self-center" id="text-section">
            <div
              *ngIf="pikaImg.length > 1"
              class="align-self-center"
              [ngClass]="!noText() ? 'col-4' : 'col-12'"
            >
              <img
                [ngClass]="!noText() ? 'pikaImg' : 'fullpikaImg'"
                [src]="pikaImg"
                alt=""
                id="child-sticker-image"
              />
            </div>

            <div
              *ngIf="!noText()"
              [ngClass]="pikaImg.length > 1 ? 'col-8' : 'col-12'"
              class="align-self-center"
              [style.text-align]="textAlign"
              [style.justify-content]="textAlign"
            >
              <div
                *ngIf="texts.line1.text"
                [ngClass]="[
                  isItalicText === true
                    ? 'isItalicText line1text'
                    : 'noItalic line1text'
                ]"
                id="child-sticker-text"
                [style.text-align]="textAlign"
                [ngStyle]="{ fontFamily: fontFamily, color: textColor }"
                [style.font-weight]="isBold ? 'bold' : '100'"
                [style.font-size]="texts.line1.size"
                [style.line-height]="texts.line1.lineHeight"
              >
                {{ texts.line1.text }}
              </div>
              <div
                *ngIf="texts.line2.text"
                [ngClass]="[
                  isItalicText === true ? 'isItalicText' : 'noItalic'
                ]"
                [style.text-align]="textAlign"
                [ngStyle]="{ fontFamily: fontFamily, color: textColor }"
                [style.font-weight]="isBold ? 'bold' : '100'"
                [style.font-size]="texts.line2.size"
                class="{{ util.getMarginClass() }}"
              >
                {{ texts.line2.text }}
              </div>
              <div
                *ngIf="texts.line3.text"
                [ngClass]="[
                  isItalicText === true ? 'isItalicText' : 'noItalic'
                ]"
                [style.text-align]="textAlign"
                [ngStyle]="{ fontFamily: fontFamily, color: textColor }"
                [style.font-weight]="isBold ? 'bold' : '100'"
                [style.font-size]="texts.line3.size"
                class="{{ util.getMarginClass() }}"
              >
                {{ texts.line3.text }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-6 col-md-12 align-self-center mt-5 addMarginTop">
      <div class="d-flex">
        <div
          (click)="selectJuniorSize(1)"
          [class]="juniorSize.type === 1 ? 'selected' : ''"
          class="child-size"
        >
          15 * 5 mm
        </div>
        <div
          (click)="selectJuniorSize(2)"
          [class]="juniorSize.type === 2 ? 'selected' : ''"
          class="child-size ml-2"
        >
          30 * 10 mm
        </div>
      </div>
      <div>
        <div class="text">{{ "labeleGlued" | language }}</div>

        <div class="text">{{ "withStands" | language }}</div>
      </div>
      <div class="total">
        {{ "20set" | language }}
        <sub class="sub"
          >{{ juniorSize.quantity }} kpl, {{ juniorSize.width }} x
          {{ juniorSize.height }}mm</sub
        >
      </div>
      <div class="d-flex">
        <div>
          <button (click)="saveFlag = true; save()" class="btn add-cart">
            {{ "plaOrder" | language }}
          </button>
        </div>
        <div class="ml-2">
          <button (click)="clearCanvas()" class="btn clear-canvas">
            {{ "clearCanvas" | language }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <!--<div *ngIf="color || bgUrl || pikaImg ||firstText.value ||secondText.value||thirdText.value">-->
  <!--  <button class="btn-save btn-danger" (click)="save()">Save</button>-->
  <!--</div>-->

  <div class="row mt-5">
    <div class="col-lg-4 col-md-12">
      <div class="add-text">{{ "addtexts" | language }}</div>
      <div>
        <input
          maxlength="17"
          #firstText
          (input)="addText(firstText.value, 1)"
          [ngModel]="text1.text"
          class="form-control text-input"
          placeholder="{{ 'startTypingg' | language }}"
          type="text"
        />

        <!--        <input #secondText (input)="addText(secondText.value, 2)" [ngModel]="text2" class="form-control text-input"-->
        <!--               maxlength="28"-->
        <!--               placeholder="Start typing..." type="text">-->
        <!--        <input #thirdText (input)="addText(thirdText.value, 3)" [ngModel]="text3" class="form-control text-input"-->
        <!--               maxlength="28"-->
        <!--               placeholder="Start typing..." type="text">-->
      </div>

      <div class="custom-item">
        <div class="custom-item-title">{{ "text-align" | language }}</div>
        <div class="custom-item-body text-center">
          <div aria-label="..." class="btn-group" role="group">
            <button
              (click)="setTextAlign('left')"
              [ngClass]="{ active: builder.canvas?.props.textAlign == 'left' }"
              class="btn btn-primary"
              type="button"
            >
              <i class="fa fa-align-left"></i>
            </button>
            <button
              (click)="setTextAlign('center')"
              [ngClass]="{
                active: builder.canvas?.props.textAlign == 'center'
              }"
              class="btn btn-primary"
              type="button"
            >
              <i class="fa fa-align-center"></i>
            </button>
            <button
              (click)="setTextAlign('right')"
              [ngClass]="{ active: builder.canvas?.props.textAlign == 'right' }"
              class="btn btn-primary"
              type="button"
            >
              <i class="fa fa-align-right"></i>
            </button>
            <button
              (click)="setTextAlign('justify')"
              [ngClass]="{
                active: builder.canvas?.props.textAlign == 'justify'
              }"
              class="btn btn-primary"
              type="button"
            >
              <i class="fa fa-align-justify"></i>
            </button>
            <button
              (click)="setBold()"
              [ngClass]="{ active: builder.canvas?.props.fontWeight }"
              class="btn btn-primary"
              type="button"
            >
              <i class="fa fa-bold"></i>
            </button>
            <button
              (click)="setFontStyle()"
              [ngClass]="{ active: builder.canvas?.props.fontStyle }"
              class="btn btn-primary"
              type="button"
            >
              <i class="fa fa-italic"></i>
            </button>
            <button class="btn btn-primary">
              <select
                (change)="setFontFamily($event.target, firstText.value)"
                class="form-control"
              >
                <option selected value="Roboto">Roboto</option>
                <option value="Bebas Neue">Bebas Neue</option>
                <option value="Merriweather">Merriweather</option>
                <option value="Rosarivo">Rosarivo</option>
                <option value="Playfair Display">Playfair Display</option>
                <option value="Abril Fatface">Abril Fatface</option>
                <option value="Alfa Slab One">Alfa Slab One</option>
                <option value="Dancing Script">Dancing Script</option>
                <option value="Gluten">Gluten</option>
                <option value="Permanent Marker">Permanent Marker</option>
                <option value="Luckiest Guy">Luckiest Guy</option>
                <option value="UnifrakturMaguntia">UnifrakturMaguntia</option>
              </select>
            </button>
            <button class="btn btn-primary">
              <div class="custom-item-body">
                <input
                  *ngIf="!this.isTablet"
                  (colorPickerChange)="setFill($event)"
                  [(colorPicker)]="builder?.canvas?.props.fill"
                  [cpPosition]="'bottom'"
                  [value]="builder?.canvas?.props.fill"
                  class="form-control"
                  placeholder="Color"
                  type="text"
                />
                <input
                  *ngIf="this.isTablet"
                  (colorPickerChange)="setFill($event)"
                  [(colorPicker)]="builder?.canvas?.props.fill"
                  [cpPosition]="'bottom-left'"
                  [cpPositionOffset]="'100%'"
                  [cpPositionRelativeToArrow]="true"
                  [value]="builder?.canvas?.props.fill"
                  class="form-control"
                  placeholder="Color"
                  type="text"
                />
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-md-12">
      <div class="card picture-card">
        <div class="card-header add-pic-text">
          {{ "addPicture" | language }}
        </div>
        <div>
          <div>
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  aria-controls="tab1"
                  aria-selected="true"
                  class="nav-link active tab-btn"
                  data-bs-target="#tab1"
                  data-bs-toggle="tab"
                  id="li_tab1"
                  role="tab"
                  type="button"
                >
                  <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                    <path
                      d="M12,3C13.74,3 15.36,3.5 16.74,4.35C17.38,3.53 18.38,3 19.5,3A3.5,3.5 0 0,1 23,6.5C23,8 22.05,9.28 20.72,9.78C20.9,10.5 21,11.23 21,12A9,9 0 0,1 12,21A9,9 0 0,1 3,12C3,11.23 3.1,10.5 3.28,9.78C1.95,9.28 1,8 1,6.5A3.5,3.5 0 0,1 4.5,3C5.62,3 6.62,3.53 7.26,4.35C8.64,3.5 10.26,3 12,3M12,5A7,7 0 0,0 5,12A7,7 0 0,0 12,19A7,7 0 0,0 19,12A7,7 0 0,0 12,5M16.19,10.3C16.55,11.63 16.08,12.91 15.15,13.16C14.21,13.42 13.17,12.54 12.81,11.2C12.45,9.87 12.92,8.59 13.85,8.34C14.79,8.09 15.83,8.96 16.19,10.3M7.81,10.3C8.17,8.96 9.21,8.09 10.15,8.34C11.08,8.59 11.55,9.87 11.19,11.2C10.83,12.54 9.79,13.42 8.85,13.16C7.92,12.91 7.45,11.63 7.81,10.3M12,14C12.6,14 13.13,14.19 13.5,14.5L12.5,15.5C12.5,15.92 12.84,16.25 13.25,16.25A0.75,0.75 0 0,0 14,15.5A0.5,0.5 0 0,1 14.5,15A0.5,0.5 0 0,1 15,15.5A1.75,1.75 0 0,1 13.25,17.25C12.76,17.25 12.32,17.05 12,16.72C11.68,17.05 11.24,17.25 10.75,17.25A1.75,1.75 0 0,1 9,15.5A0.5,0.5 0 0,1 9.5,15A0.5,0.5 0 0,1 10,15.5A0.75,0.75 0 0,0 10.75,16.25A0.75,0.75 0 0,0 11.5,15.5L10.5,14.5C10.87,14.19 11.4,14 12,14Z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  aria-controls="tab2"
                  aria-selected="false"
                  class="nav-link tab-btn"
                  data-bs-target="#tab2"
                  data-bs-toggle="tab"
                  id="li_tab2"
                  role="tab"
                  type="button"
                >
                  <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                    <path
                      d="M12,3C13.74,3 15.36,3.5 16.74,4.35C17.38,3.53 18.38,3 19.5,3A3.5,3.5 0 0,1 23,6.5C23,8 22.05,9.28 20.72,9.78C20.9,10.5 21,11.23 21,12A9,9 0 0,1 12,21A9,9 0 0,1 3,12C3,11.23 3.1,10.5 3.28,9.78C1.95,9.28 1,8 1,6.5A3.5,3.5 0 0,1 4.5,3C5.62,3 6.62,3.53 7.26,4.35C8.64,3.5 10.26,3 12,3M12,5A7,7 0 0,0 5,12A7,7 0 0,0 12,19A7,7 0 0,0 19,12A7,7 0 0,0 12,5M16.19,10.3C16.55,11.63 16.08,12.91 15.15,13.16C14.21,13.42 13.17,12.54 12.81,11.2C12.45,9.87 12.92,8.59 13.85,8.34C14.79,8.09 15.83,8.96 16.19,10.3M7.81,10.3C8.17,8.96 9.21,8.09 10.15,8.34C11.08,8.59 11.55,9.87 11.19,11.2C10.83,12.54 9.79,13.42 8.85,13.16C7.92,12.91 7.45,11.63 7.81,10.3M12,14C12.6,14 13.13,14.19 13.5,14.5L12.5,15.5C12.5,15.92 12.84,16.25 13.25,16.25A0.75,0.75 0 0,0 14,15.5A0.5,0.5 0 0,1 14.5,15A0.5,0.5 0 0,1 15,15.5A1.75,1.75 0 0,1 13.25,17.25C12.76,17.25 12.32,17.05 12,16.72C11.68,17.05 11.24,17.25 10.75,17.25A1.75,1.75 0 0,1 9,15.5A0.5,0.5 0 0,1 9.5,15A0.5,0.5 0 0,1 10,15.5A0.75,0.75 0 0,0 10.75,16.25A0.75,0.75 0 0,0 11.5,15.5L10.5,14.5C10.87,14.19 11.4,14 12,14Z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  aria-controls="tab3"
                  aria-selected="false"
                  class="nav-link tab-btn"
                  data-bs-target="#tab3"
                  data-bs-toggle="tab"
                  id="li_tab3"
                  role="tab"
                  type="button"
                >
                  <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                    <path
                      d="M16.93 17.12L16.13 15.76L17.59 11.39L19 10.92L20 11.67C20 11.7 20 11.75 20 11.81C20 11.88 20.03 11.94 20.03 12C20.03 13.97 19.37 15.71 18.06 17.21L16.93 17.12M9.75 15L8.38 10.97L12 8.43L15.62 10.97L14.25 15H9.75M12 20.03C11.12 20.03 10.29 19.89 9.5 19.61L8.81 18.1L9.47 17H14.58L15.19 18.1L14.5 19.61C13.71 19.89 12.88 20.03 12 20.03M5.94 17.21C5.41 16.59 4.95 15.76 4.56 14.75C4.17 13.73 3.97 12.81 3.97 12C3.97 11.94 4 11.88 4 11.81C4 11.75 4 11.7 4 11.67L5 10.92L6.41 11.39L7.87 15.76L7.07 17.12L5.94 17.21M11 5.29V6.69L7 9.46L5.66 9.04L5.24 7.68C5.68 7 6.33 6.32 7.19 5.66S8.87 4.57 9.65 4.35L11 5.29M14.35 4.35C15.13 4.57 15.95 5 16.81 5.66C17.67 6.32 18.32 7 18.76 7.68L18.34 9.04L17 9.47L13 6.7V5.29L14.35 4.35M4.93 4.93C3 6.89 2 9.25 2 12S3 17.11 4.93 19.07 9.25 22 12 22 17.11 21 19.07 19.07 22 14.75 22 12 21 6.89 19.07 4.93 14.75 2 12 2 6.89 3 4.93 4.93Z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  aria-controls="tab4"
                  aria-selected="false"
                  class="nav-link tab-btn"
                  data-bs-target="#tab4"
                  data-bs-toggle="tab"
                  id="li_tab4"
                  role="tab"
                  type="button"
                >
                  <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                    <path
                      d="M16.93 17.12L16.13 15.76L17.59 11.39L19 10.92L20 11.67C20 11.7 20 11.75 20 11.81C20 11.88 20.03 11.94 20.03 12C20.03 13.97 19.37 15.71 18.06 17.21L16.93 17.12M9.75 15L8.38 10.97L12 8.43L15.62 10.97L14.25 15H9.75M12 20.03C11.12 20.03 10.29 19.89 9.5 19.61L8.81 18.1L9.47 17H14.58L15.19 18.1L14.5 19.61C13.71 19.89 12.88 20.03 12 20.03M5.94 17.21C5.41 16.59 4.95 15.76 4.56 14.75C4.17 13.73 3.97 12.81 3.97 12C3.97 11.94 4 11.88 4 11.81C4 11.75 4 11.7 4 11.67L5 10.92L6.41 11.39L7.87 15.76L7.07 17.12L5.94 17.21M11 5.29V6.69L7 9.46L5.66 9.04L5.24 7.68C5.68 7 6.33 6.32 7.19 5.66S8.87 4.57 9.65 4.35L11 5.29M14.35 4.35C15.13 4.57 15.95 5 16.81 5.66C17.67 6.32 18.32 7 18.76 7.68L18.34 9.04L17 9.47L13 6.7V5.29L14.35 4.35M4.93 4.93C3 6.89 2 9.25 2 12S3 17.11 4.93 19.07 9.25 22 12 22 17.11 21 19.07 19.07 22 14.75 22 12 21 6.89 19.07 4.93 14.75 2 12 2 6.89 3 4.93 4.93Z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  aria-controls="tab5"
                  aria-selected="false"
                  class="nav-link tab-btn"
                  data-bs-target="#tab5"
                  data-bs-toggle="tab"
                  id="li_tab5"
                  role="tab"
                  type="button"
                >
                  <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                    <path
                      d="M8.66,13.07C6.92,13.07 5.5,11.66 5.5,9.93C5.5,9.22 5.76,8.54 6.19,8C5.77,7.46 5.5,6.78 5.5,6.07C5.5,4.34 6.93,2.93 8.66,2.93L9.09,2.96C9.56,1.81 10.69,1 12,1C13.31,1 14.44,1.81 14.91,2.96L15.34,2.93C17.07,2.93 18.5,4.34 18.5,6.07C18.5,6.78 18.24,7.46 17.81,8C18.23,8.54 18.5,9.22 18.5,9.93C18.5,11.66 17.07,13.07 15.34,13.07L14.91,13.04C14.44,14.19 13.31,15 12,15C10.69,15 9.56,14.19 9.09,13.04L8.66,13.07M12,13C12.62,13 13.12,12.5 13.14,11.9L13.03,10.81C12.71,10.93 12.36,11 12,11C11.64,11 11.3,10.93 11,10.81L10.86,11.9C10.88,12.5 11.38,13 12,13M15.34,11.07C15.97,11.07 16.5,10.56 16.5,9.92C16.5,9.5 16.23,9.08 15.83,8.89L14.95,8.47C14.83,9.21 14.44,9.85 13.89,10.3L14.7,10.87C14.88,11 15.1,11.07 15.34,11.07M14.69,5.13L13.87,5.69C14.43,6.14 14.82,6.78 14.94,7.5L15.82,7.1C16.23,6.9 16.5,6.5 16.5,6.07C16.5,5.44 15.97,4.93 15.34,4.93C15.11,4.93 14.89,5 14.69,5.13M12,3C11.38,3 10.88,3.5 10.86,4.1L11,5.19C11.3,5.07 11.64,5 12,5C12.36,5 12.71,5.07 13.03,5.19L13.14,4.1C13.12,3.5 12.62,3 12,3M8.66,4.93C8.03,4.93 7.5,5.44 7.5,6.07C7.5,6.5 7.77,6.9 8.17,7.1L9.05,7.5C9.17,6.78 9.56,6.14 10.11,5.69L9.3,5.13C9.12,5 8.9,4.93 8.66,4.93M8.17,8.9C7.77,9.1 7.5,9.5 7.5,9.92C7.5,10.55 8.03,11.06 8.66,11.06C8.89,11.06 9.11,11 9.31,10.86L10.12,10.31C9.56,9.86 9.17,9.22 9.05,8.5L8.17,8.9M12,22A9,9 0 0,1 3,13A9,9 0 0,1 12,22A9,9 0 0,1 21,13A9,9 0 0,1 12,22M14.44,19.56C16.34,18.85 17.85,17.34 18.56,15.44C16.66,16.14 15.15,17.66 14.44,19.56M5.44,15.44C6.15,17.34 7.66,18.85 9.56,19.56C8.86,17.66 7.34,16.15 5.44,15.44Z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  aria-controls="tab6"
                  aria-selected="false"
                  class="nav-link tab-btn"
                  data-bs-target="#tab6"
                  data-bs-toggle="tab"
                  id="li_tab6"
                  role="tab"
                  type="button"
                >
                  <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                    <path
                      d="M20.79,13.95L18.46,14.57L16.46,13.44V10.56L18.46,9.43L20.79,10.05L21.31,8.12L19.54,7.65L20,5.88L18.07,5.36L17.45,7.69L15.45,8.82L13,7.38V5.12L14.71,3.41L13.29,2L12,3.29L10.71,2L9.29,3.41L11,5.12V7.38L8.5,8.82L6.5,7.69L5.92,5.36L4,5.88L4.47,7.65L2.7,8.12L3.22,10.05L5.55,9.43L7.55,10.56V13.45L5.55,14.58L3.22,13.96L2.7,15.89L4.47,16.36L4,18.12L5.93,18.64L6.55,16.31L8.55,15.18L11,16.62V18.88L9.29,20.59L10.71,22L12,20.71L13.29,22L14.7,20.59L13,18.88V16.62L15.5,15.17L17.5,16.3L18.12,18.63L20,18.12L19.53,16.35L21.3,15.88L20.79,13.95M9.5,10.56L12,9.11L14.5,10.56V13.44L12,14.89L9.5,13.44V10.56Z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  aria-controls="tab7"
                  aria-selected="false"
                  class="nav-link tab-btn"
                  data-bs-target="#tab7"
                  data-bs-toggle="tab"
                  id="li_tab7"
                  role="tab"
                  type="button"
                >
                  <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                    <path
                      d="M8.66,13.07C6.92,13.07 5.5,11.66 5.5,9.93C5.5,9.22 5.76,8.54 6.19,8C5.77,7.46 5.5,6.78 5.5,6.07C5.5,4.34 6.93,2.93 8.66,2.93L9.09,2.96C9.56,1.81 10.69,1 12,1C13.31,1 14.44,1.81 14.91,2.96L15.34,2.93C17.07,2.93 18.5,4.34 18.5,6.07C18.5,6.78 18.24,7.46 17.81,8C18.23,8.54 18.5,9.22 18.5,9.93C18.5,11.66 17.07,13.07 15.34,13.07L14.91,13.04C14.44,14.19 13.31,15 12,15C10.69,15 9.56,14.19 9.09,13.04L8.66,13.07M12,13C12.62,13 13.12,12.5 13.14,11.9L13.03,10.81C12.71,10.93 12.36,11 12,11C11.64,11 11.3,10.93 11,10.81L10.86,11.9C10.88,12.5 11.38,13 12,13M15.34,11.07C15.97,11.07 16.5,10.56 16.5,9.92C16.5,9.5 16.23,9.08 15.83,8.89L14.95,8.47C14.83,9.21 14.44,9.85 13.89,10.3L14.7,10.87C14.88,11 15.1,11.07 15.34,11.07M14.69,5.13L13.87,5.69C14.43,6.14 14.82,6.78 14.94,7.5L15.82,7.1C16.23,6.9 16.5,6.5 16.5,6.07C16.5,5.44 15.97,4.93 15.34,4.93C15.11,4.93 14.89,5 14.69,5.13M12,3C11.38,3 10.88,3.5 10.86,4.1L11,5.19C11.3,5.07 11.64,5 12,5C12.36,5 12.71,5.07 13.03,5.19L13.14,4.1C13.12,3.5 12.62,3 12,3M8.66,4.93C8.03,4.93 7.5,5.44 7.5,6.07C7.5,6.5 7.77,6.9 8.17,7.1L9.05,7.5C9.17,6.78 9.56,6.14 10.11,5.69L9.3,5.13C9.12,5 8.9,4.93 8.66,4.93M8.17,8.9C7.77,9.1 7.5,9.5 7.5,9.92C7.5,10.55 8.03,11.06 8.66,11.06C8.89,11.06 9.11,11 9.31,10.86L10.12,10.31C9.56,9.86 9.17,9.22 9.05,8.5L8.17,8.9M12,22A9,9 0 0,1 3,13A9,9 0 0,1 12,22A9,9 0 0,1 21,13A9,9 0 0,1 12,22M14.44,19.56C16.34,18.85 17.85,17.34 18.56,15.44C16.66,16.14 15.15,17.66 14.44,19.56M5.44,15.44C6.15,17.34 7.66,18.85 9.56,19.56C8.86,17.66 7.34,16.15 5.44,15.44Z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
              </li>
            </ul>

            <div
              class="tab-content card-body max-height max-min-height"
              id="myTabContent"
              *ngIf="childClipArt"
            >
              <div
                *ngFor="let key of objectKey(formatedTabs())"
                class="{{
                  key == 'tab1' ? 'tab-pane fade show active' : 'tab-pane fade'
                }}"
                id="{{ key }}"
                role="tabpanel"
              >
                <img
                  *ngFor="let clip of formatedTabs()[key]"
                  (click)="getImgPolaroid($event)"
                  alt=""
                  class="images-item"
                  src="{{ BASE_URL }}{{
                    clip.attributes.image.data?.attributes?.url
                  }}"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-md-12">
      <div class="card bg-card">
        <div class="card-header bg-color-text">
          {{ "backgroundColor" | language }}
        </div>
        <div class="card-body">
          <div class="row bg-section">
            <div
              (click)="setCanvasFill(bgColor.black)"
              [ngStyle]="{ 'background-color': bgColor.black }"
              class="col-lg-3 col-md-4 bg-color"
            ></div>
            <div
              (click)="setCanvasFill(bgColor.red)"
              [ngStyle]="{
                'background-color': bgColor.red,
                border: bgColor.red
              }"
              class="col-lg-3 col-md-4 bg-color"
            ></div>
            <div
              (click)="setCanvasFill(bgColor.blue)"
              [ngStyle]="{
                'background-color': bgColor.blue,
                border: bgColor.blue
              }"
              class="col-lg-3 col-md-4 bg-color"
            ></div>
            <div
              (click)="setCanvasFill(bgColor.green)"
              [ngStyle]="{
                'background-color': bgColor.green,
                border: bgColor.green
              }"
              class="col-lg-3 col-md-4 bg-color"
            ></div>
            <div
              (click)="setCanvasFill(bgColor.yellow)"
              [ngStyle]="{
                'background-color': bgColor.yellow,
                border: bgColor.yellow
              }"
              class="col-lg-3 col-md-4 bg-color"
            ></div>
            <div
              (click)="setCanvasFill(bgColor.Maroon)"
              [ngStyle]="{
                'background-color': bgColor.Maroon,
                border: bgColor.Maroon
              }"
              class="col-lg-3 col-md-4 bg-color"
            ></div>
            <div
              (click)="setCanvasFill(bgColor.Cyan)"
              [ngStyle]="{
                'background-color': bgColor.Cyan,
                border: bgColor.Cyan
              }"
              class="col-lg-3 col-md-4 bg-color"
            ></div>
            <div
              (click)="setCanvasFill(bgColor.Magenta)"
              [ngStyle]="{
                'background-color': bgColor.Magenta,
                border: bgColor.Magenta
              }"
              class="col-lg-3 col-md-4 bg-color"
            ></div>
            <div
              (click)="setCanvasFill(bgColor.Grey)"
              [ngStyle]="{
                'background-color': bgColor.Grey,
                border: bgColor.Grey
              }"
              class="col-lg-3 col-md-4 bg-color"
            ></div>
            <div
              (click)="setCanvasFill(bgColor.Pink)"
              [ngStyle]="{
                'background-color': bgColor.Pink,
                border: bgColor.Pink
              }"
              class="col-lg-3 col-md-4 bg-color"
            ></div>
            <div
              (click)="setCanvasFill(bgColor.Olive)"
              [ngStyle]="{
                'background-color': bgColor.Olive,
                border: bgColor.Olive
              }"
              class="col-lg-3 col-md-4 bg-color"
            ></div>
            <div
              (click)="setCanvasFill(bgColor.Brown)"
              [ngStyle]="{
                'background-color': bgColor.Brown,
                border: bgColor.Brown
              }"
              class="col-lg-3 col-md-4 bg-color"
            ></div>
            <div
              (click)="setCanvasFill(bgColor.Gunmetal)"
              [ngStyle]="{
                'background-color': bgColor.Gunmetal,
                border: bgColor.Gunmetal
              }"
              class="col-lg-3 col-md-4 bg-color"
            ></div>
            <div
              (click)="setCanvasImage(bgImage.imgUrl)"
              [ngStyle]="{
                'background-image': 'url(' + bgImage.imgUrl + ')',
                'background-repeat': 'no-repeat',
                height: ' 40px',
                'background-size': '100% 50px'
              }"
              class="col-lg-3 col-md-4 bg-color"
            ></div>
            <div
              (click)="setCanvasImage(bgImage.imgUrl2)"
              [ngStyle]="{
                'background-image': 'url(' + bgImage.imgUrl2 + ' )',
                'background-repeat': 'no-repeat',
                height: ' 40px',
                'background-size': '100% 50px'
              }"
              class="col-lg-3 col-md-4 bg-color"
            ></div>
            <div
              (click)="setCanvasImage(bgImage.imgUrl3)"
              [ngStyle]="{
                'background-image': 'url(' + bgImage.imgUrl3 + ' )',
                'background-repeat': 'no-repeat',
                height: ' 40px',
                'background-size': '100% 50px'
              }"
              class="col-lg-3 col-md-4 bg-color"
            ></div>
            <div
              (click)="setCanvasImage(bgImage.imgUrl4)"
              [ngStyle]="{
                'background-image': 'url(' + bgImage.imgUrl4 + ' )',
                'background-repeat': 'no-repeat',
                height: ' 40px',
                'background-size': '100% 50px'
              }"
              class="col-lg-3 col-md-4 bg-color"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-how-it-works></app-how-it-works>
<!--include footer component-->
<app-footer></app-footer>
<!--include footer component-->
