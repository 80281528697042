import { fabric } from "fabric";
export class ExtendedFabricRect extends fabric.Rect {
  id: string;

  constructor(options?: fabric.IRectOptions) {
    super(options);
  }
}

export class ExtendedFabricEllipse extends fabric.Ellipse {
  id: string;

  constructor(options?: fabric.IEllipseOptions) {
    super(options);
  }
}

export class ExtendedFabricImage extends fabric.Image {
  id: string;

  constructor(element: HTMLImageElement, options?: fabric.IImageOptions) {
    super(element, options);
  }
}

export class ExtendedFabricText extends fabric.Text {
  id: string;
  isEditing: boolean;

  constructor(text: string, options?: fabric.ITextOptions) {
    super(text, options);
    // this.id = options.id;
    this.isEditing = false; // Initialize 'isEditing' to false
  }
}

export class ExtendedFabricTextbox extends fabric.Textbox {
  id: string;

  constructor(text: string, options?: fabric.ITextboxOptions) {
    super(text, options);
  }
}

export class ExtendedFabricGroup extends fabric.Group {
  id: string;

  constructor(objects?: fabric.Object[], options?: fabric.IGroupOptions) {
    super(objects, options);
  }
}

export class ExtendedFabricObject extends fabric.Object {
  id: string;

  constructor(options?: fabric.IObjectOptions) {
    super(options);
  }
}
