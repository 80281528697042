import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
  Renderer2,
  ViewChild,
  HostListener,
} from "@angular/core";
import { FabricjsEditorComponent } from "../../../projects/angular-editor-fabric-js/src/lib/angular-editor-fabric-js.component";
import { BuilderService } from "../services/builder.service";
import { UtilService } from "../util.service";
// @ts-ignore
import LineClamp from "@tvanc/lineclamp";
import { DOCUMENT } from "@angular/common";
import { ApiService } from "../services/api.service";
import { AppConstants } from "../../AppConstants";

@Component({
  selector: "app-junior-sticker-builder",
  templateUrl: "./junior-sticker-builder.component.html",
  styleUrls: ["./junior-sticker-builder.component.scss"],
})
export class JuniorStickerBuilderComponent implements OnInit, AfterViewInit {
  @ViewChild("canvas", { static: false }) canvas: FabricjsEditorComponent;
  @ViewChild("clampedElement") clampedElement: ElementRef;
  public childClipArt = [];
  public tabsClipArt: any;
  bgColor = {
    black: "#1D1D1D",
    red: "#DE1212FF",
    orange: "#ff6d00",
    yellow: "#ffda00",
    green: "#45e306",
    blue: "#130f7e",
    Cyan: "#00FFFF",
    Magenta: "#FF00FF",
    Pink: "#FFC0CB",
    Olive: "#808000",
    Maroon: "#800000",
    Brown: "#A52A2A",
    Grey: "#808080",
    Gunmetal: "#2C3539",
  };
  bgImage = {
    // tslint:disable-next-line:max-line-length
    imgUrl: "./assets/bg-img/1.jpg",
    imgUrl2: "./assets/bg-img/2.jpg",
    imgUrl3: "./assets/bg-img/3.jpg",
    imgUrl4: "./assets/bg-img/4.jpg",
  };
  public BASE_URL = AppConstants.BASE_URL;
  pikaImg = "";
  pikaEvent: any;
  bgUrl = "";
  color = "";
  textAlign = "center";
  fontFamily = "helvetica";
  isBold = false;
  isItalicText = false;
  textColor = "#000000";
  texts = {
    line1: {
      text: "",
      size: "16px",
      lineHeight: "16px",
      canvasSize: 50,
    },
    line2: {
      text: "",
      size: "16px",
    },
    line3: {
      text: "",
      size: "16px",
    },
  };
  text1 = {
    text: "",
    size: "",
  };
  text2 = "";
  text3 = "";
  textDetails: any;
  type1: any;
  type2: any;
  type3: any;
  juniorSize = {
    width: 15,
    height: 5,
    type: 1,
    quantity: 150,
  };
  private clamp: any;
  // Define the flag with default value false for place order savePDF call
  public saveFlag: boolean = false;

  constructor(
    public builder: BuilderService,
    public util: UtilService,
    private api: ApiService,
    private renderer: Renderer2,
    private el: ElementRef,
    @Inject(DOCUMENT) private document: HTMLDocument
  ) {}

  isTablet = window.innerWidth < 769;
  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.builder.canvas = this.canvas;
    this.changeSize();
    this.getChildClipArt();
    this.builder.canvas.clear();
    if (this.util.isMobile) {
      // Fetch width data of child-sticker-parent by ID to manage ratio
      const childStickerParent = this.el.nativeElement.querySelector(
        "#child-sticker-parent"
      );
      if (childStickerParent) {
        const width = childStickerParent.offsetWidth;
        console.log("kkk1 width", width);

        // Calculate new height
        const newHeight = window.innerWidth / 3;
        console.log("kkk1 newHeight", newHeight);

        // Set new height to child-sticker-parent
        this.renderer.setStyle(childStickerParent, "height", `${newHeight}px`);
        this.renderer.setStyle(
          childStickerParent,
          "min-height",
          `${newHeight}px`
        );

        // Resize canvas
        this.canvas.changeChildSize(width, newHeight);
      }
    } else {
      this.builder.canvas.changeChildSize(600, 200);
    }

    // this.clampText();
  }
  @HostListener("window:scroll", ["$event"])
  onWindowScroll(event): void {
    // Get the scroll position
    const scrollPosition =
      window.scrollY ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;

    // Get the element you want to modify
    const stickyImageSection =
      this.el.nativeElement.querySelector(".stickyImageSec");
    const addMarginTop = this.el.nativeElement.querySelector(".addMarginTop");

    const stickyParentDiv =
      this.el.nativeElement.querySelector(".stickyParentDiv");
    const rect = stickyParentDiv.getBoundingClientRect();

    // Calculate the position based on the top of the viewport
    const positionFromTop = rect.top;
    //console.log("positionFromTop - ", positionFromTop);

    // Check if the scroll position is beyond the threshold
    if (scrollPosition > positionFromTop) {
      // Add the desired class when scrolling down
      if (stickyImageSection) {
        this.renderer.addClass(stickyImageSection, "addSticky");
      }
      if (addMarginTop) {
        this.renderer.addClass(addMarginTop, "addMarginTopS");
      }
    } else {
      // Remove the class when scrolling back to the top
      if (addMarginTop) {
        this.renderer.removeClass(addMarginTop, "addMarginTopS");
      }
      if (stickyImageSection) {
        this.renderer.removeClass(stickyImageSection, "addSticky");
      }
    }
  }

  public changeSize() {
    this.canvas.changeChildSize(1133.86, 566.93);
  }

  clampText() {
    const element = this.clampedElement.nativeElement;
    this.clamp = new LineClamp(element, {
      maxLines: 1,
    });
    const listener = (event) => console.log(event.type);

    element.addEventListener("lineclamp.softclamp", listener);
    element.addEventListener("lineclamp.hardclamp", listener);
    element.addEventListener("lineclamp.clamp", listener);

    // softClamp() emits 'lineclamp.softclamp' and 'lineclamp.clamp', or nothing
    // if clamping unnecessary
    this.clamp.softClamp();

    // hardClamp() emits 'lineclamp.hardclamp' and 'lineclamp.clamp', or nothing
    // if clamping unnecessary
    this.clamp.hardClamp();

    // apply() can emit 'lineclamp.softclamp' and/or 'lineclamp.hardclamp' followed
    // by 'lineclamp.clamp', or nothing if clamping is unnecessary
    this.clamp.apply();
  }

  public getImgPolaroid(event) {
    this.pikaEvent = event;
    // this.builder.canvas.getChildImgPolaroid(event);
    const ele = event.target;
    this.pikaImg = ele.src;
    const image = this.pikaImg.length > 1;
    this.singleLineSticker(image, this.texts.line1.text);
    setTimeout(() => {
      this.save();
    }, 200);
    //this.reRenderWithImage();
    // this.canvas.ge
  }

  reRenderWithImage() {
    const textExists = this.util.textExists(this.texts);
    if (textExists) {
      const size = this.util.getFontSize(
        this.texts.line1.text,
        true,
        1,
        this.texts
      );
      this.texts.line1.size = size;
      this.texts.line2.size = size;
      this.texts.line3.size = size;
    } else {
      console.log("No change required!");
    }
  }

  public setCanvasFill(color) {
    this.color = color;
    this.bgUrl = "";
    this.canvas.props.canvasFill = color;
    this.save();

    // this.builder.canvas.setCanvasFill();
  }

  public setCanvasImage(url) {
    this.bgUrl = url;
    this.save();
    // this.builder.canvas.setBackgroundImage(url);
  }

  addText(text, type) {
    // const metrics = this.clamp.calculateTextMetrics();
    // console.log('Metrics', metrics);
    switch (type) {
      case 1:
        this.texts.line1.text = text;
        console.log(this.texts.line1);
        break;
      case 2:
        this.texts.line2.text = text;
        console.log(this.texts.line2);
        break;
      case 3:
        this.texts.line3.text = text;
        console.log(this.texts.line3);
        break;
    }
    const image = this.pikaImg.length > 1;

    this.singleLineSticker(image, text);
    this.save();
  }
  getTextWidth(text, font) {
    let gettextwidth = this.getTextWidth;
    // re-use canvas object for better performance
    const canvas = <HTMLCanvasElement>document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width;
  }

  getCssStyle(element, prop) {
    return window.getComputedStyle(element, null).getPropertyValue(prop);
  }
  singleLineSticker(img, text) {
    this.texts.line1.size = img ? "150px" : "150px";
    this.texts.line1.canvasSize = img ? 150 : 100;
    this.texts.line1.lineHeight = img ? "150px" : "100px";
    console.log(this.texts.line1);
    var text_section = document.getElementById("text-section");
    console.log("text_section" + text_section);
    var positionInfo = text_section.getBoundingClientRect();
    var canvaswidth = positionInfo.width - 20;
    var fontweight = this.isBold ? "900" : "100";
    var textwidth = this.getTextWidth(
      text,
      fontweight + " " + this.texts.line1.size + " '" + this.fontFamily + "'"
    );
    let canvasSize = this.texts.line1.canvasSize;
    console.log("positionInfo" + positionInfo.width);
    while (textwidth > canvaswidth) {
      canvasSize = canvasSize - 1;
      textwidth = this.getTextWidth(
        text,
        fontweight + " " + canvasSize + "px " + this.fontFamily
      );
    }
    this.texts.line1.size = canvasSize - 4 + "px";
    this.texts.line1.canvasSize = canvasSize - 4;
    this.texts.line1.lineHeight = canvasSize - 4 + "px";
    console.log(this.texts.line1);
    console.log("this.juniorSize.type" + this.juniorSize.type);
    if (this.juniorSize.type === 2) {
      this.texts.line1.canvasSize = this.texts.line1.canvasSize * 2;
    }
    if (this.util.isMobile && img) {
      const size = Number(this.texts.line1.size.replace("px", ""));
      this.texts.line1.size = size / 2 + "px";
      this.texts.line1.canvasSize = size / 2;
      this.texts.line1.lineHeight = size / 2 + "px";
    } else if (img) {
      const size = Number(this.texts.line1.size.replace("px", ""));
      this.texts.line1.size = size / 1.5 + "px";
      this.texts.line1.canvasSize = size / 1.5;
      this.texts.line1.lineHeight = size / 1.5 + "px";
    }
  }

  public setFontFamily(value, text) {
    this.fontFamily = value.value;
    this.builder.canvas.setFontFamilyJunior(value);
    const image = this.pikaImg.length > 1;
    this.singleLineSticker(image, text);
    this.save();
  }

  public setTextAlign(value) {
    this.textAlign = value;
    // this.builder.canvas.setTextAlign(value);
    this.save();
  }

  public setBold() {
    this.isBold = !this.isBold;
    // this.builder.canvas.setBold();
    this.save();
  }

  public setFontStyle() {
    this.isItalicText = !this.isItalicText;
    // this.builder.canvas.setFontStyle();
    this.save();
  }

  public setFill(event) {
    this.textColor = event;
    // this.builder.canvas.setFill();
    this.save();
  }

  save() {
    // this.builder.canvas.clear();

    // var childStickerParent = document.getElementById("child-sticker-parent");

    // // Get the bounding rectangles of both elements
    // let childStickerParentRect = childStickerParent.getBoundingClientRect();
    // // Fix size will be used so PDF created always in the same size
    // this.builder.canvas.changeChildSize(
    //   childStickerParentRect.width,
    //   childStickerParentRect.height
    // );
    // this.builder.canvas.changeSize();
    const sticker =
      this.text1.text.length > 0 &&
      this.text2.length > 0 &&
      this.text3.length > 0 &&
      this.pikaImg.length > 0;
    // Here we getting the width and height of added text so when creating canvas/PDF we can put the same size.
    var get_text_length = document.getElementById("child-sticker-text");
    var getTextLengthInfo = get_text_length?.getBoundingClientRect();

    this.textDetails = {
      text: [
        {
          text: this.texts.line1.text,
          type: 1,
          size: this.texts.line1.size,
          canvasSize: this.texts.line1.canvasSize,
          width: getTextLengthInfo ? getTextLengthInfo.width : 0,
          height: getTextLengthInfo ? getTextLengthInfo.height : 0,
        },
        // { text: this.texts.line2.text, type: 2, size: this.texts.line1.size },
        // { text: this.texts.line3.text, type: 3, size: this.texts.line1.size },
      ],
      textAlign: this.textAlign,
      bold: this.isBold,
      italic: this.isItalicText,
      background: this.color,
      bgUrl: this.bgUrl,
      color: this.textColor,
      image: this.pikaImg,
      event: this.pikaEvent,
      fontFamily: this.fontFamily,
      type: this.juniorSize.type,
    };

    if (this.textDetails.event) {
      this.builder.canvas.getChildImgPolaroid(
        this.textDetails.event,
        this.util.getLineSum(this.texts)
      );
    }

    setTimeout(() => {
      this.builder.canvas.addJuniorText(
        this.textDetails,
        this.textDetails.event,
        this.texts
      );
    }, 500);
    if (this.textDetails.bgUrl) {
      this.builder.canvas.setBackgroundImage(this.textDetails.bgUrl);
    } else {
      this.builder.canvas.setCanvasFill("child_sticker");
    }

    this.builder.stickerDetails = {
      customSize: false,
      customSizes: false,
      fileEvent: undefined,
      material: undefined,
      name: undefined,
      pdf: undefined,
      piecePrice: undefined,
      pieces: this.juniorSize.quantity,
      // @ts-ignore
      size: {
        height: this.juniorSize.type === 1 ? 5 : 10,
        width: this.juniorSize.type === 1 ? 15 : 30,
      },
      stickerNumber: undefined,
    };
    this.builder.stickerDetails.name = {
      id: 0,
      image: "",
      selected: false,
      value: "",
      name: "nameTag",
    };
    this.builder.stickerDetails.stickerNumber = {
      amount: 20,
      createdAt: undefined,
      enable: false,
      price: this.juniorSize.type === 1 ? 18 : 18,
      publishedAt: undefined,
      updatedAt: undefined,
      piece: this.juniorSize.quantity,
    };

    this.builder.canvas.sizeInCm.width = this.juniorSize.type === 1 ? 15 : 30;
    this.builder.canvas.sizeInCm.height = this.juniorSize.type === 1 ? 5 : 10;

    if (this.saveFlag) {
      this.builder.canvas.savePdf();
      // Reset the flag after place order
      this.saveFlag = false;
    }
    // this.router.navigateByUrl('/place-order?type=junior-sticker');
  }

  selectJuniorSize(size: number) {
    if (size === 1) {
      this.juniorSize.width = 15;
      this.juniorSize.height = 5;
      this.juniorSize.quantity = 150;
      this.juniorSize.type = 1;
      this.util.juniorSize = 1;
    } else {
      this.juniorSize.width = 30;
      this.juniorSize.height = 10;
      this.juniorSize.quantity = 120;
      this.juniorSize.type = 2;
      this.util.juniorSize = 2;
    }
  }

  clearCanvas() {
    this.builder.canvas.clear();
    this.pikaImg = "";
    this.pikaEvent = undefined;
    this.bgUrl = "";
    this.color = "";
    this.textAlign = "";
    this.fontFamily = "";
    this.isBold = false;
    this.isItalicText = false;
    this.textColor = "";
    this.text1 = {
      text: "",
      size: "",
    };
    this.text1 = {
      text: "",
      size: "",
    };
    this.text1 = {
      text: "",
      size: "",
    };
    this.text2 = "";
    this.text3 = "";
    this.textDetails = undefined;
    this.type1 = undefined;
    this.type2 = undefined;
    this.type3 = undefined;
  }

  noText() {
    return (
      this.texts.line1.text.length === 0 &&
      this.texts.line2.text.length === 0 &&
      this.texts.line3.text.length === 0
    );
  }

  /**
   * Get Child Cliparts
   */

  getChildClipArt() {
    this.api.getChildClipart().subscribe(
      (clipArtData) => {
        this.childClipArt = clipArtData.data;
        //this.tabsClipArt= this.formatedTabs();
        //console.log(this.tabsClipArt);
      },
      (error) => {
        console.log("ERROR Child Clipart", error);
      }
    );
  }
  objectKey(obj) {
    return Object.keys(obj);
  }
  formatedTabs() {
    return this.childClipArt.reduce((prev, now) => {
      if (!prev[now.attributes.tabgroup]) {
        prev[now.attributes.tabgroup] = [];
      }
      prev[now.attributes.tabgroup].push(now);
      return prev;
    }, {});
  }
}
