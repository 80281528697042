<div>
  <div *ngIf="router.url === '/login'" >
    <div class="row" style="margin: 0">
   <div class="col-6" style="padding-left: 10%">
     <div class="login-text">Login</div>
     <form (ngSubmit)="onSubmit()" [formGroup]="loginForm" class="login_form" novalidate>
       <div class="t-left">
         <div style="margin-bottom: 20px;">
           <label class="email-label" for="email">Email*</label> <br>
           <input class="input-text" id="email" formControlName="email" type="email" placeholder="user@mail.com">
<!--           <div>Please enter valid email</div>-->
         </div>
        <div>
          <label class="password-label" for="password">Password*</label> <br>
          <input class="input-text" id="password" formControlName="password" placeholder="Password" type="password">
<!--          <div>Please enter valid password</div>-->
        </div>
         <div class="p-login">
           <div>
             <button [disabled]="!loginForm.valid" class="login-btn "
                     type="submit">Login
             </button>
           </div>
           <div>
             <span>Not yet registered?</span>
             <button [routerLink]="'/signup'" class=" account-btn">
               Create an account
             </button>
           </div>

         </div>

       </div>
     </form>

   </div>
   <div class="col-6">
     <div class="log-img-col">
     <img class="log-img" src="assets/img/login.png" alt="img">
     </div>

   </div>
    </div>

  </div>

  <div *ngIf="router.url === '/signup'">
    <div class="row" style="margin: 0">
      <div class="col-6" style="padding-left: 10%">
        <div class="login-text">Welcome to Grafimax</div>
        <form (ngSubmit)="onSubmit()" [formGroup]="loginForm" class="login_form" novalidate>
          <div class="t-left">
            <div style="margin-bottom: 20px;">
              <label class="email-label" for="email2">Email*</label> <br>
              <input class="input-text" id="email2" formControlName="email" type="email" placeholder="user@mail.com">
              <!--           <div>Please enter valid email</div>-->
            </div>
            <div style="margin-bottom: 20px;">
              <label class="password-label" for="password2">Password*</label> <br>
              <input class="input-text" id="password2" formControlName="password" placeholder="Password" type="password">
              <!--          <div>Please enter valid password</div>-->
            </div>
            <div >
              <label class="password-label" for="password3">Confirm Password</label> <br>
              <input class="input-text" id="password3" formControlName="confirmPassword"  placeholder="Confirm Password" type="password">
<!--              <div>Please enter the same password</div>-->

            </div>
            <div class="p-login">
              <div>
                <button [disabled]="!signUpForm.valid" class="login-btn "
                        type="submit">Signup
                </button>
              </div>
              <div>
                <button [routerLink]="'/login'" class="login-btn">
                  Login
                </button>
              </div>

            </div>

          </div>
        </form>

      </div>
      <div class="col-6">
        <div class="log-img-col">
          <img class="log-img" src="assets/img/login.png" alt="img">
        </div>

      </div>
    </div>


  </div>
</div>
