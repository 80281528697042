import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { BuilderService } from "../services/builder.service";
import { Router } from "@angular/router";
import { ApiService } from "../services/api.service";
import { AppConstants } from "../../AppConstants";
import { MatSnackBar } from "@angular/material/snack-bar";
import { FabricjsEditorComponent } from "../../../projects/angular-editor-fabric-js/src/lib/angular-editor-fabric-js.component";
import { ToastrService } from "ngx-toastr";
import { TranslationService } from "../services/translation.service";

@Component({
  selector: 'app-etusivu',
  templateUrl: './etusivu.component.html',
  styleUrls: ['./etusivu.component.scss']
})
export class EtusivuComponent implements OnInit, AfterViewInit {
  @ViewChild("canvas", { static: false }) canvas: FabricjsEditorComponent;

  customSize = false;
  stickerNumbers: any;
  resize = false;
  totalNumbers = false;
  loading = false;

  stickers = [
    {
      img: "assets/img/DieCut-icon.png",
      id: 1,
      name: "Cutting",
      value: "5",
      name_fi: "Muotoonleikkaus",
    },
    {
      img: "assets/img/1.png",
      id: 2,
      name: "Square",
      value: "1",
      name_fi: "Suorakulma",
    },
    {
      img: "assets/img/2.png",
      id: 3,
      name: "Round",
      value: "2",
      name_fi: "Pyöreä",
    },
    // {img: 'assets/img/3.png', id: 4, name: 'Round Corners', value: 'round_corner'},
    // {img: 'assets/img/sheet.png', id: 5, name: 'Sheet', value: 'sheet'},
  ];
  materials = [];
  size = [];
  numbers = [];
  selectedMaterialId: string;
  activeChip = false;
  sticker: any;
  material: any;
  sizeValue: any;
  totalPieces: number;
  totalPrice: number;
  public stickerMaterial: any;
  private fileEvent: any;
  public BASE_URL = AppConstants.BASE_URL;
  getLanguage;
  public custom = {
    showCustomSize: false,
    showCustomNumber: false,
    customNumber: 30,
    customPricing: 0,
    customSize: {
      height: 1,
      width: 1,
    },
  };
  public uploaded = false;

  constructor(
    private builder: BuilderService,
    private router: Router,
    private api: ApiService,
    public translation: TranslationService,
    private matSnack: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.sticker = this.stickers[0];
    this.getMaterial();

    this.getSize();
    console.log("This is log from home!", new Date().getTime());
    setTimeout(() => console.log("Log later on!", new Date().getTime()), 10);
    localStorage.removeItem("canvasDetails");
    localStorage.removeItem("stickerData");
    localStorage.removeItem("clipArtUrl");
    localStorage.removeItem("checkStatus");
    localStorage.removeItem("getMaterial");
    localStorage.removeItem("stickerDetails");
    localStorage.removeItem("getMobMaterial");
    this.getLanguage = localStorage.getItem("lang");
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.builder.canvas = this.canvas;
      console.log("Setting builder", this.canvas);
      this.loading = false;
    }, 300);
    localStorage.removeItem("material");
    localStorage.removeItem("clipArt");
  }

  /**
   * function to be used for selecting the material type
   * @param id used for getting the selected material
   * @param name name used for saving the details
   */
  selectMaterial(id, name) {
    // this.material = name.attributes;
    this.material = name;
    console.log(this.material);
    this.materials.forEach((el) => {
      if (el.id === id) {
        el.active = true;
        this.activeChip = false;
      } else {
        el.active = false;
      }
    });
    localStorage.setItem("getMaterial", JSON.stringify(this.material));
  }

  /**
   * function to be used for selecting the number of stickers
   * @param type used for different funtion
   * @param pieces used for number of pieces
   * @param price is the amount of pieces
   */
  selectSticker(type, pieces?: any, price?: any) {
    console.log("this is pieces", pieces, price);
    if (type === 1) {
      console.log(this.sticker);
    } else if (type === 2) {
      console.log(this.sizeValue);
      setTimeout(() => {
        this.builder.size.width = this.sizeValue.attributes.width;
        this.builder.size.height = this.sizeValue.attributes.height;
        this.custom.customSize.width = this.builder.size.width;
        this.custom.customSize.height = this.builder.size.height;
        console.log("this is size builder", this.builder.size);
        this.custom.showCustomSize = false;
        console.log("This is custom values *******", this.custom);
        this.getQuantity();
      }, 300);
    } else if (type === 3) {
      this.totalPieces = pieces;
      this.totalPrice = price;
      this.custom.showCustomNumber = false;
      this.custom.customNumber = this.totalPieces;
      this.custom.customPricing = this.totalPrice;
      console.log("This is custom pricing", this.custom.customPricing);
      // this.custom.customNumber = this.totalPieces;
      console.log(this.totalPieces, this.totalPrice);
    }
  }

  /**
   * this function used for getting the checkbox event
   * @param event different event for different checkbox
   * @param type used for getting the event of different checkbox
   */
  checkBox(event, type) {
    if (type === 1) {
      console.log(event.target.checked);
      this.resize = event.target.checked;
    } else if (type === 2) {
      this.totalNumbers = event.target.checked;
    }
  }

  /**
   * this function to be used for submit and saving all details selected by user
   */
  submitDetails() {
    console.log("custom values", this.custom.customSize.width);
    console.log("custom size", this.customSize);
    if (!this.material) {
      const errorMaterial =
        this.getLanguage === "en"
          ? "Please select material."
          : "Ole hyvä ja valitse materiaali.";

      this.builder.showError(errorMaterial, "");
      return;
    }
    this.builder.stickerDetails = {
      name: this.sticker,
      material: this.material,
      size: this.customSize
        ? {
            height: this.custom.customSize.height,
            width: this.custom.customSize.width,
          }
        : this.sizeValue.attributes,
      pieces: this.custom.showCustomNumber
        ? this.custom.customNumber
        : this.stickerNumbers.attributes.piece,
      piecePrice: this.custom.showCustomNumber
        ? this.custom.customPricing
        : this.stickerNumbers.attributes.amount,
      customSize: this.customSize,
      stickerNumber: this.custom.showCustomNumber
        ? { price: this.custom.customPricing }
        : this.stickerNumbers.attributes,
      fileEvent: this.fileEvent,
      customSizes: false,
      pdf: 0,
      pdfpreview:0,
      background: "#ffffff",
      message: "",
    };
    this.builder.finalOrder.width = this.customSize
      ? this.custom.customSize.width
      : this.sizeValue.attributes.width;
    this.builder.finalOrder.height = this.customSize
      ? this.custom.customSize.height
      : this.sizeValue.attributes.height;

    console.log("****************", this.builder.finalOrder.width);
    // this.builder.finalOrder.number = this.totalPieces;
    //

    if (this.customSize) {
      this.router.navigateByUrl(
        "editor?name=" +
          this.sticker.name +
          "&width=" +
          this.custom.customSize.width +
          "&height=" +
          this.custom.customSize.height +
          "&pieces=" +
          this.custom.customNumber
      );
      console.log(this.builder.stickerDetails);
    } else {
      const pieces = this.custom.showCustomNumber
        ? this.custom.customNumber
        : this.stickerNumbers.attributes.piece;
      this.router.navigateByUrl(
        "editor?name=" +
          this.sticker.name +
          "&width=" +
          this.sizeValue.attributes.width +
          "&height=" +
          this.sizeValue.attributes.height +
          "&pieces=" +
          pieces
      );
    }
  }

  /**
   * Read url of file from input event
   * @param event pass input event from HTML
   */

  public readUrl(event) {
    console.log(
      "this is event",
      this.checkExtension(event.target.files[0].name)
    );
    // const extension = this.checkExtension(event.target.files[0].name);
    // this.builder.canvas.extension =  this.checkExtension(event.target.files[0].name);
    // console.log('dsadsadsads', this.builder.canvas.extension);
    // const supported = (this.checkExtension(event.target.files[0].name) === 'jpg') ||
    //   (this.checkExtension(event.target.files[0].name) === 'png') || (this.checkExtension(event.target.files[0].name) === 'pdf');
    console.log("builder", this.builder.canvas);
    const extension = this.checkExtension(event.target.files[0].name);
    // @ts-ignore
    this.builder?.canvas?.extension = extension;
    this.builder.extension = extension;
    console.log("This is the extension", extension);
    const supported =
      extension === "jpg" ||
      extension === "jpeg" ||
      extension === "png" ||
      extension === "pdf" ||
      extension === "gif" ||
      extension === "svg" ||
      extension === "tiff" ||
      extension === "tif" ||
      extension === "bmp";

    console.log("Supported", supported);
    if (!supported) {
      console.log("This file type is not supported!!!!!");
      this.builder.showError("Not Supported", "File type not supported");
      return;
    }
    if (event.target.files[0].size > 100000000) {
      const setTextT =
        this.getLanguage === "en"
          ? "Please check the file size."
          : "Ole hyvä ja tarkasta tiedoston koko.";
      const setText =
        this.getLanguage === "en"
          ? "Maximum file size 100Mb:"
          : "Suurin sallittu tiedoston koko 100Mb:";
      this.builder.showError(setTextT, setText);
      return;
    }
    this.fileEvent = event;
    this.builder.fileEvent = event;
    this.uploaded = true;
    this.submitDetails();
    // this.builder.canvas.readUrl(event);
  }

  /**
   * Function to return extension of file
   * @param name file name
   */
  checkExtension(name) {
    console.log("this is name", name.replace(/^.*\./, ""));
    return name.replace(/^.*\./, "");
  }
  getMaterial() {
    this.api.getMaterial().subscribe(
      (materialData) => {
        materialData.data.forEach((material) => {
          material.active = false;
          if (material.attributes.title === "Glossy finish") {
            material.active = true;
            this.stickerMaterial = material;
            this.material = material.attributes;
          }
        });
        this.materials = materialData.data;
        this.selectMaterial(this.materials[0].id, this.materials[0]);
        console.log("these are materials", this.materials[0]);
        if (this.materials.length > 0) {
          // Set 'selectedMaterialId' to the 'id' of the first material
          this.selectedMaterialId = this.materials[0].id;
        }
      },
      (error) => {
        console.log("ERROR material", error);
      }
    );
  }

  getSize() {
    this.api.getSize().subscribe(
      (sizeData) => {
        this.size = sizeData.data;
        this.sizeValue = this.size[0];
        console.log("Size value **", this.sizeValue);
        this.builder.size.width = this.size[0].attributes.width;
        this.builder.size.height = this.size[0].attributes.height;
        console.log("this is builder size **", this.builder.size);
        this.getQuantity();
        console.log("these are materials", sizeData);
      },
      (error) => {
        console.log("ERROR material", error);
      }
    );
  }

  getQuantity() {
    this.api.getQuantity().subscribe(
      (quantityData) => {
        console.log("these are quantity", quantityData);
        this.numbers = quantityData.data;
        this.numbers.forEach((num) => {
          num.attributes.price = this.builder.calculatePrice(
            num.attributes.piece
          );
        });
        console.log("numbers", this.numbers);
        this.stickerNumbers = this.numbers[0];
        console.log("This is sticker number", this.stickerNumbers);
        this.custom.customPricing = this.numbers[0].attributes.price;
        this.custom.customNumber = this.numbers[0].attributes.piece;
        this.custom.customSize.width = this.builder.size.width;
        this.custom.customSize.height = this.builder.size.height;
      },
      (error) => {
        console.log("ERROR material", error);
      }
    );
  }

  // Show custom size and items

  showCustom(type: string, event) {
    if (type === "size") {
      this.sizeValue = undefined;
      this.custom.showCustomSize = event.checked;
      event.checked
        ? (this.sizeValue = undefined)
        : (this.sizeValue = this.size[0]);
      event.checked ? (this.customSize = true) : (this.customSize = false);
    } else if (type === "customNumber") {
      console.log("This is custom pricing", this.custom.customPricing);
      event.checked
        ? (this.stickerNumbers = undefined)
        : (this.stickerNumbers = this.numbers[0]);

      this.custom.showCustomNumber = event.checked;
    }
  }

  /**
   * Set custom size
   * @param $event change event from input
   * @param dim dimension type Height or width(h= height, w = width)
   */

  setCustomSize($event: any, dim: string, valueDefault = "nochange") {
    console.log("$evemt", $event.target.value, dim);
    const value = Number($event.target.value);
    this.custom.showCustomNumber = false;

    if (value > 125) {
      console.log("Here i height", value > 125);
      if (dim === "h") {
        this.custom.customSize.height = 125;
      } else {
        this.custom.customSize.width = 125;
      }
    }
    if (valueDefault == "change") {
      if (value < 3) {
        if (dim === "w") {
          this.custom.customSize.width = 3;
        } else {
          this.custom.customSize.height = 3;
        }
      }
    }
    if (dim === "w") {
      this.builder.size.width = this.custom.customSize.width;
    } else {
      this.builder.size.height = this.custom.customSize.height;
    }

    this.getQuantity();
  }

  setCustomNumber($event: any) {
    // console.log(this.builder.calculatePrice($event.target.value));
    this.custom.customPricing = this.builder.calculatePrice(
      $event.target.value
    );
    console.log(
      "custom pricing",
      this.builder.calculatePrice($event.target.value)
    );
  }
}
