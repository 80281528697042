import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { FabricjsEditorComponent } from "../../../projects/angular-editor-fabric-js/src/lib/angular-editor-fabric-js.component";
import { BuilderService } from "../services/builder.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "../services/api.service";
import { MatBottomSheet } from "@angular/material/bottom-sheet";

@Component({
  selector: "app-canvas-editor",
  templateUrl: "./canvas-editor.component.html",
  styleUrls: ["./canvas-editor.component.scss"],
})
export class CanvasEditorComponent implements OnInit, AfterViewInit {
  // @ViewChild("htmlCanvas") htmlCanvas: ElementRef;
  @ViewChild("canvas", { static: false }) canvas: FabricjsEditorComponent;
  @ViewChild("canvasCont", { static: false }) canvasCont: ElementRef;
  numbers = this.builder.stickerDetails?.pieces;
  public loaded = false;
  public stickerDetails: any;
  public material = [];
  public grownumber = 1; 
  public textData: { fill: "" };
  newTextString = "";
  // @HostListener('document:resize', ['$event'])
  public clipArt = [];
  loading: boolean = false;

  onResize(event, parentEvent) {
    this.builder.canvas.canvasResize(event, parentEvent);
    // this.setInitialSize(true);
    // this.canvas.resize(event.target.innerWidth - 500);
  }

  constructor(
    public builder: BuilderService,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private api: ApiService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private sheet: MatBottomSheet
  ) {}

  ngOnInit(): void {
    localStorage.setItem(
      "stickerDetails",
      JSON.stringify(this.builder.stickerDetails)
    );

    this.builder.stickerDetailsReset.subscribe((res: any) => {
      if (res) {
        this.builder.canvas = this.builder.canvas;
        this.builder.cutStyle = res.cutStyle;
        this.builder.material = res.material;
        this.builder.size = res.size;
        this.builder.selected = res.selected;
        this.builder.finalOrder = res.finalOrder;
        this.builder.disableBtn = res.disableBtn;
        this.builder.cutOutline = res.cutOutline;
        this.builder.stickerDetails = res.stickerDetails;
      }
    });
    this.builder.loadingCanvas = true;
    if (!this.builder.stickerDetails) {
      this.router.navigateByUrl("/");
    }

    this.route.queryParams.subscribe(
      (data) => {
        this.stickerDetails = data;
        console.log("this is data", data);
      },
      (error) => {
        console.log("this is error");
      }
    );
    this.builder.loadingCanvas = false;
    console.log("These are sticker details", this.builder.stickerDetails);
    this.getMaterial();
    this.getClipArt();
    this.builder.loader.subscribe((res) => {
      this.loading = res;
    });

    if (localStorage.getItem("checkStatus") == "true") {
      setTimeout(() => {
        const getMobMaterialDetails = JSON.parse(
          localStorage.getItem("getMobMaterial")
        );
        const getLang = localStorage.getItem("lang");
        if (getMobMaterialDetails) {
          this.builder.matName =
            getLang === "en"
              ? getMobMaterialDetails.attributes.title
              : getMobMaterialDetails.attributes.name_fi;
        }
        const getClipArtUrl: any = JSON.parse(
          localStorage.getItem("clipArtUrl")
        );
        const getCanvasDetails: any = JSON.parse(
          localStorage.getItem("canvasDetails")
        );
        this.builder.canvas.props.canvasFill = getCanvasDetails.background;
        this.builder.canvas.setCanvasFill();
        if (getClipArtUrl) {
          for (let index = 0; index < getClipArtUrl.length; index++) {
            for (
              let index = 0;
              index < getCanvasDetails.objects.length;
              index++
            ) {
              const element = getCanvasDetails.objects[index];
              const clipArt = getClipArtUrl[index];
              if (element.type == "group") {
                this.builder.canvas.getImgPolaroid(
                  clipArt,
                  "test",
                  element.top,
                  element.left,
                  element.scaleX,
                  element.scaleY
                );
                this.sheet.dismiss();
              }
              if (element.type == "i-text") {
                if (element.text) {
                  this.newTextString = element.text;
                  this.builder.canvas.textString = element.text;
                  this.builder.canvas.addSessionText(
                    element.top,
                    element.left,
                    element.scaleX,
                    element.scaleY
                  );
                  this.builder.canvas.textString = this.newTextString;
                }
                if (element.fontSize) {
                  this.builder.canvas.setActiveStyle(
                    "fontSize",
                    parseInt(element.fontSize, 10),
                    null
                  );
                  this.builder.canvas.props.fontSize = element.fontSize;
                }
                if (element.fill) {
                  this.builder.canvas.setActiveStyle(
                    "fill",
                    element.fill,
                    null
                  );
                  this.builder.canvas.props.fill = element.fill;
                }
                if (element.fontFamily) {
                  this.builder.canvas.setActiveStyle(
                    "fontFamily",
                    element.fontFamily,
                    null
                  );
                  this.builder.canvas.props.fontFamily = element.fontFamily;
                }
                if (element.fontWeight) {
                  this.builder.canvas.setActiveStyle(
                    "fontWeight",
                    element.fontWeight ? "bold" : "",
                    null
                  );
                  this.builder.canvas.props.fontWeight = element.fontWeight;
                }
                if (element.fontStyle) {
                  this.builder.canvas.setActiveStyle(
                    "fontStyle",
                    "italic",
                    null
                  );
                  this.builder.canvas.props.fontStyle = element.fontStyle;
                }
                if (element.overline) {
                  this.builder.canvas.setActiveStyle(
                    "overline",
                    "overline",
                    null
                  );
                  this.builder.canvas.props.overline = element.overline;
                }
                if (element.underline) {
                  this.builder.canvas.setActiveStyle(
                    "underline",
                    "underline",
                    null
                  );
                  this.builder.canvas.props.underline = element.underline;
                }
                if (element.linethrough) {
                  this.builder.canvas.setActiveStyle(
                    "linethrough",
                    "line-through",
                    null
                  );
                  this.builder.canvas.props.linethrough = element.linethrough;
                }
              }
              if (element.type == "image") {
                this.builder.image = element.src;
                this.builder.canvas.addSessionImage(
                  element.src,
                  element.top,
                  element.left,
                  element.scaleX,
                  element.scaleY
                );
              }
            }
          }
        } else {
          if (getCanvasDetails.objects.length > 0) {
            for (
              let index = 0;
              index < getCanvasDetails.objects.length;
              index++
            ) {
              const element = getCanvasDetails.objects[index];
              if (element.type == "i-text") {
                if (element.text) {
                  this.newTextString = element.text;
                  this.builder.canvas.textString = element.text;
                  this.builder.canvas.addSessionText(
                    element.top,
                    element.left,
                    element.scaleX,
                    element.scaleY
                  );
                  this.builder.canvas.textString = this.newTextString;
                }
                if (element.fontSize) {
                  this.builder.canvas.setActiveStyle(
                    "fontSize",
                    parseInt(element.fontSize, 10),
                    null
                  );
                  this.builder.canvas.props.fontSize = element.fontSize;
                }
                if (element.fill) {
                  this.builder.canvas.setActiveStyle(
                    "fill",
                    element.fill,
                    null
                  );
                  this.builder.canvas.props.fill = element.fill;
                }
                if (element.fontFamily) {
                  this.builder.canvas.setActiveStyle(
                    "fontFamily",
                    element.fontFamily,
                    null
                  );
                  this.builder.canvas.props.fontFamily = element.fontFamily;
                }
                if (element.fontWeight) {
                  this.builder.canvas.setActiveStyle(
                    "fontWeight",
                    element.fontWeight ? "bold" : "",
                    null
                  );
                  this.builder.canvas.props.fontWeight = element.fontWeight;
                }
                if (element.fontStyle) {
                  this.builder.canvas.setActiveStyle(
                    "fontStyle",
                    "italic",
                    null
                  );
                  this.builder.canvas.props.fontStyle = element.fontStyle;
                }
                if (element.overline) {
                  this.builder.canvas.setActiveStyle(
                    "overline",
                    "overline",
                    null
                  );
                  this.builder.canvas.props.overline = element.overline;
                }
                if (element.underline) {
                  this.builder.canvas.setActiveStyle(
                    "underline",
                    "underline",
                    null
                  );
                  this.builder.canvas.props.underline = element.underline;
                }
                if (element.linethrough) {
                  this.builder.canvas.setActiveStyle(
                    "linethrough",
                    "line-through",
                    null
                  );
                  this.builder.canvas.props.linethrough = element.linethrough;
                }
              }
              if (element.type == "image") {
                this.builder.image = element.src;
                this.builder.canvas.addSessionImage(
                  element.src,
                  element.top,
                  element.left,
                  element.scaleX,
                  element.scaleY
                );
              }
            }
          }
        }
        const data: any = JSON.parse(localStorage.getItem("stickerData"));
        if (data.material) {
          this.builder.selectedMarerial.emit(data.material.attributes.title);
          this.builder.canvas.setMatetial(data.material);
        }
        this.builder.canvas.sizeInCm.width = data.size.width;
        this.builder.canvas.sizeInCm.height = data.size.height;
        this.builder.canvas.resetSize(data.size.width, data.size.height);
        // this.builder.stickerDetails.stickerNumber.price = data.stickerDetails.stickerNumber.price;
      }, 700);
      localStorage.setItem("checkStatus", "false");
    }
  }

  @HostListener("window:beforeunload", ["$event"])
  unloadHandler(event: Event) {
    // Your logic on beforeunload
    console.log("Dont go!");
    event.returnValue = "You will leave this page" as any;
    return "If you reload this page, your previous action will be repeated";
  }
  onInputChange(event: any){
      this.grownumber = event.value
      this.builder.canvas.growSize(event.value);
  }
  // After view init set canvas size as per screen

  ngAfterViewInit(): void {
    if (this.stickerDetails) {
      if (this.stickerDetails.width && this.stickerDetails.height) {
        this.setInitialSize(false);
        // if cutting sticker then set initial size resize will not called because of cutting sticker size is fixed to show.
        if (this.stickerDetails.name != "Cutting") {
          setTimeout(() => {
            this.setInitialSize(true);
          }, 300);
        }
      } else {
        this.setInitialSize(false);
      }
    } else {
      this.setInitialSize(false);
    }
    if (this.builder.stickerDetails.material) {
      /*this.builder.canvas.setBackgroundImage1(
        this.builder.stickerDetails.material
      );*/
    }
    if (this.builder.stickerDetails.fileEvent) {
      this.builder.loadingCanvas = true;
      this.canvas.readUrl(this.builder.stickerDetails.fileEvent);
      setTimeout(() => {
        this.canvas.removeBg();
        this.builder.loadingCanvas = false;
      }, 500);
    }
  }

  getTextProperty(eventData: any) {
    this.builder.canvas.textString = eventData.target.text;
    this.builder.canvas.props.fontFamily = eventData.target.fontFamily;
    this.builder.canvas.props.fill = eventData.target.fill;
    this.builder.canvas.props.fontSize = eventData.target.fontSize;
    this.builder.canvas.props.fontWeight = eventData.target.fontWeight;
    this.builder.canvas.props.fontStyle = eventData.target.fontStyle;
    this.builder.canvas.props.overline = eventData.target.overline;
    this.builder.canvas.props.underline = eventData.target.underline;
    this.builder.canvas.props.linethrough = eventData.target.linethrough;
  }

  setInitialSize(resize) {
    this.builder.loadingCanvas = true;

    console.log("This is sticker details", this.stickerDetails);
    this.builder.canvas = this.canvas;
    // console.log('width', this.canvasCont.nativeElement.offsetWidth
    // );
    this.builder.canvas.containerWidth =
      this.canvasCont.nativeElement.offsetWidth;
    this.builder.canvas.size.width =
      this.canvasCont.nativeElement.offsetWidth - 1;
    this.changeSize();
    this.builder.canvas.containerHeight =
      this.canvasCont.nativeElement.offsetHeight;
    this.builder.canvas.size.height = this.builder.canvas.containerHeight - 1;
    this.changeSize();

    if (resize) {
      const cm = 37.7952755906;
      const event = {
        height: this.stickerDetails.height * cm,
        width: this.stickerDetails.width * cm,
      };
      // this.builder.canvas.canvasResize(event);
      this.builder.canvas.size.width = event.width;
      this.builder.canvas.size.height = event.height;
      this.changeSize();
      this.builder.canvas.changeSizeInCm(1);
      // this.builder.canvas.changeSizeInCm(2);
    }
    // if cutting sticker then set sizeInCm because of cutting sticker size is fixed to show.
    if (this.stickerDetails.name === "Cutting") {
      this.builder.canvas.sizeInCm.width = this.stickerDetails.width;
      this.builder.canvas.sizeInCm.height = this.stickerDetails.height;
    }
    this.builder.canvas.changeShape(Number(this.stickerDetails.name));
    this.builder.loadingCanvas = false;
  }

  public changeSize() {
    this.canvas.changeSize();
  }

  /**
   * Get Material
   */

  getMaterial() {
    this.api.getMaterial().subscribe(
      (materialData) => {
        materialData.data.forEach((material) => {
          material.selected = false;
        });
        this.material = materialData.data;
        this.builder.stickerDetails.material = this.material[0];
        console.log("these are materials", materialData);
      },
      (error) => {
        console.log("ERROR material", error);
      }
    );
  }

  /**
   * Get Clipart
   */

  getClipArt() {
    this.api.getClipart().subscribe(
      (clipArtData) => {
        clipArtData.data.forEach((material) => {
          material.selected = false;
        });
        this.clipArt = clipArtData.data;
        this.cd.detectChanges();
        console.log("these are materials", clipArtData);
      },
      (error) => {
        console.log("ERROR material", error);
      }
    );
  }

  //  Unused functions

  // public saveCanvasToJSON() {
  //   this.canvas.saveCanvasToJSON();
  // }
  //
  // public confirmClear() {
  //   this.canvas.confirmClear();
  // }
  //
  // public getImgPolaroid(event) {
  //   this.canvas.getImgPolaroid(event);
  // }
  //
  // public readUrl(event) {
  //   this.canvas.readUrl(event);
  // }
  //
  // public removeSelected() {
  //   this.canvas.removeSelected();
  // }
  //
  // public sendToBack() {
  //   this.canvas.sendToBack();
  // }
  //
  //
  // public setCanvasFill() {
  //   this.canvas.setCanvasFill();
  // }
  //
  // public setCanvasImage() {
  //   this.canvas.setCanvasImage();
  // }
  //
  // public setFill() {
  //   this.canvas.setFill();
  // }
  //
  // public hasTextDecoration(value) {
  //   this.canvas.hasTextDecoration(value);
  // }
  //
  // public setTextDecoration(value) {
  //   this.canvas.setTextDecoration(value);
  // }
  //
  // public setLineHeight() {
  //   this.canvas.setLineHeight();
  // }
  //
  // public rasterize() {
  //   this.canvas.rasterize();
  // }
  //
  // public rasterizeSVG() {
  //   this.canvas.rasterizeSVG();
  // }
  //
  // public loadCanvasFromJSON() {
  //   this.canvas.loadCanvasFromJSON();
  // }
  //
  // public addText() {
  //   this.canvas.addText();
  // }
  //
  // public addImageOnCanvas(url) {
  //   this.canvas.addImageOnCanvas(url);
  // }
  // public removeWhite(url) {
  //   this.canvas.removeWhite(url);
  // }
  // public addFigure(figure) {
  //   this.canvas.addFigure(figure);
  // }
  //
  // public bringToFront() {
  //   this.canvas.bringToFront();
  // }
  //
  // public cleanSelect() {
  //   this.canvas.cleanSelect();
  // }
  //
  //
  //
  // public clone() {
  //   this.canvas.clone();
  // }
  //
  //
  //
  // public setId() {
  //   this.canvas.setId();
  // }
  //
  // public setOpacity() {
  //   this.canvas.setOpacity();
  // }
  //
  //
  // public setFontFamily() {
  //   this.canvas.setFontFamily();
  // }
  //
  // public setTextAlign(value) {
  //   this.canvas.setTextAlign(value);
  // }
  //
  // public setBold() {
  //   this.canvas.setBold();
  // }
  //
  // public setFontStyle() {
  //   this.canvas.setFontStyle();
  // }
  //
  //
  // public setFontSize() {
  //   this.canvas.setFontSize();
  // }
  //
  //
  // public setCharSpacing() {
  //   this.canvas.setCharSpacing();
  // }
  //
  // public rasterizeJSON() {
  //   this.canvas.rasterizeJSON();
  // }
  savePdf() {
    if (this.stickerDetails.name.name === "Round") {
      this.builder.canvas.drawEllipse();
    }
    console.log("these are sticker-details", this.builder.stickerDetails);
    this.builder.canvas.savePdf();
  }

  back() {
    this.router.navigate([""]);
  }
  deleteObject() {
    this.canvas.removeSelected();
  }
  reset() {
    const data: any = JSON.parse(localStorage.getItem("stickerDetails"));
    this.numbers = data.pieces;
    this.builder.stickerDetails.stickerNumber.price = data.stickerNumber.price;
    this.builder.canvas.sizeInCm.width = data.size.width;
    this.builder.canvas.sizeInCm.height = data.size.height;
    this.builder.canvas.resetSize(data.size.width, data.size.height);
    this.builder.stickerDetailsReset.emit(this.builder);
  }
}
