<div class="container-fluid">
  <div class="row">
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container-fluid">
        <a class="navbar-brand" href="#">
          <img src="/assets/header/latest-logo.png" class="logo-width" alt="">
        </a>
<!--        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">-->
<!--          <span class="navbar-toggler-icon"></span>-->
<!--        </button>-->
<!--        <div class="collapse navbar-collapse" id="navbarNavDropdown">-->
<!--          <ul class="navbar-nav ms-left mb-2  mob-padding mb-lg-0">-->
<!--            <li class="nav-item">-->
<!--              <a class="nav-link active" aria-current="page" href="#">Service</a>-->
<!--            </li>-->
<!--            <li class="nav-item">-->
<!--              <a class="nav-link" href="javascript:void(0)">Products</a>-->
<!--            </li>-->
<!--            <li class="nav-item">-->
<!--              <a class="nav-link" href="javascript:void(0)">Our Customers </a>-->
<!--            </li>-->
<!--            <li class="nav-item">-->
<!--              <a class="nav-link" href="javascript:void(0)">Company </a>-->
<!--            </li>-->
<!--            <li class="nav-item">-->
<!--              <a class="nav-link" href="javascript:void(0)">Contact</a>-->
<!--            </li>-->
<!--            <li class="nav-item ml-3">-->
<!--              <a class="nav-link" href="javascript:void(0)">-->
<!--                <svg style="width:24px;height:24px" viewBox="0 0 24 24">-->
<!--                  <path fill="currentColor" d="M17,18A2,2 0 0,1 19,20A2,2 0 0,1 17,22C15.89,22 15,21.1 15,20C15,18.89 15.89,18 17,18M1,2H4.27L5.21,4H20A1,1 0 0,1 21,5C21,5.17 20.95,5.34 20.88,5.5L17.3,11.97C16.96,12.58 16.3,13 15.55,13H8.1L7.2,14.63L7.17,14.75A0.25,0.25 0 0,0 7.42,15H19V17H7C5.89,17 5,16.1 5,15C5,14.65 5.09,14.32 5.24,14.04L6.6,11.59L3,4H1V2M7,18A2,2 0 0,1 9,20A2,2 0 0,1 7,22C5.89,22 5,21.1 5,20C5,18.89 5.89,18 7,18M16,11L18.78,6H6.14L8.5,11H16Z" />-->
<!--                </svg>-->
<!--              </a>-->
<!--            </li>-->

<!--            <li class="nav-item">-->
<!--              <a class="nav-link border" href="javascript:void(0)">Login</a>-->
<!--            </li>-->

<!--          </ul>-->
<!--        </div>-->
        <div class="dropdown" style="cursor: pointer">
          <a  (click)="dropdown()"  role="button" style="list-style-type: none; text-decoration: none; color: black">
            <img [src]="translationService.getLang() === 'en' ? 'assets/header/us-flag.png': 'assets/header/finland-flag.png'"
                 style=" width: 31px; cursor: pointer;">
            <span class="ml-2">{{translationService.getLang() === 'en' ? 'En' : 'Fi'}}</span>
            <svg style="width:24px;height:24px" viewBox="0 0 24 24">
              <path fill="currentColor" d="M7,10L12,15L17,10H7Z" />
            </svg>
          </a>

          <div class="dropdown-content" *ngIf="showLang">
            <a (click)="changeLanguage('en')"  *ngIf="translationService.getLang() === 'fi'">
              <img src="assets/header/us-flag.png" style="width: 31px; cursor: pointer;"> <span
              class="ml-2">En</span></a>
            <a (click)="changeLanguage('fi')" *ngIf="translationService.getLang() === 'en'">
              <img src="assets/header/finland-flag.png" style="width: 31px; cursor: pointer;"> <span
              class="ml-2">Fi</span></a>
          </div>
        </div>
      </div>
    </nav>
  </div>

</div>

