import {ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {BuilderService} from '../services/builder.service';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {MobileMessageComponent} from '../mobile-sheets/mobile-message/mobile-message.component';
import {CanvasBgComponent} from '../canvas-bg/canvas-bg.component';
import {StickerMaterialComponent} from '../sticker-material/sticker-material.component';
import {AddImageComponent} from '../add-image/add-image.component';
import {CutOptionsComponent} from '../cut-options/cut-options.component';
import {AddTextComponent} from '../add-text/add-text.component';
import {AddClipartComponent} from '../add-clipart/add-clipart.component';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-sticker-detail',
  templateUrl: './sticker-detail.component.html',
  styleUrls: ['./sticker-detail.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class StickerDetailComponent implements OnInit {

  numbers = this.builder.stickerDetails?.pieces;
  material;
  selectedMarerial: any;
  clipArt: any;
  getLanguage;
  setText;
  width;
  // matName;
  
  constructor(
    public builder: BuilderService,
    private sheet: MatBottomSheet,
    private api: ApiService,
    private cd: ChangeDetectorRef,
    ) {
      this.getLanguage = localStorage.getItem('lang');
  }

  ngOnInit(): void {
    const stickerDetails = {
      cutStyle: this.builder.cutStyle,
      material: this.builder.material,
      size: this.builder.size,
      selected: this.builder.selected,
      finalOrder: this.builder.finalOrder,
      disableBtn: this.builder.disableBtn,
      cutOutline: this.builder.cutOutline,
      stickerDetails: this.builder.stickerDetails
    }

    this.builder.stickerDetailsReset.subscribe((res: any) => {
      if(res) {
        const data: any = JSON.parse(localStorage.getItem('stickerDetails'));
        this.numbers = data.pieces;
      }
    })
    this.builder.selectedMarerial.subscribe((res: any) => {
      console.log(res,'check if data')
      if(res) {
        this.selectedMarerial = res;
        console.log(this.selectedMarerial.attributes,'this.selectedMarerial')
        this.builder.matName = (this.getLanguage === 'en') ? this.selectedMarerial.attributes.title : this.selectedMarerial.attributes.name_fi  
      }
      
    })
    
    const getMaterialDetails = JSON.parse(localStorage.getItem("getMaterial"));
    this.builder.matName = (this.getLanguage === 'en') ? getMaterialDetails.attributes.title : getMaterialDetails.attributes.name_fi;
  }

  getMaterial() {
    this.api.getMaterial().subscribe(materialData => {
      materialData.data.forEach(material => {
        material.selected = false;
      });
      this.material = materialData.data;
      localStorage.setItem('material',JSON.stringify(this.material));
      console.log('these are materials', materialData);
    }, error => {
      console.log('ERROR material', error);
    });
  }


  // public changeSize() {
  //   this.builder.canvas.changeSize();
  // }

  openSheet(type) {
    if (type === 1) {
      this.sheet.open(MobileMessageComponent, {});

    } else if (type === 2) {
      this.getMaterial();
      setTimeout(() => {
        this.sheet.open(StickerMaterialComponent, {});
      }, 500);

    } else if (type === 3) {
      this.sheet.open(AddImageComponent, {});

    } else if (type === 4) {
      this.sheet.open(CutOptionsComponent, {});

    } else if (type === 5) {
      this.sheet.open(AddTextComponent, {});

    } else if (type === 6) {
      this.sheet.open(CanvasBgComponent, {});

    } else if (type === 7) {
      this.builder.loader.emit(true);
      this.cd.detectChanges();
      this.sheet.open(AddClipartComponent, {
        panelClass: 'clipart-modal'
      });
    }
  }

  changeNumber($event: any) {
    this.builder.loadingCanvas = true;
    console.log('this is change event', $event.target.value);
    let pieces = Number($event.target.value);
    if (pieces < 1) {
      pieces = 1;
      this.numbers = pieces;
      // @ts-ignore
      this.builder.stickerDetails?.pieces = pieces;

    } else {
      this.numbers = pieces;
      // @ts-ignore
      this.builder.stickerDetails?.pieces = pieces;

    }
    this.builder.stickerDetails.stickerNumber.piece = pieces;
    this.builder.stickerDetails.stickerNumber.price = this.builder.calculatePrice(this.numbers);
    this.builder.loadingCanvas = false;

  }

  savePdf() {
    if (this.builder.stickerDetails.name.name === 'Round') {
      this.builder.canvas.drawEllipse();
    }
    this.builder.canvas.savePdf();
  }

  reset() {
    const data: any = JSON.parse(localStorage.getItem('stickerDetails'));
    this.numbers = data.pieces;
    this.builder.stickerDetails.stickerNumber.price = data.stickerNumber.price;
    this.builder.canvas.sizeInCm.width = data.size.width;
    this.builder.canvas.sizeInCm.height = data.size.height;
    this.builder.canvas.resetSize(data.size.width, data.size.height);
  }
  deleteObject(){
    this.builder.canvas.removeSelected();
  }
  undo(){
    this.builder.canvas.undo();
  }

}
