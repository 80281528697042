<div class="mobile">
  <div *ngIf="builder.canvas">
    <div class="m-sticker-details">
      <div class="child-message" (click)="openSheet(1)">
        <div>
          {{ "message" | language }}
        </div>
        <div *ngIf="this.builder.stickerDetails.message">
          {{ this.builder.stickerDetails.message }}
        </div>
        <div *ngIf="!this.builder.stickerDetails.message">
          {{ "addCustomMessage" | language }}
        </div>
      </div>
      <div (click)="openSheet(2)" class="child-material">
        <div>{{ "material" | language }}</div>
        <div>
          {{ builder.matName ? builder.matName : ("addMaterial" | language) }}
        </div>
      </div>
    </div>

    <div class="m-sticker-details">
      <div class="child-w">
        <div>
          {{ "width" | language }}
        </div>
        <div>
          <input
            [(ngModel)]="builder.canvas.sizeInCm.width"
            (change)="builder.canvas.changeSizeInCm(1, 'nochange', true)"
            (keyup)="builder.canvas.changeSizeInCm(1, 'nochange', true)"
            type="number"
            (mouseout)="builder.canvas.changeSizeInCm(1, 'nochange', true)"
          />
        </div>
      </div>
      <div class="child-h">
        <div>
          {{ "height" | language }}
        </div>
        <div>
          <input
            [(ngModel)]="builder.canvas.sizeInCm.height"
            (change)="builder.canvas.changeSizeInCm(2, 'nochange', true)"
            (keyup)="builder.canvas.changeSizeInCm(2, 'nochange', true)"
            type="number"
            (mouseout)="builder.canvas.changeSizeInCm(2, 'nochange', true)"
          />
        </div>
      </div>
      <div class="child-n">
        <div>
          {{ "number" | language }}
        </div>
        <div>
          <input
            [(ngModel)]="numbers"
            type="number"
            (keyup)="changeNumber($event)"
          />
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="builder.canvas" class="mobile-tabs">
    <div (click)="openSheet(3)">
      <div>
        <img class="f-img" src="/assets/edit-option/add-artwork.png" alt="" />
      </div>
      <div>{{ "uploadFile" | language }}</div>
    </div>
    <div (click)="openSheet(4)">
      <div>
        <img class="f-img" src="/assets/edit-option/cutting.png" alt="" />
      </div>
      <div>{{ "cutline" | language }}</div>
    </div>
    <div (click)="openSheet(5)">
      <div>
        <img class="f-img" src="/assets/edit-option/add-text.png" alt="" />
      </div>
      <div>{{ "insertText" | language }}</div>
    </div>
    <div (click)="openSheet(6)">
      <div>
        <img class="f-img" src="/assets/edit-option/bg-color.png" alt="" />
      </div>
      <div>{{ "background" | language }}</div>
    </div>
    <div (click)="openSheet(7)">
      <div>
        <img class="f-img" src="/assets/edit-option/clipart.png" alt="" />
      </div>
      <div>{{ "clipart" | language }}</div>
    </div>
  </div>
</div>

<div class="main-div">
  <div class="card" *ngIf="builder.canvas">
    <div class="row sticker-section">
      <div class="col-lg-9 col-md-12 col-sm-12 vl">
        <div class="row">
          <div class="col-3">
            <div>
              <div>{{ "stickerByShape" | language }}</div>
              <div>
                {{ builder.stickerDetails?.name.name.toLowerCase() | language }}
              </div>
            </div>
          </div>
          <div class="col-3">
            <div>
              <div>{{ "pieces" | language }}</div>
              <div>
                <!--                <mat-form-field appearance="outline">-->
                <!--                  <input matInput [(ngModel)]="numbers" type="text" (keyup)="changeNumber($event)">-->
                <input
                  [(ngModel)]="numbers"
                  type="number"
                  name=""
                  id="2"
                  (input)="changeNumber($event)"
                  class="comments"
                />
                <!--                </mat-form-field>-->
              </div>
              <!--              <div>{{builder.stickerDetails?.pieces}} pcs</div>-->
            </div>
          </div>
          <div class="col-3">
            <div>
              <div>{{ "material" | language }}</div>
              <div *ngIf="builder.isMatName">
                {{
                  getLanguage === "en"
                    ? builder.stickerDetails?.material.attributes.title
                    : builder.stickerDetails?.material.attributes.name_fi
                }}
              </div>
              <div *ngIf="!builder.isMatName">{{ builder.matName }}</div>
            </div>
          </div>
          <!-- <div class="col-3"> -->
          <!--            <div>-->
          <!--              <div>Notes</div>-->
          <!--              <div>-->
          <!--                <textarea type="text" name="" id="1" class="comments"></textarea>-->

          <!--              </div>-->
          <!--            </div>-->
          <!-- </div> -->
          <div class="col-3 reset-web">
            <div class="col-12 order-btn1">
              <button (click)="reset()" class="order-btn btn">
                {{ "reset" | language }}
              </button>
            </div>
          </div>
        </div>

        <!--        <div class="row">-->
        <!--          <div class="col-3 head-text">Sticker by shape</div>-->
        <!--          <div class="col-3 head-text">Numbers</div>-->
        <!--          <div class="col-3 head-text">Material</div>-->
        <!--          <div class="col-3 head-text">-->
        <!--            <div>-->
        <!--              <label>Notes</label>-->

        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
        <!--        <div class="row">-->
        <!--          <div class="col-3 subhead-text">{{builder.stickerDetails?.name?.name}}</div>-->
        <!--          <div class="col-3 subhead-text">{{builder.stickerDetails?.pieces}} pcs</div>-->
        <!--          <div class="col-3 subhead-text">{{ builder.stickerDetails?.material?.title }}</div>-->
        <!--          <div class="col-3 subhead-text">-->
        <!--            <textarea type="text" name="" id="" class="comments"></textarea>-->

        <!--          </div>-->
        <!--        </div>-->
        <!--        <div class="row mt-1">-->
        <!--&lt;!&ndash;          <div class="col-6 head-text">Size(In cm)</div>&ndash;&gt;-->
        <!--          <div class="row">-->
        <!--            &lt;!&ndash;        <div class="col-3">&ndash;&gt;-->
        <!--            &lt;!&ndash;          <div class="form-group">&ndash;&gt;-->
        <!--            &lt;!&ndash;            <div class="input-group">&ndash;&gt;-->
        <!--            &lt;!&ndash;              <div class="input-group-append">&ndash;&gt;-->
        <!--            &lt;!&ndash;                              <span class="input-group-text subhead-text">&ndash;&gt;-->
        <!--            &lt;!&ndash;                                  Width&ndash;&gt;-->
        <!--            &lt;!&ndash;                              </span>&ndash;&gt;-->
        <!--            &lt;!&ndash;              </div>&ndash;&gt;-->
        <!--            &lt;!&ndash;              <input type="number" value="2" min="0"  class="form-control" [(ngModel)]="builder.canvas.size.width" (keyup)="changeSize()">&ndash;&gt;-->
        <!--            &lt;!&ndash;            </div>&ndash;&gt;-->
        <!--            &lt;!&ndash;          </div>&ndash;&gt;-->
        <!--            &lt;!&ndash;        </div>&ndash;&gt;-->
        <!--            &lt;!&ndash;        <div class="col-3">&ndash;&gt;-->
        <!--            &lt;!&ndash;          <div class="form-group">&ndash;&gt;-->
        <!--            &lt;!&ndash;            <div class="input-group mb-3">&ndash;&gt;-->
        <!--            &lt;!&ndash;              <div class="input-group-append">&ndash;&gt;-->
        <!--            &lt;!&ndash;                              <span class="input-group-text subhead-text">&ndash;&gt;-->
        <!--            &lt;!&ndash;                                  Height&ndash;&gt;-->
        <!--            &lt;!&ndash;                              </span>&ndash;&gt;-->
        <!--            &lt;!&ndash;              </div>&ndash;&gt;-->
        <!--            &lt;!&ndash;              <input type="number" value="2" min="0"  class="form-control" [(ngModel)]="builder.canvas.size.height" (keyup)="changeSize()">&ndash;&gt;-->
        <!--            &lt;!&ndash;            </div>&ndash;&gt;-->
        <!--            &lt;!&ndash;          </div>&ndash;&gt;-->
        <!--            &lt;!&ndash;        </div>    &ndash;&gt;-->
        <!--            <div class="col-lg-3 col-md-6 col-sm-6">-->
        <!--              <div class="form-group">-->
        <!--                <div class="input-group">-->
        <!--                  <div class="input-group-append">-->
        <!--                              <span class="input-group-text subhead-text">-->
        <!--                                  Width-->
        <!--                              </span>-->
        <!--                  </div>-->
        <!--                  <input type="number" value="2" min="1" max="125" class="size-inp"-->
        <!--                         [(ngModel)]="builder.canvas.sizeInCm.width" (change)="builder.canvas.changeSizeInCm(1)" (keyup)="builder.canvas.changeSizeInCm(1)">-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--            <div class="col-lg-3 col-md-6 col-sm-6">-->
        <!--              <div class="form-group">-->
        <!--                <div class="input-group mb-3">-->
        <!--                  <div class="input-group-append">-->
        <!--                              <span class="input-group-text subhead-text">-->
        <!--                                  Height-->
        <!--                              </span>-->
        <!--                  </div>-->
        <!--                  <input type="number" value="2" min="1" max="125" class="size-inp"-->
        <!--                         [(ngModel)]="builder.canvas.sizeInCm.height" (change)="builder.canvas.changeSizeInCm(1)" (keyup)="builder.canvas.changeSizeInCm(2)">-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
      <div class="col-lg-3 col-md-12 col-sm-12 order-col">
        <div class="head-text">{{ "total" | language }}</div>
        <div class="row">
          <div class="col-12 money-text">
            € {{ builder.stickerDetails.stickerNumber.price }}
            <sub class="sub-text">{{ "includingVat" | language }}</sub>
          </div>
          <div class="col-12 order-btn1">
            <button (click)="savePdf()" class="order-btn btn">
              {{ "placeOrder" | language }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
