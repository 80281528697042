<div class="card" style="height: 100%">
  <div class="card-header clip-text">{{ "selectClipart" | language }}</div>
  <div class="card-body max-height">
   
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button
            aria-controls="tab1"
            aria-selected="true"
            class="nav-link active tab-btn"
            data-bs-target="#tab1"
            data-bs-toggle="tab"
            id="li_tab1"
            role="tab"
            type="button"
          >
            <img src="./assets/tabsicon/1TabIconstickerwizard.png"
                  alt="" style="width: 24px; height: 24px"
                />
          </button>
        </li>       
        <li class="nav-item" role="presentation">
          <button
            aria-controls="tab2"
            aria-selected="false"
            class="nav-link tab-btn"
            data-bs-target="#tab2"
            data-bs-toggle="tab"
            id="li_tab2"
            role="tab"
            type="button"
          >
           <img src="./assets/tabsicon/2TabIconstickerwizard.png"
                  alt="" style="width: 24px; height: 24px"
                />
          </button>
        </li>       
        <li class="nav-item" role="presentation">
          <button
            aria-controls="tab3"
            aria-selected="false"
            class="nav-link tab-btn"
            data-bs-target="#tab3"
            data-bs-toggle="tab"
            id="li_tab3"
            role="tab"
            type="button"
          >
           <img src="./assets/tabsicon/3TabIconstickerwizard.png"
                  alt="" style="width: 24px; height: 24px"
                />
          </button>
        </li>
       <li class="nav-item" role="presentation">
                <button
                  aria-controls="tab4"
                  aria-selected="false"
                  class="nav-link tab-btn"
                  data-bs-target="#tab4"
                  data-bs-toggle="tab"
                  id="li_tab4"
                  role="tab"
                  type="button"
                >
                  <img src="./assets/tabsicon/4TabIconstickerwizard.png"
                  alt="" style="width: 24px; height: 24px"
                />
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  aria-controls="tab5"
                  aria-selected="false"
                  class="nav-link tab-btn"
                  data-bs-target="#tab5"
                  data-bs-toggle="tab"
                  id="li_tab5"
                  role="tab"
                  type="button"
                >
                 <img src="./assets/tabsicon/5TabIconstickerwizard.png"
                  alt="" style="width: 24px; height: 24px"
                />
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  aria-controls="tab6"
                  aria-selected="false"
                  class="nav-link tab-btn"
                  data-bs-target="#tab6"
                  data-bs-toggle="tab"
                  id="li_tab6"
                  role="tab"
                  type="button"
                >
                  <img src="./assets/tabsicon/6TabIconstickerwizard.png"
                  alt="" style="width: 24px; height: 24px"
                />
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  aria-controls="tab7"
                  aria-selected="false"
                  class="nav-link tab-btn"
                  data-bs-target="#tab7"
                  data-bs-toggle="tab"
                  id="li_tab7"
                  role="tab"
                  type="button"
                >
                  <img src="./assets/tabsicon/7TabIconstickerwizard.png"
                  alt="" style="width: 24px; height: 24px"
                />
                </button>
              </li>
      </ul>

      <div class="tab-content card-body max-height max-min-height"
        id="myTabContent" *ngIf="clipArt">
        <div 
          *ngFor="let key of objectKey(formatedTabs())"
          class= "{{key=='tab1' ? 'tab-pane fade show active':'tab-pane fade'}}"
          id="{{key}}"
          role="tabpanel"
        >
          <img *ngFor="let clip of formatedTabs()[key]" (click)="getImgPolaroid($event)" alt="" class="images-item" src="{{BASE_URL}}{{clip.attributes.image.data?.attributes?.url}}"> 
        </div>
      </div>
      <!--<ng-container *ngFor="let art of clipArt">
        <img
          class="images-item"
          (click)="getImgPolaroid($event)"
          [src]="BASE_URL + art.attributes.image.data?.attributes?.url"
          alt=""
        />
      </ng-container>-->
    
    <!--    <img class="images-item" (click)="getImgPolaroid($event)" src="/assets/img/pikachu.svg">-->
    <!--    <img class="images-item" (click)="getImgPolaroid($event)" src="/assets/img/squirtle.svg">-->
    <!--    <img class="images-item" (click)="getImgPolaroid($event)" src="/assets/img/bullbasaur.svg">-->
    <!--    <img class="images-item" (click)="getImgPolaroid($event)" src="/assets/img/charmander.svg">-->
    <!--    <img class="images-item" (click)="getImgPolaroid($event)" src="/assets/img/bellsprout.svg">-->
    <!--    <img class="images-item" (click)="getImgPolaroid($event)" src="/assets/img/caterpie.svg">-->
    <!--    <img class="images-item" (click)="getImgPolaroid($event)" src="/assets/img/dratini.svg">-->
    <!--    <img class="images-item" (click)="getImgPolaroid($event)" src="/assets/img/eevee.svg">-->
    <!--    <img class="images-item" (click)="getImgPolaroid($event)" src="/assets/img/jigglypuff.svg">-->
    <!--    <img class="images-item" (click)="getImgPolaroid($event)" src="/assets/img/mankey.svg">-->
    <!--    <img class="images-item" (click)="getImgPolaroid($event)" src="/assets/img/meowth.svg">-->
    <!--    <img class="images-item" (click)="getImgPolaroid($event)" src="/assets/img/pidgey.svg">-->
    <!--    <img class="images-item" (click)="getImgPolaroid($event)" src="/assets/img/psyduck.svg">-->
    <!--    <img class="images-item" (click)="getImgPolaroid($event)" src="/assets/img/rattata.svg">-->
    <!--    <img class="images-item" (click)="getImgPolaroid($event)" src="/assets/img/abra.svg">-->
    <!--    <img class="images-item" (click)="getImgPolaroid($event)" src="/assets/img/snorlax.svg">-->
    <!--    <img class="images-item" (click)="getImgPolaroid($event)" src="/assets/img/zubat.svg">-->
  </div>
</div>
