<app-header></app-header>
<div>
  <!--  <div class="header">-->
  <!--    Payment-->
  <!--  </div>-->
  <div>
    <div class="row" style="background: #efefef">
      <div class="col-lg-8 col-md-12 align-self-center">
        <div class="payment-card">
          <div class="pay-text">{{ "stickerDetails" | language }}</div>
          <div class="row" style="padding-top: 20px; align-items: center">
            <!--            <div class="col-lg-4 col-md-12">-->
            <!--              <div>-->
            <!--                <img src="assets/img/fox-sticker.png" alt="" class="w-100">-->
            <!--              </div>-->
            <!--            </div>-->
            <div class="col-lg-2 col-md-12">
              <div class="row">
                <!--                <h6 class="cancel-text">Cancel Order</h6>-->
                <div class="col-6">
                  <p class="sticker-text">{{ "shape" | language }}</p>
                  <h6
                    class="cut-text"
                    *ngIf="builder.stickerDetails.name.name === 'Cutting'"
                  >
                    {{ "singleCutting" | language }}
                  </h6>
                  <h6
                    class="cut-text"
                    *ngIf="builder.stickerDetails.name.name !== 'Cutting'"
                  >
                    {{
                      builder.stickerDetails.name.name.toLowerCase() | language
                    }}
                  </h6>
                  <!--                  <button class="builder-btn">Back to Builder</button>-->
                </div>
                <!--                <div class="col-6">-->
                <!--                  <p class="sticker-text">Material</p>-->
                <!--                  <h6 class="cut-text">Glossy Vinyl</h6>-->
                <!--                  <button class="builder-btn">Copy sticker</button>-->
                <!--                </div>-->
              </div>
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="row">
                <div class="col-6">
                  <p class="sticker-text">
                    {{ "numberOfStickers" | language }}
                  </p>
                  <h6 class="cut-text">
                    {{ builder.stickerDetails.stickerNumber.piece }}
                    {{ "pcs" | language }}
                  </h6>
                </div>
                <div class="col-6">
                  <p class="sticker-text">{{ "selectedSize" | language }}</p>
                  <!--                  <h6 class="cut-text">{{builder.stickerDetails.customSizes ?  builder.stickerDetails.customSizes.width +  '*' + builder.stickerDetails.customSizes.width : builder.stickerDetails.size.width + '*' + builder.stickerDetails.size.height }}</h6>-->
                  <h6 class="cut-text">
                    {{
                      builder.stickerDetails.size.width +
                        " * " +
                        builder.stickerDetails.size.height
                    }}
                    {{
                      builder.stickerDetails.name.name === "nameTag"
                        ? " mm"
                        : " cm"
                    }}
                  </h6>
                  <!--                  <p class="sticker-text">Total</p>-->
                  <!--                  <h6 class="cut-text">€ 124</h6>-->
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="payment-card">
          <div class="row">
            <div class="pay-text">{{ "paymentMethod" | language }}</div>
            <div>
              <img class="pay-img" src="assets/img/klarna.png" alt="" />
            </div>
          </div>
          <div class="row">
            <div class="time-text">
              {{ "estimatedDeliveryTime" | language }}
            </div>
            <div class="col-6">
              <mat-radio-group [(ngModel)]="deliveryType">
                <mat-radio-button value="standard" class="w-100">{{
                  "mailbusinessdays" | language
                }}</mat-radio-button>
                <!-- <mat-radio-button value="fast" class="w-100">{{"fast"|language}}</mat-radio-button>
                <mat-radio-button value="express" class="w-100">{{"expressDelivery"|language}}</mat-radio-button> -->
              </mat-radio-group>
              <!--              <div class="form-check radio-div">-->
              <!--                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">-->
              <!--                <label class="form-check-label pay-label" for="flexRadioDefault1">-->
              <!--                  Standard <span class="date">01 Dec- 02 Dec</span>-->
              <!--                </label>-->
              <!--              </div>-->
              <!--              <div class="form-check radio-div">-->
              <!--                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked>-->
              <!--                <label class="form-check-label pay-label" for="flexRadioDefault2">-->
              <!--                  Fast <span class="date">01 Dec- 02 Dec</span> <span class="total-card">€ 20</span>-->
              <!--                </label>-->
              <!--              </div>-->
              <!--              <div class="form-check radio-div">-->
              <!--                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" checked>-->
              <!--                <label class="form-check-label pay-label" for="flexRadioDefault3">-->
              <!--                  Express delivery <span class="date">01 Dec- 02 Dec</span> <span class="total-card">€ 50</span>-->
              <!--                </label>-->
              <!--              </div>-->
            </div>
            <div class="col-6">
              <div class="img-div">
                <img class="pay-img" src="assets/img/posti.png" alt="" />
              </div>
              <!-- <div class="img-div"><img class="pay-img" src="assets/img/ups.png" alt=""></div>
              <div class="img-div"><img class="pay-img" src="assets/img/ups.png" alt=""></div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12">
        <div>
          <div class="card-delivery">
            <form [formGroup]="userDetail" autocomplete="on">
              <div class="row">
                <mat-form-field class="col-lg-6 col-md-12" appearance="outline">
                  <mat-label>
                    {{ "firstName" | language }}
                  </mat-label>
                  <input
                    formControlName="name"
                    placeholder="{{ 'firstName' | language }}"
                    matInput
                    type="text"
                    autocomplete="give-name"
                  />
                  <mat-error>{{ "enterValidName" | language }}</mat-error>
                </mat-form-field>
                <mat-form-field class="col-lg-6 col-md-12" appearance="outline">
                  <mat-label>
                    {{ "lastName" | language }}
                  </mat-label>
                  <input
                    formControlName="last_name"
                    placeholder="{{ 'lastName' | language }}"
                    matInput
                    type="text"
                    autocomplete="family-name"
                  />
                  <mat-error>{{ "enterLastName" | language }}</mat-error>
                </mat-form-field>
              </div>

              <div class="row">
                <mat-form-field class="col-lg-6 col-md-12" appearance="outline">
                  <mat-label>
                    {{ "email" | language }}
                  </mat-label>
                  <input
                    formControlName="email"
                    placeholder="{{ 'email' | language }}"
                    matInput
                    type="text"
                    autocomplete="email"
                  />
                  <mat-error>{{ "pleaseEnterMail" | language }}</mat-error>
                </mat-form-field>
                <mat-form-field class="col-lg-6 col-md-12" appearance="outline">
                  <mat-label>
                    {{ "phone" | language }}
                  </mat-label>
                  <input
                    formControlName="phone"
                    placeholder="{{ 'phone' | language }}"
                    matInput
                    type="text"
                    autocomplete="tel"
                  />
                  <mat-error>{{ "pleEntValNum" | language }}</mat-error>
                </mat-form-field>
              </div>

              <div class="row">
                <mat-form-field class="col-12" appearance="outline">
                  <mat-label>
                    {{ "address" | language }}
                  </mat-label>
                  <input
                    formControlName="address"
                    placeholder="{{ 'address' | language }}"
                    matInput
                    type="text"
                    autocomplete="street-address"
                  />
                  <mat-error>{{ "pleseEnterAddress" | language }}</mat-error>
                </mat-form-field>

                <mat-form-field class="col-lg-6 col-md-12" appearance="outline">
                  <mat-label>
                    {{ "city" | language }}
                  </mat-label>
                  <input
                    formControlName="city"
                    placeholder="{{ 'city' | language }}"
                    matInput
                    type="text"
                    autocomplete="address-line2"
                  />
                  <mat-error>{{ "pleEnCit" | language }}</mat-error>
                </mat-form-field>

                <mat-form-field class="col-lg-6 col-md-12" appearance="outline">
                  <mat-label>
                    {{ "postCode" | language }}
                  </mat-label>
                  <input
                    formControlName="postcode"
                    placeholder="{{ 'postCode' | language }}"
                    matInput
                    type="text"
                    autocomplete="postal-code"
                  />
                  <mat-error>{{ "pleaseEnterPostCode" | language }}</mat-error>
                </mat-form-field>
              </div>

              <div class="row">
                <mat-form-field class="col-12" appearance="outline">
                  <mat-label>{{ "message" | language }}</mat-label>
                  <textarea
                    formControlName="message"
                    matInput
                    type="text"
                    [(ngModel)]="builder.stickerDetails.message"
                  ></textarea>
                </mat-form-field>
              </div>
            </form>
          </div>

          <div class="card-delivery">
            <div class="row">
              <div class="col-6">
                <!--                <div class="amount-text">Amount (excluding VAT)</div>-->
                <!--                <div class="amount-text">Delivery (excluding VAT)</div>-->
                <div class="amount-text">
                  <div class="due-text">{{ "grandTotal" | language }}</div>
                </div>
              </div>
              <div class="col-6">
                <!--                <div class="amount-number">€{{builder.stickerDetails.stickerNumber.price}}</div>-->
                <!--                <div class="amount-number">€ 0</div>-->
                <!--                <div class="amount-number">€ 13.55</div>-->
                <div class="due-number">
                  €{{ builder.stickerDetails.stickerNumber.price }}
                </div>
              </div>
              <div>
                <button class="pay-btn" (click)="createOrder()">
                  {{ "continueToPay" | language }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
