<!--<app-header></app-header>-->
<!--<div class="full-height">-->
<!--  <div>-->
<!--    <div>-->
<!--      <div class="mt-5 sticker-details2">-->
<!--        &lt;!&ndash;          <div>{{builder.canvas}}</div>&ndash;&gt;-->
<!--        &lt;!&ndash;          <div *ngIf="builder.canvas" class="text-center"> {{builder.canvas.size.width}}</div>&ndash;&gt;-->
<!--        <div style="min-height: 70vh">-->
<!--          &lt;!&ndash;            <div *ngIf="builder.canvas" class="text-center mr-3 align-self-center"> {{builder.canvas.size.height}}</div>&ndash;&gt;-->
<!--&lt;!&ndash;          <div>Width : {{builder.canvas.sizeInCm.width}}</div>&ndash;&gt;-->
<!--          <div #canvasCont (window:resize)="onResize(canvasCont.getBoundingClientRect())">-->
<!--            <angular-editor-fabric-js #canvas></angular-editor-fabric-js>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="sticker-details">-->

<!--          <app-sticker-detail></app-sticker-detail>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div>-->
<!--        <div class="side-bar">-->
<!--          &lt;!&ndash;          Edit playground&ndash;&gt;-->
<!--          <div class="edit-play">-->
<!--            &lt;!&ndash;            <app-sticker-material></app-sticker-material>&ndash;&gt;-->
<!--            <div class="card card-height">-->
<!--              <app-add-image *ngIf="builder.selected === 1"></app-add-image>-->
<!--              <app-sticker-material *ngIf="builder.selected === 2"></app-sticker-material>-->
<!--              <app-cut-options *ngIf="builder.selected === 3"></app-cut-options>-->
<!--              <app-add-text *ngIf="builder.selected === 4"></app-add-text>-->
<!--              <app-canvas-bg *ngIf="builder.selected === 5"></app-canvas-bg>-->
<!--              <app-add-clipart *ngIf="builder.selected === 6"></app-add-clipart>-->
<!--            </div>-->
<!--          </div>-->
<!--          &lt;!&ndash;          Edit options&ndash;&gt;-->
<!--          <div>-->
<!--            <div class="edit-option-cont">-->
<!--              <app-edit-options></app-edit-options>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
<app-loader *ngIf="loading" [setCss]="true"></app-loader>

<div>
  <app-header></app-header>

  <div class="mobile-order">
    <div class="p-2">
      <div class="d-flex justify-content-between">
        <div>
          <button
            class="inc-btn"
            (click)="builder.canvas.undo()"
            mat-flat-button
            [disabled]="builder.disableBtn"
          >
            <svg width="20" height="19" viewBox="0 0 25 24">
              <path
                d="M24.9773 15.7323C24.9773 10.8815 21.7011 7.52915 15.898 7.52915H7.37731L4.39318 7.66888L6.80592 5.61172L10.4631 2.03075C10.6662 1.82757 10.7678 1.58631 10.7678 1.25613C10.7678 0.595843 10.3233 0.138672 9.65037 0.138672C9.37097 0.138672 9.04079 0.278348 8.82494 0.494255L1.35829 7.82123C1.10429 8.0625 0.977295 8.35458 0.977295 8.67202C0.977295 8.97678 1.10429 9.26887 1.35829 9.51013L8.82494 16.8371C9.04079 17.0657 9.37097 17.1927 9.65037 17.1927C10.3233 17.1927 10.7678 16.7355 10.7678 16.0752C10.7678 15.7451 10.6662 15.5038 10.4631 15.3006L6.80592 11.7324L4.39318 9.67522L7.37731 9.80216H16.0884C20.4186 9.80216 22.717 12.1895 22.717 15.6054C22.717 19.0339 20.4186 21.5736 16.0884 21.5736H13.1678C12.4694 21.5736 12.0122 22.0816 12.0122 22.7038C12.0122 23.326 12.4694 23.834 13.1678 23.834H16.1012C21.7901 23.834 24.9773 20.5958 24.9773 15.7323Z"
                fill="currentColor"
              />
            </svg>
          </button>
        </div>
        <button (click)="reset()" class="order-btn btn">
          {{ "reset" | language }}
        </button>
        <button class="order-btn" mat-flat-button (click)="savePdf()">
          {{ "placeOrder" | language }} :{{
            builder.stickerDetails.stickerNumber.price | currency : "EUR"
          }}
        </button>
      </div>
    </div>
  </div>

  <div class="div-0">
    <div class="back mobile_remove_back">
      <span class="material-icons" (click)="back()"> arrow_back </span>
    </div>
    <div class="div-1">
      <div class="canvas-cont" id="canvasSizeSet" #parentCont>
        <div
          class="child"
          #canvasCont
          (window:resize)="
            onResize(
              canvasCont.getBoundingClientRect(),
              parentCont.getBoundingClientRect()
            )
          "
        >
          <div
            *ngIf="builder.loadingCanvas"
            style="
              position: absolute;
              left: 0;
              right: 0;
              display: flex;
              height: 100%;
              z-index: 99;
              background: #00000045;
            "
          >
            <div style="flex-grow: 1; align-self: center">
              <mat-spinner
                style="display: inline-block; z-index: 99"
              ></mat-spinner>
            </div>
          </div>
          <angular-editor-fabric-js
            #canvas
            (textDataCreated)="getTextProperty($event)"
          ></angular-editor-fabric-js>
        </div>
      </div>
      <div class="sticker-details">
        <div class="mobile">
          <div class="p-2">
            <button
              (click)="builder.canvas.confirmClear()"
              mat-flat-button
              class="clear-button w-100"
            >
              {{ "clearCanvas" | language }}
            </button>
          </div>
        </div>
        <app-sticker-detail></app-sticker-detail>
      </div>
    </div>
    <div class="reset">
      <div class="col-12 order-btn1">
        <button (click)="deleteObject()" class="deleteBtn order-btn btn">
          {{ "delete" | language }}
        </button>
      </div>
    </div>

    <div class="div-2">
      <div class="side-bar">
        <!--          Edit playground-->
        <div class="side-bar-flex">
          <div class="edit-play">
            <!--            <app-sticker-material></app-sticker-material>-->
            <div>
              <div>
                <div
                  class="card card-height"
                  style="
                    flex-grow: 1;
                    border-color: white !important;
                    border-radius: 0;
                  "
                >
                  <app-add-image *ngIf="builder.selected === 1"></app-add-image>
                  <app-sticker-material
                    [material]="material"
                    *ngIf="builder.selected === 2"
                  ></app-sticker-material>
                  <app-cut-options
                    *ngIf="builder.selected === 3"
                  ></app-cut-options>
                  <app-add-text *ngIf="builder.selected === 4"></app-add-text>
                  <app-canvas-bg *ngIf="builder.selected === 5"></app-canvas-bg>
                  <app-add-clipart
                    [clipArt]="clipArt"
                    *ngIf="builder.selected === 6"
                  ></app-add-clipart>
                </div>
                <div *ngIf="builder?.canvas">
                  <div class="p-2">
                    <button
                      (click)="builder.canvas.confirmClear()"
                      mat-flat-button
                      class="clear-button w-100"
                    >
                      {{ "clearCanvas" | language }}
                    </button>
                  </div>
                  <div class="p-2">
                    <div>
                      <div style="color: black; margin: 5px">
                        {{ "enterSize" | language }}
                      </div>
                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-append">
                            <span class="input-group-text subhead-text">
                              {{ "width" | language }}
                            </span>
                          </div>
                          <input
                            step="0.1"
                            type="number"
                            value="2"
                            max="125"
                            class="size-inp"
                            [(ngModel)]="builder.canvas.sizeInCm.width"
                            (change)="
                              builder.canvas.changeSizeInCm(1, 'nochange', true)
                            "
                            (keyup)="
                              builder.canvas.changeSizeInCm(1, 'nochange', true)
                            "
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-append">
                            <span class="input-group-text subhead-text">
                              {{ "height" | language }}
                            </span>
                          </div>
                          <input
                            step="0.1"
                            type="number"
                            value="2"
                            max="125"
                            class="size-inp"
                            [(ngModel)]="builder.canvas.sizeInCm.height"
                            (change)="
                              builder.canvas.changeSizeInCm(2, 'nochange', true)
                            "
                            (keyup)="
                              builder.canvas.changeSizeInCm(2, 'nochange', true)
                            "
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <!--Change cutting-->

                  <div
                    class="p-2"
                    *ngIf="builder.stickerDetails.name.name === 'Cutting'"
                  >
                    <div>{{ "cuttingSize" | language }}</div>
                    <div class="d-flex justify-content-between mt-2">
                      
                      <mat-slider
                        (change)="onInputChange($event)"
                        color="primary"
                        min="1"
                        max="4"
                        step="1"
                        [value]="grownumber"
                      >
                      </mat-slider>
                    </div>
                  </div>
                  <!--                  <button mat-flat-button class="ml-1" >Increase cutting</button>-->
                  <!--                  <button mat-flat-button class="ml-1">Decrease cutting</button>-->
                </div>
              </div>
            </div>
          </div>
          <!--          Edit options-->
          <div>
            <div class="edit-option-cont">
              <app-edit-options></app-edit-options>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--  <div class="div-3">-->

  <!--  </div>-->

  <!--  <app-footer></app-footer>-->
</div>
