import { Component, Input, OnInit } from "@angular/core";
import { BuilderService } from "../services/builder.service";
import { ApiService } from "../services/api.service";
import { AppConstants } from "../../AppConstants";
import { main } from "@angular/compiler-cli/src/main";
import { title } from "process";

@Component({
  selector: "app-sticker-material",
  templateUrl: "./sticker-material.component.html",
  styleUrls: ["./sticker-material.component.scss"],
})
export class StickerMaterialComponent implements OnInit {
  public active = false;

  // material = [];
  private activeChip: boolean;
  BASE_URL = AppConstants.BASE_URL;
  @Input() material;
  getLanguage;

  constructor(private builder: BuilderService, private api: ApiService) {
    this.material = JSON.parse(localStorage.getItem("material"));
  }

  ngOnInit(): void {
    // this.getMaterial();sa
    this.getLanguage = localStorage.getItem("lang");
    console.log("this is material", this.material);
    if (!this.material) {
      this.getMaterial();
    }
  }

  changeBorder() {
    this.active = !this.active;
  }

  // selectMaterial(index, url) {
  //   // tslint:disable-next-line:prefer-for-of
  //   console.log('this is the index', index);
  //   for (let i = 0; i < this.material.length; i++) {
  //     i === index ? this.material[i].selected = true : this.material[i].selected = false;
  //     this.builder.material = this.material[i];
  //   }
  //   this.builder.canvas.setBackgroundImage1(url);
  // }

  /**
   * function to be used for selecting the material type
   * @param id used for getting the selected material
   * @param name name used for saving the details
   */
  selectMaterial(id: number, name: { attributes: { title: any } }[]) {
    // this.builder.matName = name[id - 1].attributes.title;
    this.builder.isMatName = true;
    // this.material = name.attributes;
    let chosenMaterial;
    console.log(this.material);
    this.material.forEach((el) => {
      if (el.id === id) {
        el.active = true;
        this.builder.stickerDetails.material = el;
        chosenMaterial = el;
        console.log("this is chosen material", chosenMaterial);
        this.builder.stickerDetails.material = chosenMaterial;
        this.builder.cutOutline = false;
        const setText =
          this.getLanguage === "en"
            ? chosenMaterial.attributes.title
            : chosenMaterial.attributes.name_fi;
        const ChosenMaterialText =
          this.getLanguage === "en"
            ? "Selected material"
            : "Valittu materiaali";
        this.builder.showSuccess(ChosenMaterialText, setText);
        this.activeChip = false;
      } else {
        el.active = false;
      }
    });
    this.builder.selectedMarerial.emit(chosenMaterial);
    this.builder.stickerDetails.material = chosenMaterial;
    
    //this.builder.canvas.setBackgroundImage1(chosenMaterial);
    localStorage.setItem("getMobMaterial", JSON.stringify(chosenMaterial));
  }

  getMaterial() {
    this.api.getMaterial().subscribe(
      (materialData) => {
        materialData.data.forEach((material) => {
          material.selected = false;
        });
        this.material = materialData.data;

        console.log("these are materials", materialData);
      },
      (error) => {
        console.log("ERROR material", error);
      }
    );
  }
}
