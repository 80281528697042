import {Injectable} from '@angular/core';
import {BreakpointObserver} from '@angular/cdk/layout';
import {IText} from 'fabric/fabric-impl';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  public isMobile = false;
  sizes = {
    size4: {
      image: '162px',
      lines: {
        line1: '300px',
        line2: '120px',
        line3: '120px'
      }
    },
    size6: {
      image: '87px',
      lines: {
        line1: '148px',
        line2: '120px',
        line3: '120px'
      }
    },
    size8: {
      image: '70px',
      lines: {
        line1: '115px',
        line2: '100px',
        line3: '100px'
      }
    },
    size10: {
      image: '52px',
      lines: {
        line1: '95px',
        line2: '120px',
        line3: '120px'
      }
    },
    size12: {
      image: '44px',
      lines: {
        line1: '70px',
        line2: '72px',
        line3: '72px'
      }
    },
    size16: {
      image: '32px',
      lines: {
        line1: '58px',
        line2: '60px',
        line3: '60px'
      }
    },
    size18: {
      image: '28px',
      lines: {
        line1: '50px',
        line2: '42px',
        line3: '42px'
      }
    },
    size22: {
      image: '23px',
      lines: {
        line1: '40px',
        line2: '42px',
        line3: '42px'
      }
    },
    size26: {
      image: '20px',
      lines: {
        line1: '34px',
        line2: '36px',
        line3: '36px'
      }
    },
    size30: {
      image: '18px',
      lines: {
        line1: '30px',
        line2: '28px',
        line3: '28px'
      }
    },
    size: {
      image: '18px',
      lines: {
        line1: '28px',
        line2: '30px',
        line3: '22px'
      }
    }
  };

  sizesSm = {
    size4: {
      image: '65px',
      lines: {
        line1: '88px',
        line2: '86px',
        line3: '86px'
      }
    },
    size6: {
      image: '15px',
      lines: {
        line1: '60px',
        line2: '120px',
        line3: '120px'
      }
    },
    size8: {
      image: '30px',
      lines: {
        line1: '59px',
        line2: '64px',
        line3: '64px'
      }
    },
    size10: {
      image: '23px',
      lines: {
        line1: '45px',
        line2: '120px',
        line3: '120px'
      }
    },
    size12: {
      image: '19px',
      lines: {
        line1: '36px',
        line2: '52px',
        line3: '52px'
      }
    },
    size16: {
      image: '14px',
      lines: {
        line1: '26px',
        line2: '42px',
        line3: '42px'
      }
    },
    size18: {
      image: '12px',
      lines: {
        line1: '22px',
        line2: '28px',
        line3: '28px'
      }
    },
    size22: {
      image: '10px',
      lines: {
        line1: '16px',
        line2: '24px',
        line3: '24px'
      }
    },
    size26: {
      image: '8px',
      lines: {
        line1: '14px',
        line2: '18px',
        line3: '18px'
      }
    },
    size30: {
      image: '7px',
      lines: {
        line1: '5px',
        line2: '20px',
        line3: '20px'
      }
    },
    size: {
      image: '10px',
      lines: {
        line1: '28px',
        line2: '30px',
        line3: '22px'
      }
    }
  };
  public screenWidth = 0;
  public screenSize = 'desktop';
  private len = 0;
  juniorSize = 1;


  constructor(private observer: BreakpointObserver) {
    this.screenSize = window.innerWidth <= 540 ? 'mobile' : 'desktop';
    this.screenWidth = window.innerWidth;
    this.isMobile = this.screenWidth < 540;
  }

  public getFontSize(text: string, image: boolean, lines: number, texts: any): string {
    // find out screen size
    const screenSize = window.innerWidth;
    if (screenSize < 532) {
      this.sizes = this.sizesSm;
    }

    // else if (screenSize > 532 && screenSize < 900) {
    //   this.sizes = this.sizesMd;
    // } else if (screenSize > 532 && screenSize > 900) {
    //   this.sizes = this.sizesLg;
    // } else {
    //   console.log('no change required!');
    // }

    let len = text.length;
    this.len = len;
    if (texts.line1.text.length > len) {
      console.log(texts.line1.text.length > len, texts.line1.text.length, texts.line1.text);
      len = texts.line1.text.length;
    }
    if (texts.line2.text.length > len) {
      console.log(texts.line2.text.length > len, texts.line2.text.length, texts.line2.text);
      len = texts.line2.text.length;
    }
    if (texts.line3.text.length > len) {
      console.log(texts.line3.text.length > len, texts.line3.text.length, texts.line3.text);
      len = texts.line3.text.length;
    }
    console.log('Length', len, screenSize);

    let line = '';
    if (lines === 1) {
      line = 'line1';
    } else if (lines === 2) {
      line = 'line2';
    } else {
      line = 'line3';
    }

    if (!image) {
      // if (lines === 2 && texts.line1.text.length > 0) {
      //   console.log('Here in len2', texts.line1.size);
      //   return texts.line1.size;
      // }

      // if ((lines === 3) && (texts.line1.text.length > 0 || texts.line2.text.length > 0)) {
      //   console.log('Here in len3', texts.line1.text.length > 0 ? texts.line1.size : texts.line2.size);
      //   return texts.line1.text.length > 0 ? texts.line1.size : texts.line2.size;
      // }
    }


    if (len <= 3) {
      return !image ? this.sizes.size4.lines[line] : this.sizes.size4.image;
    } else if (len <= 5) {
      return !image ? this.sizes.size6.lines[line] : this.sizes.size6.image;
    } else if (len <= 8) {
      return !image ? this.sizes.size8.lines[line] : this.sizes.size8.image;
    } else if (len <= 10) {
      return !image ? this.sizes.size10.lines[line] : this.sizes.size10.image;
    } else if (len <= 12) {
      return !image ? this.sizes.size12.lines[line] : this.sizes.size12.image;
    } else if (len <= 16) {
      return !image ? this.sizes.size16.lines[line] : this.sizes.size16.image;
    } else if (len <= 18) {
      return !image ? this.sizes.size18.lines[line] : this.sizes.size18.image;
    } else if (len <= 22) {
      return !image ? this.sizes.size22.lines[line] : this.sizes.size22.image;
    } else if (len <= 26) {
      return !image ? this.sizes.size26.lines[line] : this.sizes.size26.image;
    } else if (len <= 30) {
      return !image ? this.sizes.size30.lines[line] : this.sizes.size30.image;
    } else {
      return !image ? this.sizes.size4.lines[line] : this.sizes.size.image;
    }

  }

  public getMarginClass() {
    if (!this.isMobile) {
      if (this.len < 8) {
        return 'line3';
      } else if (this.len < 18) {
        return 'line2';
      } else {
        return 'line2';
      }
    }
  }

  getLinesUsed(texts: any, type: number) {
    const line1 = texts.line1.text.length > 0 ? 1 : 0;
    const line2 = texts.line2.text.length > 0 ? 1 : 0;
    const line3 = texts.line3.text.length > 0 ? 1 : 0;
    let sum = (line1 + line2 + line3);
    if (sum < 2 && type > 1) {
      sum = sum + 1;
    }
    return sum;
  }

  textExists(texts: any): boolean {
    const line1 = texts.line1.text.length > 0 ? 1 : 0;
    const line2 = texts.line2.text.length > 0 ? 1 : 0;
    const line3 = texts.line3.text.length > 0 ? 1 : 0;
    const sum = (line1 + line2 + line3);
    return sum > 0;
  }

  canvasLayout(texts: any, type, event) {

    console.log('TExts', texts.line1.text.length > 0);
    const layout = {
      line1: {
        exist: texts.line1.text.length > 0,
        top: 0,
        size: texts.line1.size
      },
      line2: {
        exist: texts.line2.text.length > 0,
        top: 0,
        size: texts.line2.size
      },
      line3: {
        exist: texts.line3.text.length > 0,
        top: 0,
        size: texts.line3.size
      }
    };


    const sum = this.getLineSum(texts);
    if (sum === 1) {
      layout.line1.top = 10;
      layout.line1.size = Number(texts.line1.size.replace('px', '')) * (this.juniorSize === 1 ? 0.7 : 1.4);
      layout.line2.top = 0;
      layout.line3.top = 0;
      layout.line2.size = 0;
      layout.line3.size = 0;
    } else if (sum === 2) {
      if (!layout.line1.exist) {
        layout.line1.top = 0;
        layout.line1.size = 0;
        layout.line2.top = 0;
        layout.line3.top = event ? 10 + this.getNumber(texts.line2.size.replace('px', '')) : 10 + this.getNumber(texts.line1.size.replace('px', '')) / (this.juniorSize === 1 ? 2 : 1);
      } else if (!layout.line2.exist) {
        layout.line1.top = 0;
        layout.line1.size = Number(texts.line1.size.replace('px', '')) / 2;
        layout.line3.top = event ? 10 + this.getNumber(texts.line1.size.replace('px', '')) : this.getNumber(texts.line1.size.replace('px', '')) / (this.juniorSize === 1 ? 2 : 1);
        layout.line3.size = 10 + this.getNumber(texts.line3.size.replace('px', '')) / 2;
      } else if (!layout.line3.exist) {
        layout.line1.top = 0;
        layout.line1.size = this.getNumber(texts.line1.size.replace('px', '')) / 2;
        layout.line2.top = event ? 10 + this.getNumber(texts.line1.size.replace('px', '')) : this.getNumber(texts.line1.size.replace('px', '')) / (this.juniorSize === 1 ? 2 : 1);
        layout.line2.size = this.getNumber(texts.line1.size.replace('px', '')) / 2;
      }
    } else if (sum === 3) {
      console.log('Here ****');
      layout.line1.top = 0;
      layout.line1.size = this.getNumber(texts.line1.size.replace('px', '') / (this.juniorSize === 1 ? 2 : 1));
      layout.line2.top = event ? 10 + this.getNumber(texts.line1.size.replace('px', '')) : this.getNumber(texts.line1.size.replace('px', '') / (this.juniorSize === 1 ? 2 : 1));
      layout.line2.size = this.getNumber(texts.line1.size.replace('px', '') / (this.juniorSize === 1 ? 2 : 1));
      layout.line3.top = event ? 10 + this.getNumber(texts.line2.size.replace('px', '')) * 2 : this.getNumber(texts.line1.size.replace('px', ''));
      layout.line3.size = this.getNumber(texts.line1.size.replace('px', '') / (this.juniorSize === 1 ? 2 : 1));
    }
    console.log('Layout', layout);

    return type === 1 ? layout.line1 : type === 2 ? layout.line2 : layout.line3;

  }

  getNumber(size: any) {
    return this.screenSize === 'mobile' ? 2 * Number(size) : Number(size);
  }

  getLineSum(texts) {
    const line1 = texts.line1.text.length > 0 ? 1 : 0;
    const line2 = texts.line2.text.length > 0 ? 1 : 0;
    const line3 = texts.line3.text.length > 0 ? 1 : 0;
    return (line1 + line2 + line3);
  }

  getTextAlign(align, text, event, size) {
    console.log('align', text);
    let left = 0;

    if (align === 'right') {
      if (text.text.length < 30) {
        console.log('Here', 30 - text.text.length);
        left = (30 - text.text.length) * size;
      }
    } else if (align === 'left') {

      left = 0;
    } else if (align === 'center') {

    }


    console.log('Left', left);
    return left;
  }


  getTop(text: IText, lines, height, type) {

    let top = 0;
    if (lines === 1) {

    } else if (lines === 2) {
      if (type === 1) {
        top = 0;
      } else {
        top = height / 2;
      }

    } else if (lines === 3) {

      if (type === 1) {
        top = 0;
      } else if (type === 2) {
        top = height / 3;
      } else if (type === 3) {
        top = 2 * height / 3;
      }

    }


    return top;
  }
}
