import { Component, OnInit } from "@angular/core";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { BuilderService } from "../../services/builder.service";

@Component({
  selector: "app-mobile-message",
  templateUrl: "./mobile-message.component.html",
  styleUrls: ["./mobile-message.component.scss"],
})
export class MobileMessageComponent implements OnInit {
  constructor(public builder: BuilderService, private sheet: MatBottomSheet) {}
  ngOnInit(): void {}
  public addComment() {
    if (this.builder.stickerDetails.message != "") {
      this.sheet.dismiss();
    }
  }
}
