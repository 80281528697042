<div class="card" *ngIf="builder.canvas">
  <div class="card-header card-text">{{ "addText" | language }}</div>
  <div class="card-body">
    <div class="input-group">
      <input
        type="text"
        class="form-control"
        placeholder="{{ 'text' | language }}"
        (keyup)="textStringVale($event)"
        [value]="builder.canvas.textString"
      />
      <div class="input-group-append">
        <span class="input-group-text">
          <button
            id="add-text"
            data-toggle="tooltip"
            data-placement="bottom"
            title="Add text"
            class="btn add-btn"
            (click)="addText()"
          >
            {{ "add" | language }}
          </button>
        </span>
      </div>
    </div>
  </div>
</div>

<!--<div class="card" *ngIf="builder.canvas">-->
<!--  <div class="card-header" *ngIf="builder.canvas.selected">Custom</div>-->
<!--  <div class="card-body">-->
<!--    <div class="custom-item" *ngIf="builder.canvas.selected  && builder.canvas.selected.type == 'group'">Group Selected</div>-->
<!--    <div class="custom-item" *ngIf="!builder.canvas.selected">No items selected</div>-->

<!--    <div class="custom-item" >-->
<!--      <div class="custom-item-title">Font family</div>-->
<!--      <div class="custom-item-body">-->
<!--        <select [(ngModel)]="builder.canvas.props.fontFamily" class="form-control" (change)="setFontFamily()">-->
<!--          <option value="arial">Arial</option>-->
<!--          <option value="helvetica" selected>Helvetica</option>-->
<!--          <option value="verdana">Verdana</option>-->
<!--          <option value="courier">Courier</option>-->
<!--          <option value="Roboto">Roboto</option>-->
<!--          <option value="Open Sans">Open Sans</option>-->
<!--          <option value="Zilla Slab">Zilla Slab</option>-->
<!--          <option value="Lato">Lato</option>-->
<!--          <option value="Bellefair">Bellefair</option>-->
<!--          <option value="Fresca">Fresca</option>-->
<!--          <option value="Raleway">Raleway</option>-->
<!--          <option value="Open Sans Condensed">Open Sans Condensed</option>-->
<!--          <option value="Indie Flower">Indie Flower</option>-->
<!--          <option value="Josefin Sans">Josefin Sans</option>-->
<!--          <option value="Inconsolata">Inconsolata</option>-->
<!--          <option value="Pacifico">Pacifico</option>-->
<!--          <option value="Gloria Hallelujah">Gloria Hallelujah</option>-->
<!--        </select>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="custom-item">-->
<!--      <div class="custom-item-title">Fill</div>-->
<!--      <div class="custom-item-body">-->
<!--        <input type="text" class="form-control" [cpPosition]="'bottom'" [(colorPicker)]="builder.canvas.props.fill"-->
<!--               [value]="builder.canvas.props.fill" (colorPickerChange)="setFill()">-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="custom-item">-->
<!--      <div class="custom-item-title">Font Size</div>-->
<!--      <div class="custom-item-body">-->
<!--        <input type="range" [(ngModel)]="builder.canvas.props.fontSize" (change)="setFontSize()" step="1" min="1"-->
<!--               max="120">{{builder.canvas.props.fontSize}}</div>-->
<!--    </div>-->
<!--    <div class="custom-item">-->
<!--      <div class="custom-item-title">Style</div>-->
<!--      <div class="custom-item-body text-center">-->
<!--        <div class="btn-group" role="group" aria-label="...">-->
<!--          <button type="button" class="btn style-btn btn-sm" [ngClass]="{'active': builder.canvas.props.fontWeight }"-->
<!--                  (click)="setBold()">-->
<!--            <i class="fa fa-bold"></i>-->
<!--          </button>-->
<!--          <button type="button" class="btn style-btn btn-sm" [ngClass]="{'active': builder.canvas.props.fontStyle }"-->
<!--                  (click)="setFontStyle()">-->
<!--            <i class="fa fa-italic"></i>-->
<!--          </button>-->
<!--          <button type="button" class="btn style-btn btn-sm"-->
<!--                  [ngClass]="{'active': hasTextDecoration('underline') }"-->
<!--                  (click)="setTextDecoration('underline')">-->
<!--            <i class="fa fa-underline"></i>-->
<!--          </button>-->
<!--          <button type="button" class="btn style-btn btn-sm"-->
<!--                  [ngClass]="{'active': hasTextDecoration('overline') }"-->
<!--                  (click)="setTextDecoration('overline')">-->
<!--            <i class="fa fa-underline fa-flip-vertical"></i>-->
<!--          </button>-->
<!--          <button type="button" class="btn style-btn btn-sm"-->
<!--                  [ngClass]="{'active': hasTextDecoration('line-through') }"-->
<!--                  (click)="setTextDecoration('line-through')">-->
<!--            <i class="fa fa-strikethrough"></i>-->
<!--          </button>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->

<div class="card">
  <div class="card-header">{{ "customStyle" | language }}</div>
  <div class="card-body">
    <!--    <div class="custom-item">Group Selected</div>-->
    <!--    <div class="custom-item">No items selected</div>-->

    <div class="custom-item">
      <div class="custom-item-title">{{ "fontFamilySec" | language }}</div>
      <div class="custom-item-body">
        <select
          [(ngModel)]="builder.canvas.props.fontFamily"
          class="form-control"
          (change)="setFontFamily($event)"
        >
          <option value="Roboto">Roboto</option>
          <option value="Bebas Neue">Bebas Neue</option>
          <option value="Merriweather">Merriweather</option>
          <option value="Rosarivo">Rosarivo</option>
          <option value="Playfair Display">Playfair Display</option>
          <option value="Abril Fatface">Abril Fatface</option>
          <option value="Alfa Slab One">Alfa Slab One</option>
          <option value="Dancing Script">Dancing Script</option>
          <option value="Gluten">Gluten</option>
          <option value="Permanent Marker">Permanent Marker</option>
          <option value="Luckiest Guy">Luckiest Guy</option>
          <option value="UnifrakturMaguntia">UnifrakturMaguntia</option>
          
        </select>
      </div>
    </div>
    <div class="custom-item">
      <div class="custom-item-title">{{ "fillColor" | language }}</div>
      <div class="custom-item-body">
        <span
          class="set-color"
          [style.background-color]="builder.canvas.props.fill"
        ></span>
        <input
          type="text"
          class="form-control new-input"
          [cpPosition]="'bottom'"
          [(colorPicker)]="builder.canvas.props.fill"
          readonly
          [value]="builder.canvas.props.fill"
          (colorPickerChange)="setFill($event)"
          (click)="addClass()"
          #hidePallet
        />
      </div>
    </div>
    <div class="custom-item" [ngClass]="{ editPallet: isEditPallet }">
      <div class="custom-item-title">{{ "fontSizeSec" | language }}</div>
      <div class="custom-item-body">
        <input
          type="range"
          [(ngModel)]="builder.canvas.props.fontSize"
          (change)="setFontSize($event)"
          step="1"
          min="1"
          max="120"
        />{{ builder.canvas.props.fontSize }}
      </div>
    </div>
    <div class="custom-item">
      <div class="custom-item-title">{{ "style" | language }}</div>
      <div class="custom-item-body text-center">
        <div class="btn-group" role="group" aria-label="...">
          <button
            type="button"
            class="btn style-btn btn-sm"
            [ngClass]="{ active: builder.canvas.props.fontWeight }"
            (click)="setBold()"
          >
            <i class="fa fa-bold"></i>
          </button>
          <button
            type="button"
            class="btn style-btn btn-sm"
            [ngClass]="{
              active: builder.canvas.props.fontStyle ? 'italic' : ''
            }"
            (click)="setFontStyle()"
          >
            <i class="fa fa-italic"></i>
          </button>
          <button
            type="button"
            class="btn style-btn btn-sm"
            [ngClass]="{ active: builder.canvas.props.underline }"
            (click)="setTextDecoration('TextDecorationUnderline', 'underline')"
          >
            <i class="fa fa-underline"></i>
          </button>
          <button
            type="button"
            class="btn style-btn btn-sm"
            [ngClass]="{ active: builder.canvas.props.overline }"
            (click)="setTextDecoration('TextDecorationOverLine', 'overline')"
          >
            <i class="fa fa-underline fa-flip-vertical"></i>
          </button>
          <button
            type="button"
            class="btn style-btn btn-sm"
            [ngClass]="{ active: builder.canvas.props.linethrough }"
            (click)="
              setTextDecoration('TextDecorationLinethrough', 'line-through')
            "
          >
            <i class="fa fa-strikethrough"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
