import {Component, OnInit} from '@angular/core';
import {BuilderService} from '../services/builder.service';

@Component({
  selector: 'app-klarna',
  templateUrl: './klarna.component.html',
  styleUrls: ['./klarna.component.scss']
})
export class KlarnaComponent implements OnInit {

  constructor(private builder: BuilderService) {
  }

  ngOnInit(): void {
    this.checkout();
  }

  checkout() {
    const checkoutContainer = document.getElementById('my-checkout-container');

    checkoutContainer.innerHTML = this.builder.klarnaSnippet;
    const scriptsTags = checkoutContainer.getElementsByTagName('script');
    // This is necessary otherwise the scripts tags are not going to be evaluated
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < scriptsTags.length; i++) {
      const parentNode = scriptsTags[i].parentNode;
      const newScriptTag = document.createElement('script');
      newScriptTag.type = 'text/javascript';
      newScriptTag.text = scriptsTags[i].text;
      parentNode.removeChild(scriptsTags[i]);
      parentNode.appendChild(newScriptTag);
    }
  }


}
