import { Component, OnInit } from '@angular/core';
import {BuilderService} from '../services/builder.service';

@Component({
  selector: 'app-edit-options',
  templateUrl: './edit-options.component.html',
  styleUrls: ['./edit-options.component.scss']
})
export class EditOptionsComponent implements OnInit {

  constructor(public builder: BuilderService) { }

  ngOnInit(): void {
  }

  undo(){
    this.builder.canvas.undo();
  }

}
