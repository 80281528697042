<div *ngIf="material">

  <div class="sticker-heading">{{"stickerMaterial"|language}}</div>


  <div *ngFor="let item of material; index as i" class="sticker-block"  [class]="item.active ? 'sticker-border': ''" (click)="selectMaterial(item.id, material)">
    <div><img class="sticker-img" [src]="BASE_URL + item.attributes.image.data.attributes.url" alt=""></div>
    <div class="sticker-name">{{ (getLanguage === 'en') ? item.attributes.title : item.attributes.name_fi }}</div>
  </div>

</div>
