import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ColorPickerModule} from 'ngx-color-picker';
import {CanvasEditorComponent} from './canvas-editor/canvas-editor.component';
import {AppRoutingModule} from './app-routing.module';
import {FabricjsEditorModule} from '../../projects/angular-editor-fabric-js/src/lib/angular-editor-fabric-js.module';
import {StickerMaterialComponent} from './sticker-material/sticker-material.component';
import {EditOptionsComponent} from './edit-options/edit-options.component';
import {CutOptionsComponent} from './cut-options/cut-options.component';
import {AddTextComponent} from './add-text/add-text.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HeaderComponent} from './shared/header/header.component';
import {FooterComponent} from './shared/footer/footer.component';
import {HomeComponent} from './home/home.component';
import {CanvasBgComponent} from './canvas-bg/canvas-bg.component';
import {AddClipartComponent} from './add-clipart/add-clipart.component';
import {StickerDetailComponent} from './sticker-detail/sticker-detail.component';
import {AddImageComponent} from './add-image/add-image.component';
import {JuniorStickerBuilderComponent} from './junior-sticker-builder/junior-sticker-builder.component';
import {ChildrenStickerBannerComponent} from './children-sticker-banner/children-sticker-banner.component';
import {HowItWorksComponent} from './how-it-works/how-it-works.component';
import {HttpClientModule} from '@angular/common/http';
import {LoginComponent} from './auth/login/login.component';
import {PlaceOrderComponent} from './place-order/place-order.component';
import {MobileOutlineComponent} from './mobile-sheets/mobile-outline/mobile-outline.component';
import {MobileMessageComponent} from './mobile-sheets/mobile-message/mobile-message.component';
import {MobileTextComponent} from './mobile-sheets/mobile-text/mobile-text.component';
import {MobileBgComponent} from './mobile-sheets/mobile-bg/mobile-bg.component';
import {MobileMaterialComponent} from './mobile-sheets/mobile-material/mobile-material.component';
import {MobileClipartComponent} from './mobile-sheets/mobile-clipart/mobile-clipart.component';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatButtonModule} from '@angular/material/button';
import {MatRadioModule} from '@angular/material/radio';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { PricePipe } from './pipes/price.pipe';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import { KlarnaComponent } from './klarna/klarna.component';
import { TermsComponent } from './terms/terms.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { PushComponent } from './push/push.component';
import {ToastrModule} from 'ngx-toastr';
import { LoaderComponent } from './loader/loader.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {ScrollToModule, ScrollToService} from '@nicky-lenaers/ngx-scroll-to';
import { LanguagePipe } from './pipes/language.pipe';
import {MatIconModule} from '@angular/material/icon';
import {MatSliderModule} from '@angular/material/slider';
import { EtusivuComponent } from './etusivu/etusivu.component';

@NgModule({
  declarations: [
    AppComponent,
    CanvasEditorComponent,
    StickerMaterialComponent,
    EditOptionsComponent,
    CutOptionsComponent,
    AddTextComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    CanvasBgComponent,
    AddClipartComponent,
    StickerDetailComponent,
    AddImageComponent,
    JuniorStickerBuilderComponent,
    ChildrenStickerBannerComponent,
    HowItWorksComponent,
    LoginComponent,
    PlaceOrderComponent,
    MobileOutlineComponent,
    MobileMessageComponent,
    MobileTextComponent,
    MobileBgComponent,
    MobileMaterialComponent,
    MobileClipartComponent,
    PricePipe,
    KlarnaComponent,
    TermsComponent,
    CheckoutComponent,
    ConfirmationComponent,
    PushComponent,
    LanguagePipe,
    LoaderComponent,
    EtusivuComponent
  ],
    imports: [
        BrowserModule,
        FabricjsEditorModule,
        FormsModule,
        ColorPickerModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        ReactiveFormsModule,
        MatBottomSheetModule,
        MatButtonModule,
        MatRadioModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatInputModule,
        MatSnackBarModule,
        ToastrModule.forRoot(),
        MatProgressSpinnerModule,
        ScrollToModule,
        MatIconModule,
        MatSliderModule
      // ToastrModule added

    ],
  providers: [ScrollToService],
  bootstrap: [AppComponent]
})
export class AppModule {
}
