import { Component, OnInit, Renderer2 } from "@angular/core";
import { BuilderService } from "../services/builder.service";
import { Name } from "../model/stickerDetail";
import { MatBottomSheet } from "@angular/material/bottom-sheet";

@Component({
  selector: "app-cut-options",
  templateUrl: "./cut-options.component.html",
  styleUrls: ["./cut-options.component.scss"],
})
export class CutOptionsComponent implements OnInit {
  public grownumber = 1;
  constructor(
    public builder: BuilderService,
    private renderer: Renderer2,
    private sheet: MatBottomSheet
  ) {}
  getLanguage;
  // cutStyle = [
  //   {name: 'Square', image: '/assets/img/square.png', selected: false, type: 1},
  //   {name: 'Circle', image: '/assets/img/circle.png', selected: false, type: 2},
  //   {name: 'Cut to outline', image: '/assets/img/dot.png', selected: false, type: 5}
  // ];

  cutStyle: Name[] = [
    {
      image: "assets/img/5.png",
      id: 1,
      name: "Cutting",
      value: "5",
      selected: false,
      name_fi: "Muotoonleikkaus",
    },
    {
      image: "assets/img/1.png",
      id: 2,
      name: "Square",
      value: "1",
      selected: false,
      name_fi: "Suorakulma",
    },
    {
      image: "assets/img/2.png",
      id: 3,
      name: "Round",
      value: "2",
      selected: false,
      name_fi: "Pyöreä",
    },
    // {img: 'assets/img/3.png', id: 4, name: 'Round Corners', value: 'round_corner'},
    // {image: 'assets/img/sheet.png', id: 5, name: 'Sheet', value: 'sheet', selected: false},
  ];

  // {name: 'Rounded corner', image: '/assets/img/round-corner.png', selected: false, type: 3},
  // {name: 'Sheet', image: '/assets/img/sheet.png', selected: false, type: 4},
  ngOnInit(): void {
    this.getLanguage = localStorage.getItem("lang");
  }
  onInputChange(event: any) {
    this.grownumber = event.value
    this.builder.canvas.growSize(event.value);
  }

  // Change cutting of canvas
  changeCanvasStyle(type: string, index) {
    console.log("changeCanvasStyle -> type", type);

    this.builder.canvas.changeCanvasShape(type, index, this.cutStyle);
  }
}
