<!--children sticker banner section starts-->
<div>
  <div class="container-fluid header-section">
    <div class="bg-overlay-hero"></div>
    <div class="row hero_section">
      <div class="col-lg-6 col-md-12 col-sm-12 mobile-order-text">
        <div class="main-heading">
          {{ "stickersFor" | language }}
          <br>
          <span class="main-heading-span">{{ "children" | language }}</span>

        </div>
        <div class="sub-heading" [innerHtml]="'unbeatablePricechild' | language"></div>
        <div class="btn-section">
          <div [ngxScrollTo]="'#destination'">
            <div class="button-builder btn-design">
              <span class="builder-text"> {{ "builder" | language }}</span>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  class="icon-style"
                >
                  <path
                    d="M256 8c137 0 248 111 248 248S393 504 256 504 8 393 8 256 119 8 256 8zM140 300h116v70.9c0 10.7 13 16.1 20.5 8.5l114.3-114.9c4.7-4.7 4.7-12.2 0-16.9l-114.3-115c-7.6-7.6-20.5-2.2-20.5 8.5V212H140c-6.6 0-12 5.4-12 12v64c0 6.6 5.4 12 12 12z"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 col-sm-12 mobile-order-image">
        <div class="home-image">
          <!--          <img src="/assets/home/home.png" alt="home-image" class="img-size">-->
          <div class="images_onHero">
            <img
              src="/assets/home/stickerWizard_CLR_TARRA_Hero_IMG.webp"
              alt="home-image"
              class="img-size"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
<!--children sticker banner section ends-->
