import {Pipe, PipeTransform} from '@angular/core';
import {BuilderService} from '../services/builder.service';
import {of} from 'rxjs';

@Pipe({
  name: 'price',
  pure: false

})
export class PricePipe implements PipeTransform {

  constructor(private builder: BuilderService) {
  }

  transform(value: number, ...args: unknown[]): unknown {
    // console.log('this is value', value);
    // console.log('this is size width', this.builder.size.width);
    const area = (this.builder.size.width * this.builder.size.height / 10000) * value;
    // console.log('this is area', area * value);
    if (area > 0 && area <= 0.5) {

      return Math.round(150 * area);
    } else if (area > 0.5 && area <= 1) {
      return Math.round(140 * area);

    } else if (area > 1 && area <= 2) {
      return Math.round(100 * area);

    } else if (area > 2 && area <= 3) {
      return Math.round(80 * area);

    } else if (area > 3 && area <= 4) {
      return Math.round(70 * area);

    } else if (area > 4 && area <= 5) {
      return Math.round(65 * area);

    } else if (area > 5 && area <= 6) {
      return Math.round(55 * area);

    } else if (area > 6 && area <= 7) {
      return Math.round(50 * area);

    } else if (area > 7) {
      return Math.round(45 * area);

    }

    // switch (area) return
    //   Math.round(value * area);
  }

}
