export class AppConstants {
  // public static BASE_URL = 'http://localhost:1337';
  public static BASE_URL = 'https://admin.tarravelho.fi';

  public static API_URLS = {
    UPLOAD: AppConstants.BASE_URL + '/api/upload',
    CONVERSION: AppConstants.BASE_URL + '/api/orders/conversion',
    GET_MATERIAL: AppConstants.BASE_URL + '/api/materials?populate=*',
    GET_CLIPART: AppConstants.BASE_URL + '/api/cliparts?populate=*',
    GET_CHILDCLIPART: AppConstants.BASE_URL + '/api/child-cliparts?populate=*',
    GET_QUANTITY: AppConstants.BASE_URL + '/api/quantities',
    GET_SIZE: AppConstants.BASE_URL + '/api/sizes',
    REMOVE_BG: AppConstants.BASE_URL + '/api/orders/removeBg',
    ORDER: AppConstants.BASE_URL + '/api/orders/place',
    KLARNA_CONFIRM: AppConstants.BASE_URL + '/api/klarna/confirm'

  };
}
