import {
  ChangeDetectorRef,
  Component,
  OnInit,
  Input,
  Renderer2,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { BuilderService } from "../services/builder.service";

@Component({
  selector: "app-add-text",
  templateUrl: "./add-text.component.html",
  styleUrls: ["./add-text.component.scss"],
})
export class AddTextComponent implements OnInit {
  @Input() selectedDatas: any;
  isTextItalic = false;
  textFontSize: null;
  textColor: null;
  isTextBold = false;
  isOverLine = false;
  isUnderLine = false;
  isLineThrough = false;
  textDecor = "";
  fontFamily = "";
  isEditPallet = false;
  newTextString = "";
  getLanguage;

  @ViewChild("hidePallet") hidePallet: ElementRef;

  constructor(
    public builder: BuilderService,
    private cd: ChangeDetectorRef,
    private renderer: Renderer2
  ) {
    // this.builder.canvas.props.fontFamily = 'helvetica';
    // this.builder.canvas.props.fill = '#000000';

    this.renderer.listen("window", "click", (e: Event) => {
      if (e.target !== this.hidePallet.nativeElement) {
        this.isEditPallet = false;
      }
    });
  }

  ngOnInit(): void {
    this.getLanguage = localStorage.getItem("lang");
    // this.builder.canvas.textString = "";
  }

  textStringVale(event) {
    this.newTextString = event.target.value;
    this.builder.canvas.textString = event.target.value;
  }

  addText() {
    const addText = this.getLanguage === "en" ? "Add Text" : "Lisää teksti";
    const textRequired =
      this.getLanguage === "en" ? "Text Required!" : "Teksti vaaditaan!";
    if (!this.builder.canvas.textString) {
      return this.builder.showError(addText, textRequired);
    }
    this.builder.canvas.addText();
    if (this.isTextItalic) {
      this.builder.canvas.setActiveStyle("fontStyle", "italic", null);
      // var italic = this.isTextItalic;
      this.builder.canvas.props.fontStyle = this.isTextItalic;
      this.isTextItalic = null;
    }
    if (this.textFontSize) {
      this.builder.canvas.setActiveStyle(
        "fontSize",
        parseInt(this.textFontSize, 10),
        null
      );
      // var fontSize = this.textFontSize;
      this.builder.canvas.props.fontSize = this.textFontSize;
      this.textFontSize = null;
    }
    if (this.textColor) {
      this.builder.canvas.setActiveStyle("fill", this.textColor, null);
      // var color = this.textColor;
      this.builder.canvas.props.fill = this.textColor;
      this.textColor = null;
    }
    if (this.fontFamily) {
      this.builder.canvas.setActiveProp("fontFamily", this.fontFamily);
      // var fontFamily = this.fontFamily;
      this.builder.canvas.props.fontFamily = this.fontFamily;
      this.fontFamily = null;
    }
    if (this.isTextBold) {
      this.builder.canvas.setActiveStyle(
        "fontWeight",
        this.isTextBold ? "bold" : "",
        null
      );
      // var textBold = this.isTextBold;
      this.builder.canvas.props.fontWeight = this.isTextBold;
      this.isTextBold = null;
    }
    if (this.isOverLine) {
      this.builder.canvas.setActiveStyle("overline", "overline", null);
      // var overLine = this.isOverLine;
      this.builder.canvas.props.overline = this.isOverLine;
      this.isOverLine = null;
    }
    if (this.isUnderLine) {
      this.builder.canvas.setActiveStyle("underline", "underline", null);
      // var underLine = this.isUnderLine;
      this.builder.canvas.props.underline = this.isUnderLine;
      this.isUnderLine = null;
    }
    if (this.isLineThrough) {
      this.builder.canvas.setActiveStyle("linethrough", "line-through", null);
      // var lineThrough = this.isLineThrough;
      this.builder.canvas.props.linethrough = this.isLineThrough;
      this.isLineThrough = null;
    }
    this.builder.canvas.textString = this.newTextString;
    this.builder.canvas.selectItemAfterAdded("");
    this.builder.showSuccess("Add Text", "Text Added");
  }
  public setId() {
    this.builder.canvas.setId();
  }
  public setOpacity() {
    this.builder.canvas.setOpacity();
  }
  public setFill(value) {
    this.textColor = value;
    this.builder.canvas.setFill();
  }
  public setFontFamily(event) {
    this.fontFamily = event.target.value;
    this.builder.canvas.setFontFamily();
  }
  public setTextAlign(value) {
    this.builder.canvas.setTextAlign(value);
  }
  public setBold() {
    if (this.isTextBold) {
      this.isTextBold = false;
    } else {
      this.isTextBold = true;
    }
    this.builder.canvas.setBold();
  }
  public setFontStyle() {
    this.builder.canvas.setFontStyle();
    this.isTextItalic = true;
  }

  public setTextDecoration(valueType, value) {
    if (value === "overline") {
      if (this.isOverLine) {
        this.isOverLine = false;
      } else {
        this.isOverLine = true;
      }
    }
    if (value === "underline") {
      if (this.isUnderLine) {
        this.isUnderLine = false;
      } else {
        this.isUnderLine = true;
      }
    }
    if (value === "line-through") {
      if (this.isLineThrough) {
        this.isLineThrough = false;
      } else {
        this.isLineThrough = true;
      }
    }
    this.builder.canvas.setTextDecoration(valueType, value);
  }
  public setFontSize(event) {
    this.builder.canvas.setFontSize();
    this.textFontSize = event.target.value;
  }

  public setLineHeight() {
    this.builder.canvas.setLineHeight();
  }

  public setCharSpacing() {
    this.builder.canvas.setCharSpacing();
  }

  public addClass() {
    this.isEditPallet = true;
  }
}
