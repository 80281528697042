import {AfterViewInit, Component, OnInit} from '@angular/core';
import {BuilderService} from '../services/builder.service';

@Component({
  selector: 'app-canvas-bg',
  templateUrl: './canvas-bg.component.html',
  styleUrls: ['./canvas-bg.component.scss']
})
export class CanvasBgComponent implements OnInit, AfterViewInit {
  prefilledColor: string;

  constructor(public builder: BuilderService) { }

  ngOnInit(): void {
    console.log(this.builder.canvas.props)
    this.prefilledColor = this.builder.canvas.props.canvasFill;
  }

  public setCanvasFill() {
    this.builder.canvas.setCanvasFill();
    this.prefilledColor = this.builder.canvas.props.canvasFill
  }

  colorKeyup(event: any){
    if(!event.target.value.includes('#')) {
      this.prefilledColor = '#' + event.target.value
    }
 }

  colorChange(color) {
    this.prefilledColor = color;
  }


  public setCanvasImage() {
    this.builder.canvas.setCanvasImage();
  }

  ngAfterViewInit(): void {
    // document.getElementById('cp').click();
  }
}
