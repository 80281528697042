<div class="edit-option-div">
  <div
    (click)="builder.selected = 1"
    class="edit-options"
    [class]="builder.selected === 1 ? 'selected' : ''"
  >
    <div class="text-center">
      <img class="f-img" src="/assets/edit-option/add-artwork.png" alt="" />
    </div>
    <div class="f-text">
      {{ "artwork" | language }}
    </div>
  </div>
  <div
    (click)="builder.selected = 2"
    class="edit-options"
    [class]="builder.selected === 2 ? 'selected' : ''"
  >
    <div class="text-center">
      <img class="f-img" src="/assets/edit-option/material.png" alt="" />
    </div>
    <div class="f-text">
      {{ "material" | language }}
    </div>
  </div>
  <div
    (click)="builder.selected = 3"
    class="edit-options"
    [class]="builder.selected === 3 ? 'selected' : ''"
  >
    <div class="text-center">
      <img class="f-img" src="/assets/edit-option/cutting.png" alt="" />
    </div>
    <div class="f-text">
      {{ "cutting" | language }}
    </div>
  </div>
  <div
    (click)="builder.selected = 4"
    class="edit-options"
    [class]="builder.selected === 4 ? 'selected' : ''"
  >
    <div class="text-center">
      <img class="f-img" src="/assets/edit-option/add-text.png" alt="" />
    </div>
    <div class="f-text">
      {{ "addtexts" | language }}
    </div>
  </div>
  <div
    (click)="builder.selected = 5"
    class="edit-options"
    [class]="builder.selected === 5 ? 'selected' : ''"
  >
    <div class="text-center">
      <img class="f-img" src="/assets/edit-option/bg-color.png" alt="" />
    </div>
    <div class="f-text">
      {{ "backgroundColor" | language }}
    </div>
  </div>
  <div
    (click)="builder.selected = 6"
    class="edit-options"
    [class]="builder.selected === 6 ? 'selected' : ''"
  >
    <div class="text-center">
      <img class="f-img" src="/assets/edit-option/clipart.png" alt="" />
    </div>
    <div class="f-text">
      {{ "clipart" | language }}
    </div>
  </div>
  <!-- <div (click)="undo()" class="edit-options" [class]="builder.selected === 7 ? 'selected': ''">
    <div class="text-center">
      <img class="f-img" src="/assets/edit-option/undo.png" alt="undo">
    </div>
    <div class="f-text">
      {{"undo"|language}}
    </div>

  </div> -->
  <!--  <div class="edit-options">-->
  <!--    <div class="text-center">-->
  <!--      <img class="f-img" src="/assets/edit-option/picture.png" alt="">-->
  <!--    </div>-->
  <!--    <div class="text-center">-->
  <!--      Add <br> artwork-->
  <!--    </div>-->

  <!--  </div>-->
</div>
