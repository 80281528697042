import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CanvasEditorComponent} from './canvas-editor/canvas-editor.component';
import {HomeComponent} from './home/home.component';
import {EtusivuComponent} from './etusivu/etusivu.component';
import {JuniorStickerBuilderComponent} from './junior-sticker-builder/junior-sticker-builder.component';
import {LoginComponent} from './auth/login/login.component';
import {PlaceOrderComponent} from './place-order/place-order.component';
import {KlarnaComponent} from './klarna/klarna.component';
import {ConfirmationComponent} from './confirmation/confirmation.component';

const routes: Routes = [
  {
    path: 'editor',
    component: CanvasEditorComponent
  },
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'etusivu',
    component: EtusivuComponent
  },
  {
    path: 'child-sticker',
    component: JuniorStickerBuilderComponent
  },
  // {
  //   path: 'login',
  //   component: LoginComponent
  // },
  {
    path: 'signup',
    component: LoginComponent
  },
  {
    path: 'place-order',
    component: PlaceOrderComponent
  }
  ,
  {
    path: 'checkout',
    component: KlarnaComponent
  },
  {
    path: 'klarna/confirm',
    component: ConfirmationComponent
  }


];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
