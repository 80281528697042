<!--<div>-->
<!--  cutting images-->
<!--  <div (click)="changeCanvasStyle(1)" class="cut-options">Square</div>-->
<!--  <div (click)="changeCanvasStyle(2)" class="cut-options">Circle</div>-->
<!--  <div (click)="changeCanvasStyle(3)" class="cut-options">Rounded corners</div>-->
<!--  <div (click)="changeCanvasStyle(4)" class="cut-options">Sheet</div>-->
<!--  <div (click)="changeCanvasStyle(5)" class="cut-options">Cut to outline</div>-->
<!--</div>-->


<div class="mt-5">

  <div class="inc_dec_btn" *ngIf="builder.stickerDetails.name.name === 'Cutting'">
    <mat-slider (change)="onInputChange($event)" color="primary" min="1" max="4" step="1" [value]="grownumber"></mat-slider>
    
</div>
  <div class="heading">{{ "cuttingSec" | language }}</div>
  <div class="main-icons">
    <div
      class="options"
      *ngFor="let item of cutStyle; index as i"
      (click)="changeCanvasStyle(item.value, i)"
      [class]="item.selected ? 'selected' : ''"
    >
      <img [src]="item.image" alt="" class="square" />
      <div class="icon-text">
        {{ getLanguage === "en" ? item.name : item.name_fi }}
      </div>
    </div>
  </div>
</div>
