import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AppConstants} from '../../AppConstants';
import {HttpClient} from '@angular/common/http';
import {Quantity} from '../model/quantity';
import {Materials} from '../model/materials';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) {
  }

  uploadApi(body): Observable<any> {
    return this.http.post(AppConstants.API_URLS.UPLOAD, body);
  }

  pngConversion(body): Observable<any> {
    return this.http.post(AppConstants.API_URLS.CONVERSION, body);
  }

  getMaterial(): Observable<Materials> {
    return this.http.get<Materials>(AppConstants.API_URLS.GET_MATERIAL);
  }

  getClipart(): Observable<any> {
    return this.http.get(AppConstants.API_URLS.GET_CLIPART);
  }
  getChildClipart(): Observable<any> {
    return this.http.get(AppConstants.API_URLS.GET_CHILDCLIPART);
  }

  getSize(): Observable<any> {
    return this.http.get(AppConstants.API_URLS.GET_SIZE);
  }

  getQuantity(): Observable<Quantity> {
    return this.http.get<Quantity>(AppConstants.API_URLS.GET_QUANTITY);
  }

  removeBg(body) {
    return this.http.post(AppConstants.API_URLS.REMOVE_BG, body);
  }

  getOrder() {
    return this.http.get(AppConstants.API_URLS.ORDER);
  }

  postOrder(body) {
    return this.http.post(AppConstants.API_URLS.ORDER, body);
  }

  confirmKlarna(oid) {
    return this.http.get(AppConstants.API_URLS.KLARNA_CONFIRM + '?oid=' + oid);
  }
}
