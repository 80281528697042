<!--<div class="d-flex align-items-center mb-3">-->
<!--  <div class="pl-2 flex-grow-1">Width:  {{ sizeInCm.width | number }}&nbsp; * &nbsp;-->
<!--    Height:  {{  sizeInCm.height | number}}</div>-->
<!--  <div>-->
<!--&lt;!&ndash;    <div>cutting :{{this.builder.stickerDetails.name.name}}</div>&ndash;&gt;-->

<!--    <span>-->
<!--  <button mat-flat-button class="ml-1" (click)="growSize(1)">Increase cutting</button>-->
<!--  <button mat-flat-button  class="ml-1" (click)="growSize(2)">Decrease cutting</button></span>-->
<!--    <button (click)="confirmClear()" mat-flat-button class="clear-button ml-1">Clear canvas</button>-->
<!--  </div>-->
<!--</div>-->

<div id="canvas-cont">
  <canvas id="canvas" #htmlCanvas></canvas>

  <img style="display: none" [src]="src" alt="" />
  <!--<canvas id="1"></canvas>-->
  <!--<canvas id="2"></canvas>-->
</div>
<div class="wrapPositionButton">
  <div
    id="centerHorizontallyBtn"
    style="display: none"
    (click)="centerHorizontally()"
  >
    <svg
      fill="#d8d03d"
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 10L5 7v2H1v2h4v2l3-3zm7 3v-2h4V9h-4V7l-3 3 3 3zm-5 5c.553 0 1-.049 1-.6V2.6c0-.553-.447-.6-1-.6-.552 0-1 .047-1 .6v14.8c0 .551.448.6 1 .6z"
      />
    </svg>
  </div>
  <div
    id="centerVerticallyBtn"
    style="display: none"
    (click)="centerVertically()"
  >
    <svg
      fill="#d8d03d"
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 12l-3 3h2v4h2v-4h2l-3-3zm3-7h-2V1H9v4H7l3 3 3-3zm5 5c0-.553-.048-1-.6-1H2.6c-.552 0-.6.447-.6 1 0 .551.048 1 .6 1h14.8c.552 0 .6-.449.6-1z"
      />
    </svg>
  </div>
  <div id="objectDeleteBtn" style="display: none" (click)="removeSelected()">
    <svg
      height="20px"
      fill="#d8d03d"
      viewBox="0 0 512 512"
      width="20px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z"
      />
    </svg>
  </div>
</div>
<!-- Buttons for handling horizontal and vertical centering -->

<!--<div [class]="builder.stickerDetails?.name?.name === 'Round' ?'fab-canvas-cont-white' : 'fab-canvas-cont-white'" id="canvas-cont">-->
<!--  <canvas [class]="changeOutlineCanvas()" id="canvas" #htmlCanvas></canvas>-->
<!--  <img style="display: none" [src]="src" alt="">-->
<!--  &lt;!&ndash;<canvas id="1"></canvas>&ndash;&gt;-->
<!--  &lt;!&ndash;<canvas id="2"></canvas>&ndash;&gt;-->
<!--</div>-->

<!--<img  width="100%" src="SafeValue must use [property]=binding: blob:http://localhost:4200/b4f135d2-cdb7-4e2c-bf85-8359b7756f96 (see http://g.co/ng/security#xss)" alt="">-->

<!--  <div style="background: grey">-->
<!--    <canvas id="c" #htmlCanvas2></canvas>-->

<!--  </div>-->

<!--<button class="btn btn-danger" (click)="savePdf()">SAVE PDF</button>-->
<!--<button class="btn btn-danger" (click)="saveCutPdf()">SAVE Cut PDF</button>-->
<!--<button class="btn btn-danger" (click)="getPdf()">convert PDF</button>-->
<!--&lt;!&ndash;<canvas id="c" ></canvas>&ndash;&gt;-->
<!--&lt;!&ndash;<div><img [src]="image"></div>&ndash;&gt;-->
<!--&lt;!&ndash;<canvas id="1"></canvas>&ndash;&gt;-->
<!--&lt;!&ndash;<canvas id="2"></canvas>&ndash;&gt;-->
