import {Component, OnInit, HostListener } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {validate} from 'codelyzer/walkerFactory/walkerFn';
import {ApiService} from '../services/api.service';
import {BuilderService} from '../services/builder.service';
import {AppConstants} from '../../AppConstants';
import {Router} from '@angular/router';

@Component({
  selector: 'app-place-order',
  templateUrl: './place-order.component.html',
  styleUrls: ['./place-order.component.scss']
})
export class PlaceOrderComponent implements OnInit {
  userDetail = FormGroup;
  deliveryType = 'standard';
  getLanguage;
  constructor(private fb: FormBuilder, private api: ApiService, public builder: BuilderService, private router: Router) {
    // @ts-ignore
    this.userDetail = this.fb.group({
      name: ['', Validators.required],
      last_name: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', Validators.required],
      address: ['', Validators.required],
      city: ['', Validators.required],
      postcode: ['', Validators.required],
      message: [''],
    });
  }

  ngOnInit(): void {
    this.getLanguage = localStorage.getItem("lang");
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event:any) {
    localStorage.setItem("checkStatus", "true");
  }

  createOrder() {
    // console.log('Creating Order', this.userDetail.valid);
    // @ts-ignore
    const formValues = this.userDetail.value;
    // @ts-ignore
    console.log('This is sticker details', this.builder.stickerDetails);
    const data: any = JSON.parse(localStorage.getItem('stickerDetails'));
    console.log("material data:",data);
    // @ts-ignore
    if (this.userDetail.valid) {
      let materialname = null;
      if(data && data.material)
      {
          if(this.getLanguage === "en"){
            materialname = data.material.attributes?.title;
          }else {
            materialname = data.material.attributes?.name_fi;
          }
      }
      const body = {
        pdf: '',
        previewpdf:'',
        name: formValues.name,
        lastName: formValues.last_name,
        email: formValues.email,
        phone: formValues.phone,
        address: formValues.address,
        postcode: formValues.postcode,
        city: formValues.city,
        message: formValues.message,
        total: this.builder.stickerDetails.stickerNumber.price,
        quantity: this.builder.stickerDetails.stickerNumber.piece,
        height: this.builder.stickerDetails.size.height,
        width: this.builder.stickerDetails.size.width,
        child: this.builder.stickerDetails.name.name === 'nameTag',
        cutting: this.builder.stickerDetails.name.name,
        delivery: this.deliveryType,
        material: materialname,
        background: AppConstants.BASE_URL + this.builder.stickerDetails.material?.background?.data.attributes.url
      };
      console.log('Sticker details', this.builder.stickerDetails);
      console.log('createOrder - Sticker PDF details', this.builder.stickerDetails.pdf.get("files"));
      this.api.uploadApi(this.builder.stickerDetails.pdf).subscribe(pdfData => {
        console.log('This is order pdfData', pdfData);
        body.pdf = pdfData[0].id;
        console.log('This is body', body);
        this.api.uploadApi(this.builder.stickerDetails.pdfpreview).subscribe(pdfData => {
        body.previewpdf = pdfData[0].id;
        this.api.postOrder(body).subscribe((data: any) => {
          console.log('Body', data);
          if (data.status === 1) {
            this.builder.klarnaSnippet = data.klarna.html_snippet;
            this.router.navigateByUrl('/checkout');


          }
        }, error => {
          console.log('Error order', error);
        });

      },error => {
        console.log('Error in uploading pdf');
      });
      },
      error => {
        console.log('Error in uploading pdf');
      });

    } else {
      const setText =
      this.getLanguage === "en"
        ? 'Please enter valid details in form'
        : 'Ole hyvä ja tarkasta lomakkeen kentät';
      //  throw error
      console.log('Error here');
      this.builder.showError('Valid details', setText);
    }
  }

  /**
   * Go to Payment gateway
   */
  startPayment() {

  }
}
