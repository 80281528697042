import { EventEmitter, Injectable } from "@angular/core";
import { FabricjsEditorComponent } from "../../../projects/angular-editor-fabric-js/src/lib/angular-editor-fabric-js.component";
import { StickerDetail } from "../model/stickerDetail";
import { ToastrService } from "ngx-toastr";
import { Output } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class BuilderService {
  @Output() stickerDetailsReset = new EventEmitter<any>();
  @Output() selectedMarerial = new EventEmitter<any>();
  @Output() loader = new EventEmitter<any>();
  isMatName: boolean = false;
  matName;
  canvas: FabricjsEditorComponent;
  selected = 1;
  selectedText = {
    text : "",
    fill : "",
    fontFamily : "",
    fontSize : "",
    fontWeight : "",
    fontStyle : "",
    textDecoration : "",
  };
  material = {
    name: "Hologram stickers",
    image: "assets/edit-option/hologram.png",
    selected: true,
  };
  cutStyle = {
    name: "Square",
    image: "/assets/img/square.png",
    selected: false,
    value: "1",
  };
  stickerDetails: StickerDetail;

  finalOrder = {
    height: 0,
    width: 0,
    heightInCm: 0,
    widthInCm: 0,
    number: 0,
    shape: {},
    material: {},
    notes: "",
    pdf: {},
  };
  fileEvent: any;
  size: any = {
    height: 1,
    width: 1,
  };
  klarnaSnippet: any;
  image: any;
  extension: any;
  loadingCanvas = false;
  cutOutline = false;
  disableBtn = false;

  constructor(private toaster: ToastrService) {}

  changeCanvasShape(type: number) {
    this.canvas.changeShape(type);
  }

  calculatePrice(piece) {
    this.loadingCanvas = true;
    let perPiece = (this.size.width * this.size.height) / 10000;
    let area = perPiece * piece;
    // console.log('calculatePrice - width', this.size.width);
    // console.log('calculatePrice - height', this.size.height);
    // console.log('calculatePrice - perPiece', perPiece);
    // console.log('calculatePrice - piece', piece);
    // console.log('calculatePrice - area', area);

    if (area > 0 && area <= 0.5) {
      const final = Math.round(150 * area);
      const checkWithMinimumPrice = final < 25 ? 25 : final;
      // @ts-ignore
      this.stickerDetails?.stickerNumber?.price = checkWithMinimumPrice;

      return checkWithMinimumPrice;
    } else if (area > 0.5 && area <= 1) {
      // @ts-ignore
      const final = Math.round(140 * area);
      const checkWithMinimumPrice = final < 25 ? 25 : final;
      // @ts-ignore
      this.stickerDetails?.stickerNumber?.price = checkWithMinimumPrice;

      return checkWithMinimumPrice;
    } else if (area > 1 && area <= 2) {
      // @ts-ignore
      const final = Math.round(100 * area);
      const checkWithMinimumPrice = final < 25 ? 25 : final;
      // @ts-ignore
      this.stickerDetails?.stickerNumber?.price = checkWithMinimumPrice;

      return checkWithMinimumPrice;
    } else if (area > 2 && area <= 3) {
      // @ts-ignore 80
      const final = Math.round(80 * area);
      const checkWithMinimumPrice = final < 25 ? 25 : final;
      // @ts-ignore
      this.stickerDetails?.stickerNumber?.price = checkWithMinimumPrice;

      return checkWithMinimumPrice;
      // this.stickerDetails?.stickerNumber?.price = Math.round(80 * area) < 25 ? 25 : Math.round(80 * area);
      // return Math.round(80 * area) < 25 ? 25 : Math.round(80 * area);
    } else if (area > 3 && area <= 4) {
      // @ts-ignore
      const final = Math.round(70 * area);
      const checkWithMinimumPrice = final < 25 ? 25 : final;
      // @ts-ignore
      this.stickerDetails?.stickerNumber?.price = checkWithMinimumPrice;

      return checkWithMinimumPrice;
    } else if (area > 4 && area <= 5) {
      // @ts-ignore
      const final = Math.round(65 * area);
      const checkWithMinimumPrice = final < 25 ? 25 : final;
      // @ts-ignore
      this.stickerDetails?.stickerNumber?.price = checkWithMinimumPrice;

      return checkWithMinimumPrice;
    } else if (area > 5 && area <= 6) {
      // @ts-ignore
      const final = Math.round(55 * area);
      const checkWithMinimumPrice = final < 25 ? 25 : final;
      // @ts-ignore
      this.stickerDetails?.stickerNumber?.price = checkWithMinimumPrice;

      return checkWithMinimumPrice;
    } else if (area > 6 && area <= 7) {
      // @ts-ignore
      const final = Math.round(50 * area);
      const checkWithMinimumPrice = final < 25 ? 25 : final;
      // @ts-ignore
      this.stickerDetails?.stickerNumber?.price = checkWithMinimumPrice;

      return checkWithMinimumPrice;
    } else if (area > 7) {
      // @ts-ignore
      const final = Math.round(45 * area);
      const checkWithMinimumPrice = final < 25 ? 25 : final;
      // @ts-ignore
      this.stickerDetails?.stickerNumber?.price = checkWithMinimumPrice;

      return checkWithMinimumPrice;
    }

    this.loadingCanvas = false;
  }

  showSuccess(title, msg) {
    console.log("Will Show success now");
    this.toaster.success(msg, title);
  }

  showError(title, msg) {
    this.toaster.error(msg, title);
  }
}
