import { Component, OnInit } from "@angular/core";
import { BuilderService } from "../services/builder.service";
import { AppConstants } from "../../AppConstants";
import { ApiService } from "../services/api.service";
import { MatBottomSheet } from "@angular/material/bottom-sheet";

@Component({
  selector: "app-add-image",
  templateUrl: "./add-image.component.html",
  styleUrls: ["./add-image.component.scss"],
})
export class AddImageComponent implements OnInit {
  constructor(
    public builder: BuilderService,
    private api: ApiService,
    private sheet: MatBottomSheet
  ) {}
  getLanguage;
  ngOnInit(): void {}
  ngAfterViewInit() {
    this.getLanguage = localStorage.getItem("lang");
  }
  // Clear url of the file from an input
  public resetFile(event) {
    event.target.value = "";
  }

  /**
   * Read url of file from input event
   * @param event pass input event from HTML
   */

  public readUrl(event) {
    // console.log('this is event', event);
    this.builder.fileEvent = event;
    this.builder.stickerDetails.fileEvent = event;
    let extension = this.checkExtension(event.target.files[0].name);
    console.log("this is extension", extension);
    this.builder.canvas.extension = extension;
    const supported =
      extension === "jpg" ||
      extension === "jpeg" ||
      extension === "png" ||
      extension === "pdf" ||
      extension === "gif" ||
      extension === "svg" ||
      extension === "tiff" ||
      extension === "tif" ||
      extension === "bmp";
    if (!supported) {
      console.log("This file type is not supported!!!!!");
      this.builder.showError("Not Supported", "File type not supported");

      return;
    } else {
      if (event.target.files[0].size > 100000000) {
        const setTextT =
          this.getLanguage === "en"
            ? "Please check the file size."
            : "Ole hyvä ja tarkasta tiedoston koko.";
        const setText =
          this.getLanguage === "en"
            ? "Maximum file size 100Mb:"
            : "Suurin sallittu tiedoston koko 100Mb:";
        this.builder.showError(setTextT, setText);
        return;
      }
      // if ((extension === 'jpg') || (extension === 'png') || (extension === 'svg')) {
      if (supported) {
      } else {
        console.log("Here setting extension pdf");
        extension = "pdf";
        this.builder.canvas.extension = extension;
      }
    }

    // Convert pdf to png if extension is pdf
    if (extension === "pdf") {
      var startTime = performance.now();
      this.convertPdf(event);
      var endTime = performance.now();
      console.log(
        `Call to pdf canvas took ${endTime - startTime} milliseconds`
      );
      return;
    }
    if (extension === "tiff" || extension === "tif") {
      this.builder.loadingCanvas = true;
      this.builder.canvas.readUrl(event);
      setTimeout(() => {
        this.convertTif(event);
      }, 500);
      return;
    }
    if (extension === "svg") {
      this.builder.canvas.addSvg(this.builder.canvas.url, event);
      delete this.builder.fileEvent;
    } else {
      this.builder.canvas.readUrl(event);
      setTimeout(() => {
        this.addImageOnCanvas(this.builder.canvas.url);
        // this.builder.canvas.removeBg();
        delete this.builder.fileEvent;
        console.log("this is also bas64", this.builder.canvas.url);
        // localStorage.setItem("imageData", JSON.stringify(this.builder.canvas.url));
      }, 100);
    }
  }

  /**
   * Convert Tif file to PNG format
   * @param event Input event
   */
  convertTif(event) {
    const fd = new FormData(); // To carry on your data
    if (event.target.files && event.target.files[0]) {
      const files = event.target.files[0];
      fd.append("files", files);
    }
    var startTime = performance.now();

    // Upload pdf file to get its Link
    this.api.uploadApi(fd).subscribe(
      (data) => {
        const body = {
          link: AppConstants.BASE_URL + data[0].url,
        };
        var endTime = performance.now();
        console.log(
          `Call to convertTif-uploadApi took ${
            endTime - startTime
          } milliseconds`
        );
        // Get PNG file from uploaded PDF
        this.api.pngConversion(body).subscribe(
          (pdfData: any) => {
            console.log("this is converted pdf data", pdfData);
            // const base64 = 'data:image/png;base64,' + pdfData.image64;
            const fileName = pdfData.image.split("/").pop().split(".png")[0]; // returns 'two'
            console.log("File name", fileName);
            // const file = this.builder.canvas.dataURLtoFile(pdfData.b64, fileName);
            // console.log('this is the file', file);
            this.builder.canvas.supportedUrl = pdfData.image;
            this.builder.loadingCanvas = false;

            this.builder.canvas.addImageOnCanvas(pdfData.b64);
            var endTime = performance.now();
            console.log(
              `Call to convertTif-pngConversion took ${
                endTime - startTime
              } milliseconds`
            );
            delete this.builder.fileEvent;
          },
          (error) => {
            delete this.builder.fileEvent;
            console.log("this is error pdf", error);
          }
        );
      },
      (error) => {
        delete this.builder.fileEvent;
        console.log("An error occurred", error);
      }
    );
    var endTime = performance.now();
    console.log(`Call to convertTif took ${endTime - startTime} milliseconds`);
  }

  public addImageOnCanvas(url) {
    // console.log('Adding image on canvas', url);
    this.builder.image = url;
    // this.builder.image.get
    this.builder.canvas.addImageOnCanvas(url);
    this.sheet.dismiss();
  }

  /**
   * Function to return extension of file
   * @param name file name
   */
  checkExtension(name) {
    return name.replace(/^.*\./, "");
  }

  /**
   * Convert PDF file to PNG format
   * @param event Input event
   */
  convertPdf(event) {
    this.builder.canvas.readUrl(event);

    const fd = new FormData(); // To carry on your data
    if (event.target.files && event.target.files[0]) {
      const files = event.target.files[0];
      fd.append("files", files);
    }
    var startTime = performance.now();

    // Upload pdf file to get its Link
    this.api.uploadApi(fd).subscribe(
      (data) => {
        this.builder.loadingCanvas = true;
        const body = {
          link: AppConstants.BASE_URL + data[0].url,
        };
        var endTime = performance.now();
        console.log(
          `Call to convertPdf-uploadApi took ${
            endTime - startTime
          } milliseconds`
        );
        // Get PNG file from uploaded PDF
        this.api.pngConversion(body).subscribe(
          (pdfData: any) => {
            console.log("this is converted pdf data", pdfData);
            // const base64 = 'data:image/png;base64,' + pdfData.image64;
            const fileName = pdfData.image.split("/").pop().split(".png")[0]; // returns 'two'
            console.log("File name", fileName);
            // const file = this.builder.canvas.dataURLtoFile(pdfData.b64, fileName);
            // console.log('this is the file', file);
            this.builder.canvas.supportedUrl = pdfData.image;

            this.builder.canvas.addImageOnCanvas(pdfData.b64);
            var endTime = performance.now();
            console.log(
              `Call to doSomething took ${endTime - startTime} milliseconds`
            );
            this.builder.loadingCanvas = false;
            delete this.builder.fileEvent;
          },
          (error) => {
            this.builder.loadingCanvas = false;
            delete this.builder.fileEvent;
            console.log("this is error pdf", error);
          }
        );
      },
      (error) => {
        this.builder.loadingCanvas = false;
        delete this.builder.fileEvent;
        console.log("An error occurred", error);
      }
    );
    var endTime = performance.now();
    console.log(`Call to convertPdf took ${endTime - startTime} milliseconds`);
  }
}
