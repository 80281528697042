import {ChangeDetectorRef, Component, Input, OnInit, SimpleChanges} from '@angular/core';
import {BuilderService} from '../services/builder.service';
import {AppConstants} from '../../AppConstants';
import {ApiService} from '../services/api.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';


@Component({
  selector: 'app-add-clipart',
  templateUrl: './add-clipart.component.html',
  styleUrls: ['./add-clipart.component.scss']
})
export class AddClipartComponent implements OnInit {

  @Input() clipArt : any;

  BASE_URL = AppConstants.BASE_URL;
  //public childClipArt = [];
  public tabsClipArt:any;
  constructor(
    private builder: BuilderService, 
    private api: ApiService,
    private cd: ChangeDetectorRef,
    private sheet: MatBottomSheet,
    ) {
  }

  ngOnInit(): void {
    console.log('this is clipart', this.clipArt);
    this.cd.detectChanges();
    if (!this.clipArt) {
      this.getClipArt();
    } else {
      this.builder.loader.emit(false);
    }

  }

  public getImgPolaroid(event) {
    this.builder.canvas.getImgPolaroid(event,"", "", "", "", "");
    this.sheet.dismiss();
  }

  getClipArt() {
    this.api.getClipart().subscribe(clipArtData => {
      clipArtData.data.forEach(material => {
        material.selected = false;
      });
      this.clipArt = clipArtData.data;
      this.cd.detectChanges();
      this.builder.loader.emit(false);
      console.log('these are materials', clipArtData);
    }, error => {
      this.builder.loader.emit(false);
      console.log('ERROR material', error);
    });
  }
  objectKey(obj) {
    return Object.keys(obj);
  }
  formatedTabs() {
      return this.clipArt.reduce((prev, now) => {
        
        if (!prev[now.attributes.tabgroup]) {
          prev[now.attributes.tabgroup] = [];
        }
        prev[now.attributes.tabgroup].push(now);
        return prev;
      }, {});

  }
}
