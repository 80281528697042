<!--include header component starts -->
<!-- <app-header></app-header> -->
<!--include header component starts -->
<!--header section starts -->
<div *ngIf="!loading">
  <div class="container-fluid header-section">
    <div class="bg-overlay-hero"></div>
    <div class="row hero_section">
      <div class="col-lg-6 col-md-12 col-sm-12 mobile-order-text">
        <div class="main-heading">
          HUOLLAMME
          <br />
          <span class="main-heading-span">SIVUSTOA</span>
        </div>
        <div class="sub-heading">AVAAMME KAUPAN TAAS <br> PIAN UUDELLEEN.</div>
        <!--<div class="btn-section">
          <div routerLink="/child-sticker">
            <div class="button-children btn-design">
              <span class="nametagBuilder-text">
                {{ "nametagBuilder" | language }}</span
              >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  class="icon-style"
                >
                  <path
                    d="M256 8c137 0 248 111 248 248S393 504 256 504 8 393 8 256 119 8 256 8zM140 300h116v70.9c0 10.7 13 16.1 20.5 8.5l114.3-114.9c4.7-4.7 4.7-12.2 0-16.9l-114.3-115c-7.6-7.6-20.5-2.2-20.5 8.5V212H140c-6.6 0-12 5.4-12 12v64c0 6.6 5.4 12 12 12z"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>-->
      </div>
      <div class="col-lg-6 col-md-12 col-sm-12 mobile-order-image">
        <div class="home-image">
          <!--          <img src="/assets/home/home.png" alt="home-image" class="img-size">-->
          <div class="images_onHero">
            <img
              src="/assets/home/stickerWizard_CLR_TARRA_Hero_IMG.webp"
              alt="home-image"
              class="img-size"
            />
          </div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
  <!--  header section ends-->

  <!--setting section stars here-->

  <!-- <div class="bg-grey pt-5 pb-style" id="destination">
    <div class="fs-18 custom-container">
      <div class="row"> -->
        <!--      Sticker by shape-->
        <!-- <div class="col-lg-3 col-md-12 main-card">
          <div class="details-card">
            <div class="fs-20 card-title">
              {{ "stickerByShape" | language }}
            </div>
            <div class="grid" *ngFor="let item of stickers">
              <div>
                <div class="d-flex justify-content-lg-start">
                  <div>
                    <img class="logo" [src]="[item.img]" alt="" />
                  </div>
                  <div class="align-self-center">
                    {{
                      this.translation.getLang() === "en"
                        ? item.name
                        : item.name_fi
                    }}
                  </div>
                </div>
              </div>

              <div class="d-flex align-items-center flex-row-reverse">
                <div>
                  <mat-radio-group [(ngModel)]="sticker">
                    <mat-radio-button
                      [checked]="sticker"
                      (change)="selectSticker(1)"
                      [value]="item"
                    ></mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <!--    Sticker by material-->
        <!--<div class="col-lg-3 col-md-12 main-card">
          <div class="details-card desktop_only">
            <div>
              <div class="fs-20 card-title">
                {{ "selectMaterial" | language }}
              </div>

              <div class="row">
                <ng-container *ngFor="let material of materials; index as i">
                  <div class="col-lg-4 col-md-4 col-sm-6 p-0 set-material-ipad">
                    <div
                      class="card-material"
                      style="align-items: center"
                      (click)="selectMaterial(material.id, material)"
                      [ngClass]="
                        activeChip
                          ? 'cate-card'
                          : material.active
                          ? 'cate-card'
                          : 'cate-card2'
                      "
                    >
                      <img
                        class="img-stickers"
                        [src]="
                          BASE_URL +
                          material.attributes.image.data.attributes.url
                        "
                        alt=""
                      />-->
                      <!--                    <div>{{material.attributes.title}}</div>-->
                      <!--<div class="word-break">
                        {{
                          this.translation.getLang() === "en"
                            ? material.attributes.title
                            : material.attributes.name_fi
                        }}
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="details-card mobile_only">
            <div>
              <div class="fs-20 card-title">
                {{ "selectMaterial" | language }}
              </div>

              <div
                class="grid mobile_grid_custom"
                *ngFor="let material of materials; index as i"
              >
                <div class="col-lg-4 col-md-4 col-sm-6 p-0 set-material-ipad">
                  <div class="d-flex justify-content-lg-start">
                    <img
                      class="img-stickers"
                      [src]="
                        BASE_URL + material.attributes.image.data.attributes.url
                      "
                      alt=""
                    />
                    <div class="word-break align-self-center">
                      {{
                        this.translation.getLang() === "en"
                          ? material.attributes.title
                          : material.attributes.name_fi
                      }}
                    </div>
                  </div>
                </div>
                <div class="d-flex align-items-center flex-row-reverse">
                  <div class="align-self-center">
                    <mat-radio-group [(ngModel)]="selectedMaterialId">
                      <mat-radio-button
                        class="example-radio-button"
                        [value]="material.id"
                        (change)="selectMaterial(material.id, material)"
                      >
                      </mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <!--    Select Size-->
        <!--<div class="col-lg-3 col-md-12 main-card">
          <div class="details-card">
            <div class="card-title">{{ "selectSize" | language }} (L•K)</div>

            <div *ngFor="let item of size; i as index">
              <div class="ml-responsive">
                <div class="d-flex justify-content-between">
                  <div>
                    {{
                      item.attributes.width + " * " + item.attributes.height
                    }}cm
                  </div>-->
                  <!--            <div class="align-self-center">{{item.name}}</div>-->
                  <!--<div>
                    <mat-radio-group [(ngModel)]="sizeValue">
                      <mat-radio-button
                        [value]="item"
                        (change)="selectSticker(2)"
                      ></mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>
              </div>

              <div></div>
            </div>

            <div>
              <mat-checkbox
                [checked]="custom.showCustomSize"
                (change)="showCustom('size', $event)"
                >{{ "customSize" | language }}
              </mat-checkbox>
            </div>

            <div *ngIf="this.custom.showCustomSize">
              <div class="d-flex align-items-center text-center">
                <div>
                  <input
                    step="0.1"
                    (keyup)="setCustomSize($event, 'w')"
                    (mouseout)="setCustomSize($event, 'w', 'change')"
                    [(ngModel)]="custom.customSize.width"
                    class="w-90"
                    type="number"
                  />
                </div>
                <div>*</div>
                <div>
                  <input
                    step="0.1"
                    (keyup)="setCustomSize($event, 'h')"
                    (mouseout)="setCustomSize($event, 'h', 'change')"
                    [(ngModel)]="custom.customSize.height"
                    class="w-90"
                    type="number"
                  />
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <!--      Numbers-->
        <!--<div class="col-lg-3 col-md-12 main-card">
          <div class="details-card">
            <div class="fs-20 card-title">{{ "number" | language }}</div>

            <div class="grid" *ngFor="let number of numbers">
              <div class="ml-responsive">
                <div class="d-flex justify-content-between">
                  <div>
                    {{ number.attributes.piece }} {{ "pcs" | language }}
                  </div>

                  <div class="align-self-center">
                    € {{ number.attributes.price }}
                  </div>
                </div>
              </div>

              <div>
                <div class="d-flex flex-row-reverse">
                  <mat-radio-group [(ngModel)]="stickerNumbers">
                    <mat-radio-button
                      (change)="
                        selectSticker(
                          3,
                          number.attributes.piece,
                          number.attributes.price
                        )
                      "
                      [value]="number"
                    ></mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>
            </div>

            <div>
              <mat-checkbox
                [checked]="custom.showCustomNumber"
                (change)="showCustom('customNumber', $event)"
                >{{ "customNumbers" | language }}
              </mat-checkbox>
            </div>

            <div *ngIf="custom.showCustomNumber">
              <div class="d-flex justify-content-between">
                <div class="w-50">
                  <input
                    (input)="setCustomNumber($event)"
                    (keyup)="setCustomNumber($event)"
                    [(ngModel)]="custom.customNumber"
                    class="w-100"
                    type="number"
                  />
                </div>
                <div *ngIf="custom.customPricing">
                  € {{ custom.customPricing }}
                </div>
              </div>
            </div>
          </div>

          <div>
            <div>
              <button
                *ngIf="sticker.id === 1 ? uploaded : true"
                class="btn btn-builder"
                (click)="submitDetails()"
              >
                {{ "continueWithBuilder" | language }}
              </button>
            </div>

            <div
              class="btn btn-builder text-center"
              *ngIf="!uploaded && sticker.id === 1"
            >
              <label for="myFile" class="btn choose-btn text-center d-block">{{
                "uploadFile" | language
              }}</label>
              <input
                (change)="readUrl($event)"
                accept=".jpg,.jpeg,.png,.tiff,.tif,.svg,.pdf"
                type="file"
                id="myFile"
                style="visibility: hidden"
                name="filename"
              />
            </div>
            <div class="customPdfText mt-2 fs-14">PDF, PNG, JPG, SVG, TIF</div>
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <!--setting section ends here-->

  <!--how it works component-->
  <!-- <app-how-it-works></app-how-it-works> -->
  <!--how it works component-->

  <!--above footer section starts-->
  <!--<div class="container-fluid footer-section">
    <div class="row p-5" style="margin-right: 0; margin-left: 0">
      <div class="col-lg-6 col-md-6 col-sm-12">
        <div class="footer-img">
          <img
            src="/assets/home/Banner-artwork.png"
            alt="footer-image"
            class="f-img-size"
          />
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12">
        <div class="lets-work-text">
          {{ "workItOut" | language }}
        </div>
        <div class="custom-txt">
          {{ "customRequirement" | language }}
        </div>
        <div class="quote-text">
          {{ "customQuote" | language }}
        </div> -->
        <!--        <div class="appointment-s">-->
        <!--          <a href="javascript: void(0)" class="button-appointment">Book an appointment</a>-->
        <!--        </div>-->
      <!--</div>
    </div>

    <div style="display: none">
      <angular-editor-fabric-js #canvas></angular-editor-fabric-js>
    </div>
  </div> -->
  <!--above footer section starts-->
<!--</div>

<app-loader
  *ngIf="loading"
  style="height: 100vh; text-align: center"
></app-loader> -->
<!--include footer component-->
<!-- <app-footer></app-footer> -->
<!--include footer component-->
