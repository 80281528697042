<div class="pt-5 pb-5 footer">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-xs-12 about-company">
        <h2 class="footer-heading">{{"aboutUs"|language}}</h2>
        <p class="pr-7 about-des" [innerHtml]="'footerAboutUs'|language">
         

        </p>
        
      </div>
      <div class="col-lg-4 col-xs-12 links">
        <h2 class="mt-lg-0 mt-sm-3 footer-heading">{{"quickLinks"|language}}</h2>
        <ul class="m-0 p-0">
          <li><a href="https://stickerwizard.com/en/contact/">
            <span class="list-icon"><i aria-hidden="true" class="fa fa-caret-right"></i></span><span class="list-text">{{"contact"|language}}</span> </a></li>
          <li><a href="https://stickerwizard.com/en/privacy-policy/">
           <span class="list-icon"><i aria-hidden="true" class="fa fa-caret-right"></i></span><span class="list-text">{{"privacy"|language}}</span></a></li>
          <li><a href="https://stickerwizard.com/en/cookie-policies/"> <span class="list-icon"><i aria-hidden="true" class="fa fa-caret-right"></i></span><span class="list-text">{{"cookie"|language}}</span></a></li>
          <li><a href="https://stickerwizard.com/kayttoehdot/">
           <span class="list-icon"><i aria-hidden="true" class="fa fa-caret-right"></i></span><span class="list-text">{{"terms"|language}}</span></a></li>

        </ul>
      </div>
      <div class="col-lg-4 col-xs-12 location">
        <h2 class="mt-lg-0 mt-sm-4 footer-heading mob-heading">{{"contact"|language}}</h2>

       
        <p class="contact-text"><a href="mailto:info@stickerwizard.com"><i class="fa fa-paper-plane mr-3"></i>info@stickerwizard.com</a>
        </p>
        <p class="left-side-icon">
          <a href="https://www.instagram.com/stickerwizardcom/"><i class="fa fa-instagram  icon-style" aria-hidden="true"></i></a>

          <a href="#"><i class="fa fa-facebook-f  icon-style"></i></a>
          <a href="https://www.tiktok.com/@stickerwizardcom"><svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z"/></svg></a>
          
        </p>

      </div>
    </div>

  </div>
</div>
<footer>
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-xs-12 copyright">
        <h3 class="elementor-heading-title elementor-size-default">🧙 © All rights reserved  🪄</h3>
      </div>
      <div class="col-lg-6 col-xs-12"></div>
    </div>
  </div>
</footer>
