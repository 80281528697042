import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
  Output,
  EventEmitter,
} from "@angular/core";
import { fabric } from "fabric";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { DomSanitizer } from "@angular/platform-browser";
import JSPDF from "jspdf";
import * as getImageOutline from "image-outline";
import { ApiService } from "../../../../src/app/services/api.service";
import * as simplify from "line-simplify-rdp";
import { BuilderService } from "../../../../src/app/services/builder.service";
import { AppConstants } from "../../../../src/AppConstants";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { UtilService } from "../../../../src/app/util.service";
import { changeDpiDataUrl } from "changedpi";
import { StickerDetailComponent } from "../../../../src/app/sticker-detail/sticker-detail.component";
import { IEvent } from "fabric/fabric-impl";
import {
  ExtendedFabricRect,
  ExtendedFabricEllipse,
  ExtendedFabricImage,
  ExtendedFabricText,
  ExtendedFabricGroup,
  ExtendedFabricObject,
  ExtendedFabricTextbox,
} from "../utils/fabric";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
@Component({
  // tslint:disable-next-line:component-selector
  selector: "angular-editor-fabric-js",
  templateUrl: "./angular-editor-fabric-js.component.html",
  styleUrls: ["./angular-editor-fabric-js.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class FabricjsEditorComponent implements AfterViewInit {
  @ViewChild("htmlCanvas") htmlCanvas: ElementRef;
  @ViewChild("htmlCanvas2") htmlCanvas2: ElementRef;
  getLanguage;
  @Output() textDataCreated = new EventEmitter<any>();
  clip: any = [];
  canvasArr: any = [];
  image: any;
  public props = {
    canvasFill: "#ffffff",
    canvasImage: "",
    id: null,
    opacity: null,
    fill: "#000000",
    fontSize: null,
    lineHeight: null,
    charSpacing: null,
    fontWeight: null,
    fontStyle: null,
    textAlign: null,
    fontFamily: "helvetica",
    overline: false,
    underline: false,
    linethrough: false,
    TextDecoration: "",
  };
  public textString: string = "";
  public url: any = "";
  public size: any = {
    width: 1000,
    height: 500,
  };
  public largestImageObjectToDiecut: any = null;
  public sizeInCm: any = {
    width: 1000,
    height: 500,
  };
  public canvasResizeChange: any = {
    width: 1000,
    height: 500,
  };
  public roundedSize: any = {
    // ((num + Number.EPSILON) * 100) / 100
    width: Math.round((this.sizeInCm.width + Number.EPSILON) * 100) / 100,
    height: Math.round((this.sizeInCm.height + Number.EPSILON) * 100) / 100,
  };
  public outlineSizeInCm: any = {
    width: 1000,
    height: 500,
  };
  src;
  public json: any;
  public textEditor = false;
  public figureEditor = false;
  public selected: any;
  public imageSrc = "";
  containerWidth: number;
  containerHeight: number;
  // To track old event width and height
  oldEventWidth: number;
  oldEventHeight: number;
  cropCutting = {
    top: 0,
    height: 0,
    width: 0,
    left: 0,
  };
  cutOutline = false;
  cutOutlineRatio = {
    height: 0,
    width: 0,
  };
  extension: any;
  public grow: any = 30;
  supportedUrl = "";
  private canvas: fabric.Canvas;
  private globalEditor = false;
  private imageEditor = false;
  private files: any;
  private mFile: File;
  // public canvas2;
  private events;
  private cm = 37.7952755906;
  setHeightWidth: boolean = false;

  constructor(
    private renderer: Renderer2,
    public http: HttpClient,
    private sanitizer: DomSanitizer,
    public api: ApiService,
    public builder: BuilderService,
    private matSnack: MatSnackBar,
    private router: Router,
    private sheet: MatBottomSheet,
    private util: UtilService // private stickerDetailComponent: StickerDetailComponent
  ) {}

  ngAfterViewInit(): void {
    console.log("This is extension in canvas editor", this.extension);
    this.getLanguage = localStorage.getItem("lang");
    // setup front side canvas
    this.canvas = new fabric.Canvas(this.htmlCanvas.nativeElement, {
      hoverCursor: "pointer",
      selection: false, // Disable group selection
      selectionBorderColor: "blue",
      imageSmoothingEnabled: true,
      preserveObjectStacking: true,
      // allowTaint: true,
      // foreignObjectRendering: true
    });
    if (this.builder.stickerDetails?.name?.name === "Cutting") {
      console.log("Removing home>>>>>>>>>>>>>>>>>>>>");
      // this.cutOutline = true;

      // this.removeBgApi();
      setTimeout(() => {
        this.renderer.removeClass(
          document.getElementById("canvas-cont"),
          "fab-canvas-cont-white"
        );
        this.renderer.removeClass(document.getElementById("canvas"), "circle");

        this.renderer.removeClass(
          document.getElementsByClassName("upper-canvas"),
          "circle"
        );
        this.renderer.addClass(
          document.getElementById("canvas-cont"),
          "fab-canvas-cont-grey"
        );
      }, 300);
    }

    this.canvas.on("selection:created", (e: any) => {
      const textTypeByCreated = this.canvas.getActiveObject();
      // Event handler for object selection
      this.showCenterButtons(); // Show centering buttons when an object is selected
      this.positionButtons();
      if (textTypeByCreated.type === "i-text") {
        this.builder.selected = 4;
        this.textDataCreated.emit(e);
      } else if (textTypeByCreated.type === "group") {
        this.builder.selected = 6;
        this.textDataCreated.emit(e);
      } else if (textTypeByCreated.type === "image") {
        this.builder.selected = 1;
        this.textDataCreated.emit(e);
        if (this.canvas.getActiveObject().type === "image") {
          this.builder.canvas.props.fontFamily = "helvetica";
          this.builder.canvas.props.fill = "#000000";
          this.builder.canvas.textString = "";
        }
      } else {
        this.textDataCreated.emit(e);
      }
    });

    this.canvas.on("selection:updated", (e: any) => {
      const textTypeByUpdated = this.canvas.getActiveObject();
      if (textTypeByUpdated.type === "i-text") {
        this.builder.selected = 4;
        this.textDataCreated.emit(e);
      } else if (textTypeByUpdated.type === "group") {
        this.builder.selected = 6;
        this.textDataCreated.emit(e);
      } else if (textTypeByUpdated.type === "image") {
        this.builder.selected = 1;
        this.textDataCreated.emit(e);
      } else {
        this.textDataCreated.emit(e);
      }
    });

    this.canvas.on("selection:cleared", () => {
      this.hideCenterButtons(); // Hide centering buttons when selection is cleared
      this.textString = "";
      this.builder.canvas.props.fontFamily = "helvetica";
      this.builder.canvas.props.fill = "#000000";
      this.builder.canvas.props.fontSize = null;
      this.builder.canvas.props.fontWeight = "";
      this.builder.canvas.props.fontStyle = "";
      this.builder.canvas.props.overline = false;
      this.builder.canvas.props.underline = false;
      this.builder.canvas.props.linethrough = false;
    });

    this.canvas.on(
      "object:scaling",
      (
        event: IEvent & {
          target: {
            fontSize: number;
            scaleX: number;
            scaleY: number;
            text: string;
          };
        }
      ) => {
        if (event.target) {
          this.props.fontSize = parseInt(
            (event.target.fontSize * event.target.scaleX).toFixed(0)
          );
        }
        const selectedActiveObj = this.canvas.getActiveObject();
        this.callToSetPositionScale(selectedActiveObj);
      }
    );
    this.canvas.on(
      "object:modified",
      (
        event: IEvent & {
          target: {
            fontSize: number;
            scaleX: number;
            scaleY: number;
            text: string;
            _clearCache: () => void;
          };
        }
      ) => {
        this.positionButtons(); // Re-position buttons after modifying the object
        if (event.target) {
          const textTypeByUpdated = this.canvas.getActiveObject();
          if (textTypeByUpdated.type === "i-text") {
            event.target.fontSize *= event.target.scaleX;
            event.target.fontSize = parseInt(event.target.fontSize.toFixed());
            event.target.scaleX = 1;
            event.target.scaleY = 1;
            event.target._clearCache();
          }
          this.callToSetPositionScale(textTypeByUpdated);
        }
      }
    );

    // console.log('>>>>>>>>>>>>>>', this.builder.stickerDetails.name.name);
    if (this.builder.stickerDetails?.name?.name === "Round") {
      console.log("Addding class now");
      const element_WH = document.getElementById("canvasSizeSet");
      this.canvas.setDimensions({
        width: element_WH.offsetWidth - 30,
        height: element_WH.offsetHeight,
      });
      // let container = document.getElementById("canvas-container");
      // this.canvas.setWidth(container.clientWidth);
      // this.canvas.setHeight(container.clientHeight);
      this.canvas.calcOffset();
      this.canvas.renderAll();

      // setTimeout(() => {
      //   // this.builder.canvas.props.canvasFill = '#ffffff';
      //   // this.builder.canvas.setCanvasFill();
      //   // this.renderer.addClass(document.getElementById('canvas'), 'circle');
      //   this.renderer.addClass(
      //     document.getElementsByClassName("upper-canvas")[0],
      //     "circle"
      //   );
      //   this.renderer.addClass(
      //     document.getElementsByClassName("lower-canvas")[0],
      //     "circle"
      //   );
      //   this.renderer.addClass(
      //     document.getElementsByClassName("upper-canvas")[0],
      //     "bordered-canvas"
      //   );
      //   this.renderer.addClass(
      //     document.getElementsByClassName("lower-canvas")[0],
      //     "bordered-canvas"
      //   );
      //   this.renderer.removeClass(
      //     document.getElementById("canvas-cont"),
      //     "fab-canvas-cont-grey"
      //   );
      //   this.renderer.addClass(
      //     document.getElementById("canvas-cont"),
      //     "fab-canvas-cont-white"
      //   );
      //   this.renderer.addClass(
      //     document.getElementById("canvas-cont"),
      //     "circle"
      //   );
      //   console.log("Setting color to white ***************");
      //   this.setImageOnChangeSHape();
      // }, 300);
      let shadow = new fabric.Shadow({
        color: "rgba(0, 0, 0, 0.09)",
        blur: 10,
        offsetX: 0,
        offsetY: 0,
      });
      const workspace = new ExtendedFabricEllipse({
        fill: "rgba(255,255,255,1)",
        originX: "center",
        originY: "center",
        shadow,
        absolutePositioned: true,
      });

      workspace.id = "workspace";
      workspace.set("selectable", false);
      workspace.set("hasControls", false);
      workspace.hoverCursor = "default";
      // this.canvas.clipPath = workspace;
      this.canvas.add(workspace);
      this.canvas.centerObject(workspace);
      this.canvas.renderAll();
    }
    if (this.builder.stickerDetails?.name?.name === "Square") {
      const element_WH = document.getElementById("canvasSizeSet");
      this.canvas.setDimensions({
        width: element_WH.offsetWidth - 30,
        height: element_WH.offsetHeight,
      });
      // let container = document.getElementById("canvas-container");
      // this.canvas.setWidth(container.clientWidth);
      // this.canvas.setHeight(container.clientHeight);
      this.canvas.calcOffset();
      this.canvas.renderAll();

      console.log(
        "element width and height - ",
        element_WH.offsetWidth,
        element_WH.offsetHeight
      );
      console.log("this.canvas.getWidth() - ", this.canvas.getWidth());
      console.log("this.canvas.getHeight() - ", this.canvas.getHeight());
      // Extend the workspace object to include an id
      let shadow = new fabric.Shadow({
        color: "rgba(0, 0, 0, 0.09)",
        blur: 10,
        offsetX: 0,
        offsetY: 0,
      });
      const workspace = new ExtendedFabricRect({
        fill: "rgba(255,255,255,1)",
        strokeWidth: 0,
        shadow,
        absolutePositioned: true,
      });

      // Now you can set an id
      workspace.id = "workspace";
      workspace.set("selectable", false);
      workspace.set("hasControls", false);
      workspace.hoverCursor = "default";
      this.canvas.add(workspace);
      this.canvas.renderAll();

      console.log(
        "Addding class now for square",
        document.getElementsByClassName("upper-canvas")[0]
      );
      // this.builder.canvas.props.canvasFill = '#ffffff';
      // this.builder.canvas.setCanvasFill();
      // this.renderer.addClass(document.getElementById('canvas'), 'circle');
      setTimeout(() => {
        this.renderer.addClass(
          document.getElementsByClassName("upper-canvas")[0],
          "bordered-canvas"
        );
        this.renderer.addClass(
          document.getElementsByClassName("lower-canvas")[0],
          "bordered-canvas"
        );
        this.renderer.addClass(
          document.getElementById("canvas-cont"),
          "fab-canvas-cont-white"
        );
        this.renderer.removeClass(
          document.getElementsByClassName("upper-canvas")[0],
          "circle"
        );
        this.renderer.removeClass(
          document.getElementsByClassName("lower-canvas")[0],
          "circle"
        );
        this.renderer.removeClass(
          document.getElementById("canvas-cont"),
          "fab-canvas-cont-grey"
        );

        this.renderer.removeClass(
          document.getElementById("canvas-cont"),
          "circle"
        );
        console.log(
          "Setting color to white ***************",
          document.getElementsByClassName("upper-canvas")[0]
        );
        this.setImageOnChangeSHape();
      });
    }

    // // temporary testing canvas
    // this.canvas2 = new fabric.Canvas(this.htmlCanvas2.nativeElement, {
    //   hoverCursor: 'pointer',k
    //   selection: true,
    //   selectionBorderColor: 'blue'
    // });

    // this.canvas.on({
    //   'object:moving': (e) => {
    //   },
    //   'object:modified': (e) => {
    //   },
    //   'object:selected': (e) => {
    //
    //     const selectedObject = e.target;
    //     this.selected = selectedObject;
    //     selectedObject.hasRotatingPoint = true;
    //     selectedObject.transparentCorners = false;
    //     selectedObject.cornerColor = 'rgba(255, 87, 34, 0.7)';
    //
    //     this.resetPanels();
    //
    //     if (selectedObject.type !== 'group' && selectedObject) {
    //
    //       this.getId();
    //       this.getOpacity();
    //
    //       switch (selectedObject.type) {
    //         case 'rect':
    //         case 'circle':
    //         case 'triangle':
    //           this.figureEditor = true;
    //           this.getFill();
    //           break;
    //         case 'i-text':
    //           this.textEditor = true;
    //           this.getLineHeight();
    //           this.getCharSpacing();
    //           this.getBold();
    //           this.getFill();
    //           this.getTextDecoration();
    //           this.getTextAlign();
    //           this.getFontFamily();
    //           break;
    //         case 'image':
    //           break;
    //       }
    //     }
    //   },
    //   'selection:cleared': (e) => {
    //     this.selected = null;
    //     this.resetPanels();
    //   }
    // });

    // this.canvas.setWidth(this.size.width);
    // this.canvas.setHeight(this.size.height);

    // When we select an object and move it outside the canvas, then the object outside will appear lighter than the one inner.
    this.canvas.on("object:rotating", (e) => {
      const selectedObj = this.canvas.getActiveObject();
      this.callToSetPositionScale(selectedObj);
    });
    this.canvas.on("object:moving", (e) => {
      var obj = e.target as ExtendedFabricImage;
      this.callToSetPositionScale(obj);
    });
  }

  // This function set position and scale of active and active's duplicate image by ID
  callToSetPositionScale(e, todo = "") {
    let objectID;
    if (e.id.includes("_dup")) {
      objectID = e.id.replace("_dup", "");
    } else {
      objectID = e.id + "_dup";
    }
    // The object that's being moved is e.target
    // let dup_move = e.id + "_dup";
    // Find duplicate objects by id to move image with original active image
    let selectedDup = this.canvas
      .getObjects()
      .find((item: any) => item.id === objectID);

    if (selectedDup) {
      if (todo === "remove") {
        this.canvas.remove(selectedDup);
      } else {
        selectedDup.set({
          left: e.left,
          top: e.top,
          scaleX: e.scaleX,
          scaleY: e.scaleY,
          angle: e.angle,
          flipX: e.flipX,
          flipY: e.flipY,
        });
      }

      this.canvas.renderAll();
    }
  }
  // remove transparent part of canvas

  // trimCanvas(): any {
  //   // MIT http://rem.mit-license.org
  //     const c = document.getElementsByTagName('canvas')[0];
  //     let trimHeight: number;
  //     let trimWidth: number;
  //     let trimmed: ImageData;
  //     let ctx: any;
  //     let copy: CanvasRenderingContext2D;
  //     let pixels: ImageData;
  //     let l: number;
  //     let i;
  //     let bound: { top: null; left: null; bottom: null; right: null };
  //     let x;
  //     let y;
  //     ctx = c.getContext('2d');
  //     copy = document.createElement('canvas').getContext('2d');
  //     pixels = ctx.getImageData(0, 0, c.width, c.height);
  //     l = pixels.data.length;
  //     bound = {
  //       top: null,
  //       left: null,
  //       right: null,
  //       bottom: null
  //     };
  //
  //     // Iterate over every pixel to find the highest
  //     // and where it ends on every axis ()
  //     for (i = 0; i < l; i += 4) {
  //       if (pixels.data[i + 3] !== 0) {
  //         x = (i / 4) % c.width;
  //         // tslint:disable-next-line:no-bitwise
  //         y = ~~((i / 4) / c.width);
  //
  //         if (bound.top === null) {
  //           bound.top = y;
  //         }
  //
  //         if (bound.left === null) {
  //           bound.left = x;
  //         } else if (x < bound.left) {
  //           bound.left = x;
  //         }
  //
  //         if (bound.right === null) {
  //           bound.right = x;
  //         } else if (bound.right < x) {
  //           bound.right = x;
  //         }
  //
  //         if (bound.bottom === null) {
  //           bound.bottom = y;
  //         } else if (bound.bottom < y) {
  //           bound.bottom = y;
  //         }
  //       }
  //     }
  //
  //     // Calculate the height and width of the content
  //     trimHeight = bound.bottom - bound.top;
  //     trimWidth = bound.right - bound.left;
  //     trimmed = ctx.getImageData(bound.left, bound.top, trimWidth, trimHeight);
  //
  //     copy.canvas.width = trimWidth;
  //     copy.canvas.height = trimHeight;
  //     copy.putImageData(trimmed, 0, 0);
  //
  //     // Return trimmed canvas
  //     return copy.canvas;
  //
  // }

  // set image on changing shape

  setImageOnChangeSHape() {
    console.log("1999999999999999999999999999");
    let extension = this.builder.extension;
    console.log("This is extension", extension);
    // this.canvas.backgroundColor = '#ffffff';
    // this.canvas.renderAll();
    const supported =
      extension === "jpg" ||
      extension === "png" ||
      extension === "pdf" ||
      extension === "gif" ||
      extension === "svg" ||
      extension === "tif" ||
      extension === "bmp";
    if (!supported) {
      console.log("This file type is not supported!!!!!");
      return;
    } else {
      if (extension === "jpg" || extension === "png") {
      } else {
        console.log("Here setting extension pdf");
        extension = "pdf";
        this.builder.canvas.extension = extension;
      }
    }
    // Convert pdf to png if extension is pdf
    if (extension === "pdf") {
      this.convertPdf(this.builder.fileEvent);
    } else {
      this.readUrl(this.builder.fileEvent);
      setTimeout(() => {
        console.log("Adding Image from here 224");
        this.addImageOnCanvas(this.url);
        // this.builder.canvas.removeBg();

        console.log("this is also bas64", this.url);
      }, 100);
    }
  }

  convertPdf(event) {
    this.readUrl(event);

    const fd = new FormData(); // To carry on your data
    if (event.target.files && event.target.files[0]) {
      const files = event.target.files[0];
      fd.append("files", files);
      console.log("Files log", files);
    }
    var startTime = performance.now();
    // Upload pdf file to get its Link
    this.api.uploadApi(fd).subscribe(
      (data) => {
        const body = {
          link: AppConstants.BASE_URL + data[0].url,
        };
        var endTime = performance.now();
        console.log(
          `Call to convertPdf-uploadApi took ${
            endTime - startTime
          } milliseconds`
        );
        // Get PNG file from uploaded PDF
        this.api.pngConversion(body).subscribe(
          (pdfData: any) => {
            console.log("this is converted pdf data", pdfData);
            // const base64 = 'data:image/png;base64,' + pdfData.image64;
            const fileName = pdfData.image.split("/").pop().split(".png")[0]; // returns 'two'
            console.log("File name", fileName);
            // const file = this.builder.canvas.dataURLtoFile(pdfData.b64, fileName);
            // console.log('this is the file', file);
            this.supportedUrl = pdfData.image;
            var endTime = performance.now();
            console.log(
              `Call to convertPdf-pngConversion took ${
                endTime - startTime
              } milliseconds`
            );
            this.addImageOnCanvas(pdfData.b64);
            var endTime = performance.now();
            console.log(
              `Call to convertPdf-end took ${endTime - startTime} milliseconds`
            );
          },
          (error) => {
            console.log("this is error pdf", error);
          }
        );
      },
      (error) => {
        console.log("An error occurred", error);
      }
    );
  }

  /*------ Remove background cutting options ----------*/

  /**
   * Upload image to get base64 code
   */

  // async removeBgApi() {
  //   console.log('Before promise');
  //
  //   console.log('This is URL from removebgapi', this.url);
  //   const transparent = await this.removeWhitePart(this.url);
  //   console.log('after promise', transparent);
  //
  //
  //   const fd = new FormData();     // To carry on your data
  //   if (this.builder.fileEvent.target.files && this.builder.fileEvent.target.files[0]) {
  //     const files = this.builder.fileEvent.target.files[0];
  //     console.log('Files', files);
  //     fd.append('files', files);
  //   }
  //
  //   console.log('extension', this.builder.extension);
  //   console.log('transparent', transparent);
  //   if (this.builder.extension === 'png' && transparent) {
  //     this.drawStickerBg(this.url);
  //
  //   } else if (this.builder.extension === 'png' && !transparent) {
  //
  //
  //   } else {
  //     this.api.uploadApi(fd).subscribe(data => {
  //       const body = {
  //         link: AppConstants.BASE_URL + data[0].url
  //       };
  //       // Get PNG file from uploaded PDF
  //       this.api.uploadPdf(body).subscribe((pdfData: any) => {
  //         console.log('this is converted pdf data', pdfData);
  //         // const base64 = 'data:image/png;base64,' + pdfData.image64;
  //         const fileName = pdfData.image.split('/').pop().split('.png')[0]; // returns 'two'
  //         console.log('File name', fileName);
  //         // const file = this.builder.canvas.dataURLtoFile(pdfData.b64, fileName);
  //         // console.log('this is the file', file);
  //         this.supportedUrl = pdfData.image;
  //         const pdfBody = {
  //           link: this.supportedUrl
  //         };
  //
  //         this.api.removeBg(pdfBody).subscribe((bgImage: any) => {
  //           // console.log('this is bg Image >>>>>>>>>>>>>', bgImage);
  //           // console.log('this is converted pdf data', pdfData);
  //           if (bgImage.result_b64) {
  //             const base64 = 'data:image/png;base64,' + bgImage.result_b64;
  //
  //             // console.log('this is base 64 >>>>>>>>>', base64);
  //             this.src = base64;
  //             this.drawStickerBg(base64);
  //
  //             // const fileName = pdfData.image.split('/').pop().split('.png')[0]; // returns 'two'
  //             // console.log('File name', fileName);
  //             // const file = this.builder.canvas.dataURLtoFile(base64, fileName);
  //             // console.log('this is the file', file);
  //             // this.builder.canvas.addImageOnCanvas(base64);
  //             this.builder.loadingCanvas = false;
  //
  //           } else {
  //             this.builder.showError('Error', 'Unable2 to load this format');
  //             this.builder.loadingCanvas = false;
  //             this.router.navigateByUrl('/');
  //           }
  //
  //         }, error => {
  //           console.log('this is error pdf', error);
  //           this.builder.loadingCanvas = false;
  //
  //         });
  //
  //
  //         // this.addImageOnCanvas(pdfData.b64);
  //         // this.src = pdfData.b64;
  //         // console.log('This is log from editor cto!', new Date());
  //         // setTimeout(() => console.log('Log cto editor later on!', new Date()), 10);
  //         // this.drawStickerBg(pdfData.b64);
  //         // this.builder.loadingCanvas = false;
  //       }, error => {
  //         console.log('this is error pdf', error);
  //         this.builder.loadingCanvas = false;
  //
  //       });
  //     }, error => {
  //       console.log('An error occurred', error);
  //       this.builder.loadingCanvas = false;
  //
  //     });
  //   }
  // }

  // Original removeBG API

  async removeBgApi() {
    console.log("Here i am ****************", this.extension);
    this.builder.loadingCanvas = true;
    // PNG condition commented because remove.bg does not support high dimension images so we need to resize image
    if (this.builder.extension !== "png") {
      const fd = new FormData(); // To carry on your data
      if (
        this.builder.fileEvent.target.files &&
        this.builder.fileEvent.target.files[0]
      ) {
        const file = this.builder.fileEvent.target.files[0];
        if (
          this.builder.extension == "pdf" ||
          this.builder.extension == "tif"
        ) {
          fd.append("files", file);
          this.callDiecutBackendAPIs(fd);
        } else {
          // this code is for resize high dimension images
          // Create a new image object
          const img = document.createElement("img");
          var startTime = performance.now();
          // When the image has loaded, resize it
          img.onload = () => {
            // Create a canvas and get its context
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            const ratio = img.width / img.height;
            console.log("call img.width img.height - ", img.width, img.height);

            let newHeight = img.height;
            let newWidth = img.width;

            if (img.height > 1000) {
              newHeight = 1000;
              newWidth = newHeight * ratio;
            } else if (img.width > 1000) {
              newWidth = 1000;
              newHeight = newWidth / ratio;
            }
            // Set the canvas dimensions to the desired size
            canvas.width = newWidth;
            canvas.height = newHeight;
            console.log(
              "call canvas.width canvas.height - ",
              canvas.width,
              canvas.height
            );
            // Draw the image onto the canvas
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

            // Convert the canvas back to a Blob
            canvas.toBlob((blob) => {
              // Create a new File object with the same name as the original file
              const files = new File([blob], file.name, { type: file.type });
              console.log("call files", files);

              // Now you can use this newFile object
              // Append the file to the FormData instance
              fd.append("files", files);

              var endTime = performance.now();
              console.log(
                `Call to removeBgApi-png resize took ${
                  endTime - startTime
                } milliseconds`
              );
              this.builder.loadingCanvas = true;
              // Upload pdf file to get its Link
              this.callDiecutBackendAPIs(fd);
            }, file.type);
          };

          // Set the image source to the object URL of the file
          img.src = URL.createObjectURL(file);
          console.log("log img !png - ", img);
        }
      }
    } else {
      this.builder.loadingCanvas = true;
      var startTime = performance.now();
      const fd = new FormData(); // To carry on your data
      if (
        this.builder.fileEvent.target.files &&
        this.builder.fileEvent.target.files[0]
      ) {
        const file = this.builder.fileEvent.target.files[0];
        // this code is for resize high dimension images
        // Create a new image object
        const img = document.createElement("img");

        // When the image has loaded, resize it
        img.onload = () => {
          // Create a canvas and get its context
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          const ratio = img.width / img.height;
          let newHeight = img.height;
          let newWidth = img.width;

          if (img.height > 1000) {
            newHeight = 1000;
            newWidth = newHeight * ratio;
          } else if (img.width > 1000) {
            newWidth = 1000;
            newHeight = newWidth / ratio;
          }
          // Set the canvas dimensions to the desired size
          canvas.width = newWidth;
          canvas.height = newHeight;
          // Draw the image onto the canvas
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

          // Convert the canvas back to a Blob
          canvas.toBlob((blob) => {
            // Create a new File object with the same name as the original file
            const files = new File([blob], file.name, { type: file.type });

            // Now you can use this newFile object
            // Append the file to the FormData instance
            fd.append("files", files);

            var endTime = performance.now();
            console.log(
              `Call to removeBgApi-png resize took ${
                endTime - startTime
              } milliseconds`
            );
            this.builder.loadingCanvas = true;
            // Upload pdf file to get its Link
            this.api.uploadApi(fd).subscribe(
              (data) => {
                this.builder.loadingCanvas = true;
                const body = {
                  link: AppConstants.BASE_URL + data[0].url,
                };
                var endTime = performance.now();
                console.log(
                  `Call to removeBgApi-uploadApi took ${
                    endTime - startTime
                  } milliseconds`
                );
                // Get PNG file from uploaded PDF
                this.api.removeBg(body).subscribe(
                  (bgImage: any) => {
                    if (bgImage.result_b64) {
                      const base64 =
                        this.builder.extension === "svg"
                          ? "data:image/svg+xml;base64," + bgImage.result_b64
                          : "data:image/png;base64," + bgImage.result_b64;
                      this.src = base64;
                      console.log(
                        "Calling sticker BG",
                        this.extension,
                        this.builder.extension
                      );
                      this.drawStickerBg(base64);
                      var endTime = performance.now();
                      console.log(
                        `Call to removeBgApi-removeBg took ${
                          endTime - startTime
                        } milliseconds`
                      );
                      this.builder.loadingCanvas = false;
                      delete this.builder.fileEvent;
                    } else {
                      this.builder.loadingCanvas = false;
                      this.builder.showError(
                        "Error",
                        "Unable to load this image"
                      );
                      delete this.builder.fileEvent;
                      this.router.navigateByUrl("/");
                    }
                  },
                  (error) => {
                    console.log("this is error pdf", error);
                    this.builder.loadingCanvas = false;
                    delete this.builder.fileEvent;
                  }
                );
              },
              (error) => {
                console.log("An error occurred", error);
                this.builder.loadingCanvas = false;
                delete this.builder.fileEvent;
              }
            );
          }, file.type);
        };

        // Set the image source to the object URL of the file
        img.src = URL.createObjectURL(file);
        console.log("log img png - ", img);
      }
    }
    this.builder.loadingCanvas = false;
  }

  callDiecutBackendAPIs(fd) {
    var startTime = performance.now();
    this.api.uploadApi(fd).subscribe(
      (data) => {
        this.builder.loadingCanvas = true;
        const body = {
          link: AppConstants.BASE_URL + data[0].url,
        };
        var endTime = performance.now();
        console.log(
          `Call to removeBgApi-uploadApi took ${
            endTime - startTime
          } milliseconds`
        );
        // Get PNG file from uploaded PDF
        this.api.pngConversion(body).subscribe(
          (pdfData: any) => {
            const fileName = pdfData.image.split("/").pop().split(".png")[0]; // returns 'two'
            // if (this.builder.extension !== "pdf") {
            this.supportedUrl = pdfData.image;
            const pdfBody = {
              link: this.supportedUrl,
            };
            var endTime = performance.now();
            console.log(
              `Call to removeBgApi-pngConversion took ${
                endTime - startTime
              } milliseconds`
            );
            this.api.removeBg(pdfBody).subscribe(
              (bgImage: any) => {
                if (bgImage.result_b64) {
                  const base64 = "data:image/png;base64," + bgImage.result_b64;
                  this.src = base64;
                  this.drawStickerBg(base64);
                  var endTime = performance.now();
                  console.log(
                    `Call to removeBgApi-done took ${
                      endTime - startTime
                    } milliseconds`
                  );
                  this.builder.loadingCanvas = false;
                  delete this.builder.fileEvent;
                } else {
                  this.builder.showError("Error", "Unable to load this format");
                  this.builder.loadingCanvas = false;
                  delete this.builder.fileEvent;
                  this.router.navigateByUrl("/");
                }
              },
              (error) => {
                console.log("this is error pdf", error);
                delete this.builder.fileEvent;
                this.builder.canvas.clear();
                this.builder.loadingCanvas = false;
              }
            );
            // } else {
            //   this.builder.canvas.supportedUrl = pdfData.image;
            //   setTimeout(() => {
            //     this.builder.canvas.addImageOnCanvas(pdfData.b64);
            //     this.drawStickerBg(pdfData.b64);
            //     delete this.builder.fileEvent;
            //   }, 100);
            // }
          },
          (error) => {
            console.log("this is error pdf", error);
            this.builder.loadingCanvas = false;
            delete this.builder.fileEvent;
            this.builder.canvas.clear();
          }
        );
      },
      (error) => {
        console.log("An error occurred", error);
        this.builder.loadingCanvas = false;
        delete this.builder.fileEvent;
        this.builder.canvas.clear();
      }
    );
  }

  /**
   * Sticker style cutting
   * @param url base64 code
   * @param grow variable to define size of sticker
   */
  drawStickerBg(url, grow = 30) {
    this.builder.loadingCanvas = true;
    const img = new Image();
    img.src = url;
    console.log("In STICKER BG", new Date().getTime(), url);
    img.onload = () => {
      console.log("In onload", new Date());
      console.log("this is image Height >>>>>>>>>", img.height, img.width, img);

      const ratio = img.width / img.height;
      if (img.height < 300) {
        img.height = 350;
        img.width = img.height * ratio;
        this.setHeightWidth = true;
      } else if (img.width < 300) {
        img.width = 350;
        img.height = img.width / ratio;
        this.setHeightWidth = true;
      }

      let stickerCanvas = null;
      stickerCanvas = this.stickerEffect(img, grow);
      console.log("Done sticker in onload", new Date().getMilliseconds());
      const imgInstanceDuplicat = new ExtendedFabricImage(img, {});
      const imgInstance = new ExtendedFabricImage(stickerCanvas, {});
      // @ts-ignore
      // imgInstance.resizeFilter = new fabric.Image.filters.Resize({resizeType: 'hermite'});

      // this.canvas.clear();
      this.canvas.backgroundColor = "transparent";
      setTimeout(
        () => console.log("Instance timeout at", new Date().getTime()),
        10
      );

      imgInstance.set({
        angle: 0,
        hasRotatingPoint: true,
        originY: "center",
        originX: "center",
        lockMovementX: true,
        lockMovementY: true,
        objectCaching: false,
        absolutePositioned: true,
        id: "workspace",
      });

      imgInstance.setControlsVisibility({
        mt: false,
        mb: false,
        ml: false,
        mr: false,
        bl: false,
        br: false,
        tl: false,
        tr: false,
        mtr: false,
      });

      this.canvas.centerObject(imgInstance);

      // this.canvas.add(imgInstance);
      this.canvas.insertAt(imgInstance, 0, false);

      console.log("Instance created at", new Date().getTime());
      setTimeout(
        () => console.log("Instance timeout at", new Date().getTime()),
        10
      );

      img.height = img.height + grow * 3;
      this.setHeightWidth = true;

      img.width = img.width + grow * 3;
      this.setHeightWidth = true;

      const imageRatio = img.width / img.height;
      const canvasRatio = this.canvas.getWidth() / this.canvas.getHeight();

      if (imageRatio <= canvasRatio) {
        if (img.height > this.canvas.getHeight()) {
          console.log("Image height - ", img.height);
          imgInstance.scaleToHeight(this.canvas.getHeight());
        }
      } else {
        if (img.width > this.canvas.getWidth()) {
          console.log("Image width - ", img.width);
          imgInstance.scaleToWidth(this.canvas.getWidth());
        }
      }

      // if (this.canvas.getHeight() > this.canvas.getWidth()) {
      //   console.log('Changing width >>>>>>>>>>>>>>>');
      //   imgInstance.scaleToWidth(this.canvas.getWidth());
      //   if (img.width > this.canvas.getWidth()) {
      //     console.log('****** setting img acc to canvas scaled to width');
      //     // imgInstance.scaleToHeight(this.canvas.getHeight());
      //     imgInstance.scaleToWidth(this.canvas.getWidth());
      //   }
      //   if (img.height > this.canvas.getHeight()) {
      //     console.log('****** setting img acc to canvas scaled to height');
      //     // imgInstance.scaleToWidth(this.canvas.getWidth());
      //     imgInstance.scaleToHeight(this.canvas.getHeight());
      //
      //
      //   }
      //
      // }
      // else {
      //   console.log('Changing height >>>>>>>>>>>>>>>', imgInstance);
      //
      //   imgInstance.scaleToHeight(this.canvas.getHeight());
      //   if (img.height > this.canvas.getHeight()) {
      //     console.log('****** setting img acc to canvas scaled to height', imgInstance.getScaledHeight());
      //     // imgInstance.scaleToWidth(this.canvas.getWidth());
      //     imgInstance.scaleToHeight(this.canvas.getHeight());
      //
      //   }
      //
      //   if (img.width > this.canvas.getWidth()) {
      //     console.log('****** setting img acc to canvas scaled to width', imgInstance.getScaledWidth());
      //     // imgInstance.scaleToHeight(this.canvas.getHeight());
      //     imgInstance.scaleToWidth(this.canvas.getWidth());
      //
      //
      //   }
      //
      //   // if (imgInstance.getScaledWidth() > imgInstance.getScaledHeight()) {
      //   //   console.log('Setting here 1');
      //   //   imgInstance.scaleToWidth(this.canvas.getWidth());
      //   // } else {
      //   //   console.log('Setting here 1');
      //   //
      //   //   imgInstance.scaleToWidth(this.canvas.getHeight());
      //   //
      //   // }
      //
      //
      // }
      // this.canvas.getHeight() > this.canvas.getWidth() ? imgInstance.scaleToWidth(this.canvas.getWidth())
      //   : imgInstance.scaleToHeight(this.canvas.getHeight());
      this.cutOutline = true;
      this.builder.cutOutline = true;

      let scaledHeight = imgInstance.getScaledHeight();
      let scaledWidth = imgInstance.getScaledWidth();
      if (this.setHeightWidth) {
        if (scaledHeight < 300) {
          scaledHeight = 350;
        }
        if (scaledWidth < 300) {
          scaledWidth = 350;
        }
      }
      console.log("scaledWidth - ", scaledWidth);
      console.log("scaledHeight - ", scaledHeight);
      this.cutOutlineRatio.width = scaledWidth / this.cm;
      this.cutOutlineRatio.height = scaledHeight / this.cm;
      // ratio is uploaded image width / image height
      // Uploaded image is landscape/portrait via shape cutting
      const outlineType =
        this.cutOutlineRatio.width > this.cutOutlineRatio.height
          ? "landscape"
          : "portrait";
      console.log("image type - ", outlineType);
      // if image is landscape, then width is fixed and height is calculated
      // else height is fixed and width is calculated according to ratio of image uploaded
      if (outlineType === "landscape") {
        this.sizeInCm.height =
          Math.round((this.sizeInCm.width / ratio + Number.EPSILON) * 100) /
          100;
        this.builder.stickerDetails.size.height = this.sizeInCm.height;
      } else {
        this.sizeInCm.width =
          Math.round((this.sizeInCm.height * ratio + Number.EPSILON) * 100) /
          100;
        this.builder.stickerDetails.size.width = this.sizeInCm.width;
      }
      console.log("all objects", this.canvas.getObjects());
      // this.removeTransparent();
      console.log("Reached here");
      // Use this image when we change the shape of canvas diecut to square or round
      this.largestImageObjectToDiecut =
        fabric.util.object.clone(imgInstanceDuplicat);
      this.largestImageObjectToDiecut.set({
        id: "workspace_sw",
      });
      // This will set clipPath for needed objects so duplicate image will be shown outside the canvas
      this.setClipPathForObjects(imgInstance);
      this.canvas.renderAll();
      // this.removeWhitePart(img);
      this.builder.disableBtn = false;
      // console.log('This is trimming', this.trimCanvas());
      this.builder.loadingCanvas = false;
    };
    this.builder.loadingCanvas = false;
  }

  /**
   * Helper function returns sticker style canvas
   * @param img uploaded image
   * @param grow variable to define size of sticker
   */
  stickerEffect(img, grow) {
    // console.log('ticker effect', img);
    // console.log('this is image height', img.width);
    // console.log('this is image height', img.height);
    let canvas1: any = document.createElement("canvas");
    const ctx1 = canvas1.getContext("2d");
    let canvas2: any = document.createElement("canvas");
    const ctx2 = canvas2.getContext("2d");
    canvas1.width = canvas2.width = img.width + grow * 3;
    canvas1.height = canvas2.height = img.height + grow * 3;
    ctx1.drawImage(
      img,
      canvas1.width / 2 - img.width / 2,
      canvas1.height / 2 - img.height / 2,
      img.width,
      img.height
    );
    console.log("Effect creation has started!");
    ctx2.shadowColor = "white";
    ctx2.shadowBlur = 2;
    for (let i = 0; i < grow; i++) {
      if (i === grow - 2) {
        ctx2.shadowColor = "#1d1d1d";
        ctx2.shadowBlur = 2;
      }
      ctx2.drawImage(canvas1, 0, 0);
      ctx1.drawImage(canvas2, 0, 0);
    }
    console.log("Effect creation must be done by now!!");
    ctx2.shadowColor = "rgba(0,0,0,0)";
    // ctx2.drawImage(img, grow * 1.5, grow * 1.5);
    ctx2.drawImage(
      img,
      canvas2.width / 2 - img.width / 2,
      canvas2.height / 2 - img.height / 2,
      img.width,
      img.height
    );
    console.log("this is canvas 2", canvas2);
    return canvas2;
  }

  /*------------------------Block elements------------------------*/

  // Block "Size"

  changingCutting() {
    this.renderer.addClass(document.getElementById("canvas"), "circle");

    this.renderer.addClass(
      document.getElementsByClassName("upper-canvas")[0],
      "circle"
    );
    this.renderer.addClass(
      document.getElementById("canvas-cont"),
      "fab-canvas-cont-grey"
    );
    this.clear();
    this.setImageOnChangeSHape();
  }

  changeSize() {
    console.log("new debug" + this.builder.stickerDetails);
    if (this.builder.stickerDetails?.name.name === "Square") {
      const workspace = this.canvas
        .getObjects()
        .find((item: any) => item.id === "workspace");
      console.log("--id workspace", workspace);

      workspace.width = this.size.width - 10;
      workspace.height = this.size.height - 10;
      console.log("workspace.width - ", workspace.width);
      console.log("workspace.height - ", workspace.height);
    } else if (this.builder.stickerDetails?.name.name === "Round") {
      const workspace = this.canvas
        .getObjects()
        .find((item: any) => item.id === "workspace");
      if (workspace instanceof fabric.Ellipse) {
        workspace.width = this.size.width - 10;
        workspace.height = this.size.height - 10;
        workspace.rx = (this.size.width - 10) / 2;
        workspace.ry = (this.size.height - 10) / 2;
      }
    } else {
      this.canvas.setWidth(this.size.width);
      this.canvas.setHeight(this.size.height);
    }

    this.sizeInCm.width =
      Math.round((this.size.width / 37.7952755906 + Number.EPSILON) * 100) /
      100;
    this.sizeInCm.height =
      Math.round((this.size.height / 37.7952755906 + Number.EPSILON) * 100) /
      100;
    // this.canvas.clipPath.scaleToHeight(this.size.height);
    // this.canvas.clipPath.scaleToWidth(this.size.width);
    // this.canvas.add(this.canvas.clipPath);
  }

  resetSize(w, h) {
    this.size.width = w * 37.7952755906;
    this.size.height = h * 37.7952755906;
    // this.canvasResizeFromInput(this.size);
  }

  changeSizeInCm(
    type,
    fromevent = "change",
    stopCanvasResizeFromInput = false
  ) {
    const maxLeanght = Math.max(this.sizeInCm.width, this.sizeInCm.height);
    if (fromevent == "nochange") {
      if (maxLeanght < 2.5) {
        return;
      }
    }
    if (!this.sizeInCm.width || !this.sizeInCm.height) {
      return;
    }

    this.builder.loadingCanvas = true;
    if (this.sizeInCm.width < 2.5 || this.sizeInCm.width > 125) {
      if (this.sizeInCm.width < 2.5) {
        this.sizeInCm.width = 2.5;
      } else {
        this.sizeInCm.width = 125;
      }
    } else if (this.sizeInCm.height < 2.5 || this.sizeInCm.height > 125) {
      if (this.sizeInCm.height < 2.5) {
        this.sizeInCm.height = 2.5;
      } else {
        this.sizeInCm.height = 125;
      }
    }
    if (!this.cutOutline) {
      console.log("this is canvas height", this.containerHeight);
      // size is in pixels
      this.size.width = this.sizeInCm.width * 37.7952755906;
      this.size.height = this.sizeInCm.height * 37.7952755906;
      console.log(" - size height", this.size.height);
      console.log(" - size width", this.size.width);

      // this.canvas.setWidth(this.size.width);
      // this.canvas.setHeight(this.size.height);
      // stopCanvasResizeFromInput then match oldEventWidth and oldEventHeight to prevent multiple calls
      this.canvasResizeFromInput(this.size, stopCanvasResizeFromInput);
    } else {
      console.log("Changing size for cropped");
      console.log("this is canvas height", this.containerHeight);
      // size is in pixels
      this.size.width = this.sizeInCm.width * 37.7952755906;
      this.size.height = this.sizeInCm.height * 37.7952755906;
      // this.canvas.setWidth(this.size.width);
      // this.canvas.setHeight(this.size.height);

      // prevent call to canvasResizeFromInput when stopCanvasResizeFromInput is true
      if (!stopCanvasResizeFromInput)
        this.canvasResizeFromInput(
          {
            width: 5 * 37.7952755906,
            height: 5 * 37.7952755906,
          },
          stopCanvasResizeFromInput
        );

      const ratio = this.cutOutlineRatio.width / this.cutOutlineRatio.height;
      // type 1 means width is changing
      // type 2 means height is changing
      // this.sizeInCm means width and height in cm
      // Uploaded image is landscape/portrait via shape cutting
      const outlineType =
        this.cutOutlineRatio.width > this.cutOutlineRatio.height
          ? "landscape"
          : "portrait";
      if (type === 1 && outlineType === "landscape") {
        this.sizeInCm.height =
          Math.round((this.sizeInCm.width / ratio + Number.EPSILON) * 100) /
          100;
      } else if (type === 2 && outlineType === "landscape") {
        this.sizeInCm.width =
          Math.round((this.sizeInCm.height * ratio + Number.EPSILON) * 100) /
          100;
      } else if (type === 1 && outlineType === "portrait") {
        this.sizeInCm.height =
          Math.round((this.sizeInCm.width / ratio + Number.EPSILON) * 100) /
          100;
      } else if (type === 2 && outlineType === "portrait") {
        this.sizeInCm.width =
          Math.round((this.sizeInCm.height * ratio + Number.EPSILON) * 100) /
          100;
      }

      // // if ((this.sizeInCm.width < 1) || (this.sizeInCm.width > 125)) {
      // //   if (this.sizeInCm.width < 1) {
      // //     this.sizeInCm.width = 1;
      // //   } else {
      // //     this.sizeInCm.width = 125;
      // //   }
      // // } else if ((this.sizeInCm.height < 1) || (this.sizeInCm.height > 125)) {
      // //   if (this.sizeInCm.height < 1) {
      // //     this.sizeInCm.height = 1;
      // //
      // //   } else {
      // //     this.sizeInCm.height = 125;
      // //   }
      // //
      // // }
      // console.log("test3 this.sizeInCm - ", this.sizeInCm);
      // const ratio = this.cutOutlineRatio.width / this.cutOutlineRatio.height;
      // console.log("this is max cut", this.cutOutlineRatio);
      // console.log("this is max cut ratio", ratio);
      // // Uploaded image is landscape/portrait via shape cutting
      // const outlineType =
      //   this.cutOutlineRatio.width > this.cutOutlineRatio.height
      //     ? "landscape"
      //     : "portrait";
      // console.log("this is outline type", outlineType);
      // if (type === 1 && outlineType === "landscape") {
      //   this.sizeInCm.height =
      //     Math.round((this.sizeInCm.width / ratio + Number.EPSILON) * 100) /
      //     100;
      //   console.log("here 1");
      // } else if (type === 2 && outlineType === "landscape") {
      //   this.sizeInCm.width =
      //     Math.round((this.sizeInCm.height * ratio + Number.EPSILON) * 100) /
      //     100;
      // } else if (type === 1 && outlineType === "portrait") {
      //   this.sizeInCm.height =
      //     Math.round((this.sizeInCm.width * ratio + Number.EPSILON) * 100) /
      //     100;
      //   console.log("here 1");
      // } else if (type === 2 && outlineType === "portrait") {
      //   this.sizeInCm.width =
      //     Math.round((this.sizeInCm.height / ratio + Number.EPSILON) * 100) /
      //     100;
      // }
    }
    console.log(
      "pieces #######################",
      this.builder.stickerDetails.pieces
    );
    this.builder.size.width = this.sizeInCm.width;
    this.builder.size.height = this.sizeInCm.height;
    this.builder.stickerDetails.size.width = this.sizeInCm.width;
    this.builder.stickerDetails.size.height = this.sizeInCm.height;
    this.builder.stickerDetails.stickerNumber.price =
      this.builder.calculatePrice(this.builder.stickerDetails.pieces);
    console.log(
      "Pieces price",
      this.builder.calculatePrice(this.builder.stickerDetails.pieces)
    );
    this.builder.loadingCanvas = false;
  }

  /**
   * Change size on resizing window
   * @param event Resize event
   * @param parentEvent Resize event of parent container
   */
  canvasResize(event, parentEvent) {
    if (event.width >= 700) {
      const ratio1 = this.canvas.getWidth() / this.canvas.getHeight();
      let containerWidth1 = event.width;
      // if (this.canvasResizeChange.width > event.width) {
      //   // const containerHeight1 = window.innerHeight - 400;
      //   // const containerWidth1 = containerHeight1 * ratio1;
      //   containerWidth1 = event.width - 40;
      //   console.log("canvasResize - if - containerWidth1 - ", containerWidth1);
      // }

      const scale1 = containerWidth1 / this.canvas.getWidth();
      const zoom1 = this.canvas.getZoom() * scale1;
      this.canvas.setDimensions({
        width: containerWidth1,
        height: containerWidth1 / ratio1,
      });
      this.canvas.setViewportTransform([zoom1, 0, 0, zoom1, 0, 0]);
      return;
    } else {
      const ratio1 = this.canvas.getWidth() / this.canvas.getHeight();
      const containerWidth1 = event.width;
      const scale1 = containerWidth1 / this.canvas.getWidth();
      const zoom1 = this.canvas.getZoom() * scale1;
      this.canvas.setDimensions({
        width: containerWidth1,
        height: containerWidth1 / ratio1,
      });
      this.canvas.setViewportTransform([zoom1, 0, 0, zoom1, 0, 0]);
      return;
    }

    // const ratio1 = this.canvas.getWidth() / this.canvas.getHeight();
    // const containerHeight1 = window.innerHeight - 353;
    // const containerWidth1 = containerHeight1 * ratio1;
    // const scale1 = containerWidth1 / this.canvas.getWidth();
    // const zoom1 = this.canvas.getZoom() * scale1;

    // this.canvas.setWidth(containerWidth1);
    // this.canvas.setHeight(containerWidth1 / ratio1);
    // this.canvas.setDimensions({
    //   width: containerWidth1,
    //   height: containerWidth1 / ratio1,
    // });
    // this.canvas.setViewportTransform([zoom1, 0, 0, zoom1, 0, 0]);
    // const outerCanvasContainer = document.getElementById('canvas');

    // const ratio = this.canvas.getWidth() / this.canvas.getHeight();
    // const ratio = this.sizeInCm.width / this.sizeInCm.height;
    // const containerWidth = event.width;
    // const containerHeight = event.height;

    // this.containerWidth = event.width;
    // this.containerHeight = event.height;

    // const scale = event.width / this.canvas.getWidth();
    // const scale = parentEvent.width / this.canvas.getWidth();
    // const zoom = this.canvas.getZoom() * scale;

    // const maxCutRatio = parentEvent.width / parentEvent.height;

    // this.canvas.setWidth(event.width);
    // this.size.width = event.width;
    // this.size.height = containerWidth / ratio;
    // this.changeSize();
    // if (event.height > parentEvent.height) {
    //   this.canvas.setHeight(containerHeight / ratio);
    //   this.canvas.setWidth(event.width);
    // } else {
    //   this.canvas.setHeight(containerWidth / ratio);
    //   this.canvas.setWidth(event.width);
    // }
    // this.canvas.setHeight(containerWidth / ratio);
    // this.canvas.setWidth(event.width);

    // this.sizeInCm.width = Math.round(event.width / 37.7952755906);
    // this.sizeInCm.height = Math.round((containerWidth / ratio) / 37.7952755906);

    // Testing starts here
    // const newHeight = containerWidth / ratio;
    // const newWidth = containerWidth / ratio;

    // const orientation =
    //   this.sizeInCm.width > this.sizeInCm.height ? "landscape" : "portrait";

    // if (orientation === "landscape") {
    //   const setHeight = newHeight / ratio;
    //   const setWidth = event.height * ratio;
    //   // this.canvas.setHeight(setHeight);
    //   // this.canvas.setWidth(setWidth);
    //   this.canvas.setDimensions({ width: event.width, height: setHeight });
    //   // this.canvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0]);
    // } else if (orientation === "portrait") {
    // // const setHeight = newHeight * ratio;
    // // const setWidth = event.height / ratio;

    // // this.sizeInCm.height = Math.round(this.sizeInCm.width * ratio);
    // // this.sizeInCm.width = Math.round(this.sizeInCm.height / ratio);

    //   this.canvas.setHeight(containerWidth / ratio);
    //   this.canvas.setWidth(event.width);
    //   this.canvas.setDimensions({
    //     width: event.width,
    //     height: containerWidth / ratio,
    //   });
    //   this.canvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0]);

    // }

    // Testing ends here
    //
    // this.canvas.setHeight(containerWidth / ratio);
    // this.canvas.setWidth(event.width);
    // this.canvas.setDimensions({width: event.width, height: containerWidth / ratio});
    // this.canvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0]);
  }

  changeChildSize(width, height) {
    this.canvas.setWidth(width);
    this.canvas.setHeight(height);
    // Assuming `this.canvas` is an instance of `fabric.Canvas`
    const canvasElement = this.canvas.getElement();

    // Set the height with !important
    canvasElement.style.setProperty("height", `${height}px`, "important");
  }

  // Block "Add text"
  addSessionText(setTop, setLeft, setX, setY) {
    if (this.textString) {
      const text = new fabric.IText(this.textString, {
        left: setLeft,
        top: setTop,
        fontFamily: "helvetica",
        angle: 0,
        fill: "#000000",
        scaleX: setX,
        scaleY: setY,
        fontWeight: "",
        fontStyle: "",
        hasRotatingPoint: true,
        underline: false,
        overline: false,
        linethrough: false,
      });
      // this.canvas.centerObject(text);
      this.extend(text, this.randomId());
      this.canvas.add(text);
      this.selectItemAfterAdded(text);
      this.textString = "";
    }
  }

  addText() {
    if (this.textString) {
      const text = new ExtendedFabricText(this.textString, {
        left: 10,
        top: 10,
        fontFamily: "helvetica",
        angle: 0,
        fill: "#000000",
        scaleX: 1,
        scaleY: 1,
        fontWeight: "",
        fontStyle: "",
        lockUniScaling: true,
        hasRotatingPoint: true,
        underline: false,
        overline: false,
        linethrough: false,
      });
      text.set({ id: "sw_" + Date.now() }); // Set the 'id' property after the object is created
      this.canvas.centerObject(text);
      this.extend(text, this.randomId());
      // A duplicate object will be shown outside of the canvas so it will added before the real object
      var textDup = fabric.util.object.clone(text);
      textDup.set({ opacity: 0.5, id: text.id + "_dup" });
      const workspace = this.canvas
        .getObjects()
        .find((item: any) => item.id === "workspace");
      this.canvas.add(textDup);
      text.clipPath = workspace;
      setTimeout(() => {
        this.canvas.add(text);
      }, 100);
      this.selectItemAfterAdded(text);
      this.textString = "";
    }
  }

  addJuniorText(textDetails, event, texts) {
    const canvasObj = this.canvas.getObjects();
    console.log("these are all objects", canvasObj);

    let childStickerParent = document.getElementById("child-sticker-parent");
    var childStickerText = document.getElementById("child-sticker-text");

    // Get the bounding rectangles of both elements
    let childStickerParentRect = childStickerParent.getBoundingClientRect();
    var childStickerTextRect = childStickerText?.getBoundingClientRect();

    if (childStickerTextRect) {
      // Calculate the distances
      let topDistance = childStickerTextRect.top - childStickerParentRect.top;
      let leftDistance =
        childStickerTextRect.left - childStickerParentRect.left;
      let rightDistance =
        childStickerParentRect.right - childStickerTextRect.right;
      let bottomDistance =
        childStickerParentRect.bottom - childStickerTextRect.bottom;
      console.log("Top distance:", topDistance, "px");
      console.log("Left distance:", leftDistance, "px");
      console.log("Right distance:", rightDistance, "px");
      console.log("Bottom distance:", bottomDistance, "px");

      // console.log("this is width", width);
      textDetails.text.forEach((el) => {
        console.log("element", el);
        const workspace = this.canvas
          .getObjects()
          .find(
            (item: any) => item.id === "juniorText"
          ) as ExtendedFabricTextbox;

        if (workspace) {
          workspace.set({
            text: el.text,
            left:
              textDetails.textAlign == "left"
                ? leftDistance + 15
                : textDetails.textAlign == "right"
                ? leftDistance - 15
                : leftDistance,
            width: childStickerTextRect.width,
            height: childStickerTextRect.height,
            top: topDistance,
            fontFamily: textDetails.fontFamily,
            angle: 0,
            fontSize: el.canvasSize,
            lineHeight: 0.9,
            fill: textDetails.color ? textDetails.color : "#000000",
            textAlign: textDetails.textAlign,
            fontWeight: textDetails.bold === true ? "bold" : 100,
            fontStyle: textDetails.italic === true ? "italic" : "normal",
            hasRotatingPoint: false,
            lockMovementX: true,
            lockMovementY: true,
            lockScalingX: true,
            lockScalingY: true,
            overline: false,
            underline: false,
            linethrough: false,
            selectable: false,
          });
          this.extend(workspace, el.type === 1 ? 1 : el.type === 2 ? 2 : 3);
          this.canvas.renderAll();
        } else {
          const text = new ExtendedFabricTextbox(el.text, {
            left:
              textDetails.textAlign == "left"
                ? leftDistance + 15
                : textDetails.textAlign == "right"
                ? leftDistance - 15
                : leftDistance,
            width: childStickerTextRect.width,
            height: childStickerTextRect.height,
            top: topDistance,
            fontFamily: textDetails.fontFamily,
            angle: 0,
            fontSize: el.canvasSize,
            lineHeight: 0.9,
            fill: textDetails.color ? textDetails.color : "#000000",
            textAlign: textDetails.textAlign,
            fontWeight: textDetails.bold === true ? "bold" : 100,
            fontStyle: textDetails.italic === true ? "italic" : "normal",
            hasRotatingPoint: false,
            lockMovementX: true,
            lockMovementY: true,
            lockScalingX: true,
            lockScalingY: true,
            overline: false,
            underline: false,
            linethrough: false,
            selectable: false,
          });
          text.set({ id: "juniorText" });
          this.extend(text, el.type === 1 ? 1 : el.type === 2 ? 2 : 3);

          this.canvas.add(text);
          // this.selectItemAfterAdded(text);
        }
      });
    } else {
      const workspace = this.canvas
        .getObjects()
        .find((item: any) => item.id === "juniorText") as ExtendedFabricTextbox;
      if (workspace) {
        this.canvas.remove(workspace);
        this.canvas.renderAll();
      }
    }
  }

  // Block "Add images"

  getImgPolaroid(event: any, test: any, setTop, setLeft, setX, setY) {
    if (test == "test") {
      this.builder.loader.emit(true);
      const el1 = event;
      console.log("element src >>>>>>>>>>>>>>>>>", el1);
      let objectID: string = "";
      // A duplicate object will be shown outside of the canvas so it will added before the real object
      fabric.loadSVGFromURL(el1, (objects, options) => {
        const image = new ExtendedFabricGroup(objects, options);
        image.set({
          angle: 0,
          cornerSize: 10,
          hasRotatingPoint: true,
          top: setTop,
          left: setLeft,
          scaleX: setX,
          scaleY: setY,
          opacity: 0.5,
          id: "sw_" + Date.now() + "_dup",
        });
        this.extend(image, this.randomId());
        objectID = image.id.replace("_dup", "");
        this.canvas.add(image);
        this.canvas.centerObject(image);
      });
      fabric.loadSVGFromURL(el1, (objects, options) => {
        const image = new ExtendedFabricGroup(objects, options);
        image.set({
          angle: 0,
          cornerSize: 10,
          hasRotatingPoint: true,
          top: setTop,
          left: setLeft,
          scaleX: setX,
          scaleY: setY,
          id: objectID,
        });
        this.extend(image, this.randomId());
        // Setting clipPath for cropping the outside image of the canvas
        const workspace = this.canvas
          .getObjects()
          .find((item: any) => item.id === "workspace");
        image.clipPath = workspace;
        this.canvas.add(image);
        // this.canvas.centerObject(image);
        this.selectItemAfterAdded(image);
        console.log("Clip timeout", new Date().getTime());
        setTimeout(() => console.log("Clip timeout", new Date().getTime()), 10);
        this.builder.loader.emit(false);
      });
    } else {
      this.builder.loader.emit(true);
      const el1 = event.target.src;
      this.clip.push(el1);
      let objectID: string = "";
      const clipArr = this.clip;
      fabric.loadSVGFromURL(el1, (objects, options) => {
        // A duplicate object will be shown outside of the canvas so it will added before the real object
        const imageDup = new ExtendedFabricGroup(objects, options);
        imageDup.set({
          angle: 0,
          cornerSize: 10,
          hasRotatingPoint: true,
          opacity: 0.5,
          id: "sw_" + Date.now() + "_dup",
        });
        this.extend(imageDup, this.randomId());
        objectID = imageDup.id.replace("_dup", "");
        this.canvas.add(imageDup);
        this.canvas.centerObject(imageDup);

        setTimeout(() => {
          // Code to execute after 1 second delay
          console.log("1 second delay has passed");
          const image = new ExtendedFabricGroup(objects, options);
          image.set({
            angle: 0,
            cornerSize: 10,
            hasRotatingPoint: true,
            id: objectID,
          });
          this.extend(image, this.randomId());
          // Setting clipPath for cropping the outside image of the canvas
          const workspace = this.canvas
            .getObjects()
            .find((item: any) => item.id === "workspace");
          image.clipPath = workspace;

          this.canvas.add(image);
          this.canvas.centerObject(image);
          this.selectItemAfterAdded(image);
          console.log("Clip timeout", new Date().getTime());
          setTimeout(
            () => console.log("Clip timeout", new Date().getTime()),
            10
          );
          this.builder.loader.emit(false);
          const clipart =
            this.getLanguage === "en" ? "ClipArt" : "Clipart-kuva";
          const clipartAdd =
            this.getLanguage === "en"
              ? "ClipaArt Added"
              : "Clipart-kuva lisätty";

          this.builder.showSuccess(clipart, clipartAdd);
        }, 1000);
      });
      localStorage.setItem("clipArtUrl", JSON.stringify(clipArr));
      this.canvas.renderAll();
    }
  }

  addSvg(url: any, event: any) {
    this.builder.canvas.readUrl(event);

    const fd = new FormData(); // To carry on your data
    if (event.target.files && event.target.files[0]) {
      const files = event.target.files[0];
      fd.append("files", files);
    }
    let link = "";
    var startTime = performance.now();

    this.api.uploadApi(fd).subscribe(
      (data) => {
        link = AppConstants.BASE_URL + data[0].url;
        console.log("element src >>>>>>>>>>>>>>>>>", link);
        let objectID: string = "";
        fabric.loadSVGFromURL(link, (objects, options) => {
          // A duplicate object will be shown outside of the canvas so it will added before the real object
          const image = new ExtendedFabricGroup(objects, options);
          image.set({
            angle: 0,
            cornerSize: 10,
            hasRotatingPoint: true,
            id: "sw_" + Date.now() + "_dup",
            opacity: 0.5,
          });
          // Added scaling to make SVG look bigger. SVG looks blurry if we increase the width and length.Adding scale to image for svg show big. if we add height/width then svg show blur
          if (image.height < 300) {
            // image.scaleY = 4;
            // image.height = 600;
            image.scaleToHeight(600);
            // this.setHeightWidth = true;
          }
          if (image.width < 300) {
            // image.scaleX = 4;
            // image.width = 600;
            image.scaleToWidth(600);
            // this.setHeightWidth = true;
          }
          const workspace = this.canvas
            .getObjects()
            .find((item: any) => item.id === "workspace");
          const imageRatio = image.width / image.height;
          const canvasRatio = workspace.width / workspace.height;
          // const canvasRatio = this.canvas.getWidth() / this.canvas.getHeight();
          if (imageRatio <= canvasRatio) {
            if (image.height * image.scaleY > workspace.height) {
              image.scaleToHeight(workspace.height);
            }
          } else {
            if (image.width * image.scaleX > workspace.width) {
              image.scaleToWidth(workspace.width);
            }
          }

          this.extend(image, this.randomId());
          // store the object id without _dup to use it for the real object
          objectID = image.id.replace("_dup", "");

          this.canvas.add(image);
          this.canvas.centerObject(image);
          var endTime = performance.now();
          console.log(
            `Call to addSvg took ${endTime - startTime} milliseconds`
          );
          console.log("Clip timeout", new Date().getTime());
          setTimeout(
            () => console.log("Clip timeout", new Date().getTime()),
            10
          );
        });
        fabric.loadSVGFromURL(link, (objects, options) => {
          // const image = fabric.util.groupSVGElements(objects, options);
          const image = new ExtendedFabricGroup(objects, options);
          image.set({
            angle: 0,
            cornerSize: 10,
            hasRotatingPoint: true,
            id: objectID,
          });
          // Added scaling to make SVG look bigger. SVG looks blurry if we increase the width and length.Adding scale to image for svg show big. if we add height/width then svg show blur
          if (image.height < 300) {
            // image.scaleY = 4;
            image.scaleToHeight(600);
            // image.height = 600;
            // this.setHeightWidth = true;
          }
          if (image.width < 300) {
            // image.scaleX = 4;
            image.scaleToWidth(600);
            // image.width = 600;
            // this.setHeightWidth = true;
          }
          const workspace = this.canvas
            .getObjects()
            .find((item: any) => item.id === "workspace");
          const imageRatio = image.width / image.height;
          const canvasRatio = workspace.width / workspace.height;
          // const canvasRatio = this.canvas.getWidth() / this.canvas.getHeight();
          if (imageRatio <= canvasRatio) {
            if (image.height * image.scaleY > workspace.height) {
              image.scaleToHeight(workspace.height);
            }
          } else {
            if (image.width * image.scaleX > workspace.width) {
              image.scaleToWidth(workspace.width);
            }
          }

          this.extend(image, this.randomId());
          // setting clipPath for cropping the outside image of the canvas
          image.clipPath = workspace;
          this.canvas.add(image);
          this.canvas.centerObject(image);
          this.selectItemAfterAdded(image);
          var endTime = performance.now();
          console.log(
            `Call to addSvg took ${endTime - startTime} milliseconds`
          );
          console.log("Clip timeout", new Date().getTime());
          setTimeout(
            () => console.log("Clip timeout", new Date().getTime()),
            10
          );
          this.builder.showSuccess("ClipArt", "ClipArt Added");
        });
      },
      (error) => {
        console.log("An error occurred", error);
      }
    );
  }

  getChildImgPolaroid(event: any, texts?) {
    console.log(" *********** >>>>>>>>>>>>>>>", texts);
    let childStickerParent = document.getElementById("child-sticker-parent");
    var childStickerImage = document.getElementById("child-sticker-image");

    // Get the bounding rectangles of both elements
    let childStickerParentRect = childStickerParent.getBoundingClientRect();
    var childStickerImageRect = childStickerImage?.getBoundingClientRect();

    // Calculate the distances
    let topDistance = childStickerImageRect.top - childStickerParentRect.top;
    let leftDistance = childStickerImageRect.left - childStickerParentRect.left;
    let rightDistance =
      childStickerParentRect.right - childStickerImageRect.right;
    let bottomDistance =
      childStickerParentRect.bottom - childStickerImageRect.bottom;
    console.log("Top distance:", topDistance, "px");
    console.log("Left distance:", leftDistance, "px");
    console.log("Right distance:", rightDistance, "px");
    console.log("Bottom distance:", bottomDistance, "px");
    // let testImageHeight = childStickerImageRect.height / 2;
    // let testImageWidth = childStickerImageRect.width / 2;

    // if (this.canvas.getObjects().length >= 1) {
    //   debugger;
    //   this.canvas.remove(this.canvas.getActiveObject());
    //   if (event) {
    //     const ele = event.target;
    //     fabric.loadSVGFromURL(ele.src, (objects, options) => {
    //       const image = fabric.util.groupSVGElements(objects, options);
    //       image.set({
    //         left: 15,
    //         angle: 0,
    //         padding: 15,
    //         cornerSize: 10,
    //         // scaleX: 2.5,
    //         // scaleY: 2.5,
    //         top: 10,
    //         hasRotatingPoint: false,
    //         lockMovementX: true,
    //         lockMovementY: true,
    //         lockScalingX: true,
    //         lockScalingY: true,
    //       });
    //       image.scaleToHeight(this.canvas.getHeight());
    //       // @ts-ignore
    //       image.id = "childImage";
    //       this.extend(image, this.randomId());
    //       if (texts === 0) {
    //         this.canvas.centerObject(image);
    //       } else {
    //         image.top = 10;
    //         image.left = 15;
    //       }
    //       image.width = childStickerImageRect.width - 15;
    //       image.height = childStickerImageRect.height - 15;
    //       console.log("kkkk1 image.width ", image.width);
    //       console.log("kkkk1 image.height ", image.height);

    //       this.canvas.add(image);
    //       this.selectItemAfterAdded(image);
    //       console.log("Get objects", this.canvas.getObjects());

    //       // console.log('these are all objects', this.canvas.getObjects());
    //     });
    //   }
    // } else {
    if (event) {
      const ele = event.target;
      fabric.loadSVGFromURL(ele.src, (objects, options) => {
        console.log("loadSVGFromURL - options - ", options);
        const image = this.canvas
          .getObjects()
          .find((item: any) => item.id === "childImage");

        if (image) {
          const updatedImage = fabric.util.groupSVGElements(objects, options);
          updatedImage.set({
            left: 0,
            top: 0,
            originX: "left",
            originY: "top",
            hasRotatingPoint: false,
            lockMovementX: true,
            lockMovementY: true,
            lockScalingX: true,
            lockScalingY: true,
            selectable: false,
          });

          updatedImage.scaleToHeight(childStickerImageRect.height);
          if (texts === 0) {
            this.canvas.centerObject(updatedImage);
          } else {
            updatedImage.left = leftDistance + 10;
            updatedImage.top = topDistance;
          }
          // @ts-ignore
          updatedImage.id = "childImage";

          // Remove the old image and add the updated one
          this.canvas.remove(image);
          this.canvas.add(updatedImage);
          this.canvas.renderAll();
        } else {
          const newImage = fabric.util.groupSVGElements(objects, options);

          newImage.set({
            left: 0,
            top: 0,
            originX: "left",
            originY: "top",
            hasRotatingPoint: false,
            lockMovementX: true,
            lockMovementY: true,
            lockScalingX: true,
            lockScalingY: true,
            selectable: false,
          });

          newImage.scaleToHeight(childStickerImageRect.height);
          if (texts === 0) {
            this.canvas.centerObject(newImage);
          } else {
            newImage.left = leftDistance + 10;
            newImage.top = topDistance;
          }

          this.extend(newImage, this.randomId());
          // @ts-ignore
          newImage.id = "childImage";

          console.log(" image.width ", newImage.width);
          console.log(" image.height ", newImage.height);
          this.canvas.add(newImage);
          // this.selectItemAfterAdded(newImage);
        }
        // @ts-ignore
        // console.log(  "Objects *************************",  this.canvas.getObjects()[4].id );
      });
    }
    // }
  }

  // Block "Upload Image"

  addSessionImage(url, setTop, setLeft, setX, setY) {
    if (url) {
      fabric.Image.fromURL(url, (image: ExtendedFabricImage) => {
        image.objectCaching = false;
        // Set Image
        image.set({
          angle: 0,
          hasRotatingPoint: true,
          stroke: "#f55",
          fill: "red",
          top: setTop,
          left: setLeft,
          scaleX: setX,
          scaleY: setY,
          id: "sw_" + Date.now(),
        });
        const imageRatio = image.width / image.height;
        const canvasRatio = this.canvas.getWidth() / this.canvas.getHeight();
        if (imageRatio <= canvasRatio) {
          if (image.height > this.canvas.getHeight()) {
            image.scaleToHeight(this.canvas.getHeight());
          }
        } else {
          if (image.width > this.canvas.getWidth()) {
            image.scaleToWidth(this.canvas.getWidth());
          }
        }
        this.extend(image, this.randomId());
        // clone real object to  duplicate object will be shown outside of the canvas so it will added before the real object
        var ObjectDup = fabric.util.object.clone(image);
        ObjectDup.set({
          opacity: 0.5,
          id: image.id + "_dup",
        });
        const workspace = this.canvas
          .getObjects()
          .find((item: any) => item.id === "workspace");
        image.clipPath = workspace;

        this.canvas.add(ObjectDup);
        this.canvas.add(image);
        this.selectItemAfterAdded(image);
      });
    }
  }

  addImageOnCanvas(url) {
    if (url) {
      fabric.Image.fromURL(url, (image: ExtendedFabricImage) => {
        console.log("This is image dimensions", image.height, image.width);
        image.objectCaching = false;

        // Set Image
        image.set({
          angle: 0,
          hasRotatingPoint: true,
          stroke: "#f55",
          fill: "red",
          top: 275,
          left: 35,
          id: "sw_" + Date.now(), // Unique ID
        });
        // image.scaleToWidth(200);

        // this.canvas.getHeight() > this.canvas.getWidth() ? image.scaleToWidth(this.canvas.getWidth())
        // : image.scaleToHeight(this.canvas.getHeight());

        const imageRatio = image.width / image.height;
        const canvasRatio = this.canvas.getWidth() / this.canvas.getHeight();

        if (imageRatio <= canvasRatio) {
          if (image.height > this.canvas.getHeight()) {
            image.scaleToHeight(this.canvas.getHeight());
          }
        } else {
          if (image.width > this.canvas.getWidth()) {
            image.scaleToWidth(this.canvas.getWidth());
          }
        }

        // if (image.height > image.width) {
        //
        //   if (image.width > this.canvas.getWidth()) {
        //     image.scaleToWidth(this.canvas.getWidth());
        //
        //     console.log('Here width > height');
        //   }
        //   if (image.height > this.canvas.getHeight()) {
        //     image.scaleToHeight(this.canvas.getHeight());
        //     // if (image.width > this.canvas.getWidth()) {
        //     //   console.log('****** setting img acc to canvas');
        //     //   image.scaleToWidth(this.canvas.getWidth());
        //     //
        //     // }
        //
        //     console.log('Here height > width');
        //
        //   }
        //
        // } else {
        //   if (image.height > this.canvas.getHeight()) {
        //     image.scaleToHeight(this.canvas.getHeight());
        //     // if (image.width > this.canvas.getWidth()) {
        //     //   console.log('****** setting img acc to canvas');
        //     //   image.scaleToWidth(this.canvas.getWidth());
        //     //
        //     // }
        //
        //     console.log('Here height > width');
        //
        //   }
        //
        //   if (image.width > this.canvas.getWidth()) {
        //     image.scaleToWidth(this.canvas.getWidth());
        //
        //     console.log('Here width > height');
        //   }
        //
        //
        // }

        // image.scaleToWidth(this.canvas.getWidth());

        this.extend(image, this.randomId());
        // this.addDeleteIcon();
        const workspace = this.canvas
          .getObjects()
          .find((item: any) => item.id === "workspace");
        // clone real object to  duplicate object will be shown outside of the canvas so it will added before the real object
        var ObjectDup = fabric.util.object.clone(image);
        ObjectDup.set({
          opacity: 0.5,
          id: image.id + "_dup",
        });
        this.canvas.add(ObjectDup);
        // setting clipPath for cropping the outside image of the canvas
        image.clipPath = workspace;
        this.canvas.add(image);
        this.canvas.centerObject(ObjectDup);
        this.canvas.centerObject(image);

        this.selectItemAfterAdded(image);
        console.log("Image is added", this.canvas.getObjects());
        if (!localStorage.getItem("canvasDetails")) {
          this.builder.showSuccess("Artwork", "Artwork is added");
        }
      });
    }
  }

  /**
   * Cut to outline
   * Step 1: Hit the background remover API and get Transparent PNG image
   */

  async removeBg() {
    // console.log('BG********', this.files);
    // console.log('BG********', this.mFile.name);
    // console.log('BG********', this.mFile);
    // console.log("removeBg - URL - ", this.url);
    if (this.url.length > 0) {
      // console.log('removing', this.url);

      console.log("This is url", this.url);
      const img = new Image();
      img.src = this.url;
      img.onload = () => {
        console.log("Height", img.height, img.width);
      };

      // const transparent = await this.removeWhitePart(this.url);
      // console.log('Image is transparent', transparent);
      this.canvas.clear();

      // if (this.extension === 'png' && !transparent) {
      //   this.removeBackground();
      // } else if (this.extension === 'png' && transparent) {
      //   // this.testClipping(this.url);
      //   this.removeBackground();
      //
      // } else {
      //   this.removeBackground();
      //
      // }
      // let base64 = "here comes local base64 for debugging so that RemoveBg API is not called";
      // this.drawStickerBg(base64);
      this.removeBgApi();
    } else {
      console.log("A gya error");
    }
  }

  // removeBackground() {
  //   this.builder.loadingCanvas = true;
  //   const formData = new FormData();
  //   formData.append('image_file', this.mFile);
  //   // console.log('this is form data', formData);
  //
  //   const a = 'https://cb78ca4ab890875fed834f7b8e2d5101.m.pipedream.net';
  //   const b = 'https://sdk.photoroom.com/v1/segment';
  //   const headers = new HttpHeaders(({
  //     // 'x-api-key': '9d4f78f63d5580980eb5af21cd89a6a928ed0999',
  //     'Content-Type': 'multipart/form-data;'
  //   }));
  //
  //   const header = {
  //     'x-api-key': '9d4f78f63d5580980eb5af21cd89a6a928ed0999'
  //   };
  //   /** In Angular 5, including the header Content-Type can invalidate your request */
  //   // headers.append('Content-Type', 'multipart/form-data');
  //   this.http.post(b, formData, {headers: header, responseType: 'blob' as const}).subscribe(data => {
  //     console.log('this is the data', data);
  //     const objectURL = URL.createObjectURL(data);
  //     this.image = this.sanitizer.bypassSecurityTrustUrl(objectURL);
  //     console.log('this is image >>>>>>>>>>', this.image);
  //     // const newImage = document.createElement('img');
  //     // const newImage = new Image();
  //     // newImage.id = 'newImage';
  //     // newImage.src = this.image;
  //
  //     // this.addImageOnCanvas(this.image);
  //     // this.src = this.image;
  //     this.drawStickerBg(this.image);
  //
  //
  //     const reader = new FileReader();
  //     reader.readAsDataURL(data);
  //     reader.onloadend = () => {
  //       const base64data = reader.result;
  //       console.log('base 64 data', base64data);
  //       const img = new Image();
  //       if (typeof base64data === 'string') {
  //         console.log('yes string found!!'
  //         );
  //         img.src = base64data;
  //       }
  //       // this.testClipping(base64data);
  //
  //       // this.addImageOnCanvas(base64data);
  //       // this.removeWhitePart(base64data);
  //     };
  //     this.builder.loadingCanvas = false;
  //     console.log('All objects', this.canvas.getObjects());
  //   }, error => {
  //     console.log('this is the error', error);
  //     this.builder.loadingCanvas = false;
  //
  //   });
  // }

  // removeBackgroundHome() {
  //   this.readUrl(this.builder.fileEvent);
  //   const formData = new FormData();
  //   formData.append('image_file', this.mFile);
  //   // console.log('this is form data', formData);
  //
  //   const a = 'https://cb78ca4ab890875fed834f7b8e2d5101.m.pipedream.net';
  //   const b = 'https://sdk.photoroom.com/v1/segment';
  //   const headers = new HttpHeaders(({
  //     // 'x-api-key': '9d4f78f63d5580980eb5af21cd89a6a928ed0999',
  //     'Content-Type': 'multipart/form-data;'
  //   }));
  //
  //   const header = {
  //     'x-api-key': '9d4f78f63d5580980eb5af21cd89a6a928ed0999'
  //   };
  //   /** In Angular 5, including the header Content-Type can invalidate your request */
  //   // headers.append('Content-Type', 'multipart/form-data');
  //   this.http.post(b, formData, {headers: header, responseType: 'blob' as const}).subscribe(data => {
  //     console.log('this is the data', data);
  //     const objectURL = URL.createObjectURL(data);
  //     this.image = this.sanitizer.bypassSecurityTrustUrl(objectURL);
  //     console.log('this is image >>>>>>>>>>', this.image);
  //     // const newImage = document.createElement('img');
  //     // const newImage = new Image();
  //     // newImage.id = 'newImage';
  //     // newImage.src = this.image;
  //
  //     // this.addImageOnCanvas(this.image);
  //     // this.src = this.image;
  //     // this.drawStickerBg(this.image);
  //
  //
  //     const reader = new FileReader();
  //     reader.readAsDataURL(data);
  //     reader.onloadend = () => {
  //       const base64data = reader.result;
  //       console.log('base 64 data', base64data);
  //       const img = new Image();
  //       if (typeof base64data === 'string') {
  //         console.log('yes string found!!'
  //         );
  //         img.src = base64data;
  //       }
  //       // this.testClipping(base64data);
  //       //
  //       // this.addImageOnCanvas(base64data);
  //       // this.removeWhitePart(base64data);
  //       this.removeBg();
  //     };
  //
  //
  //   }, error => {
  //     console.log('this is the error', error);
  //   });
  // }

  /**
   * Generate SVG from polygon points
   */

  createSvg(points, image) {
    const smooth = simplify(points, 0);
    points = smooth;
    console.log("this is smoooooooooooooooooooooooth", smooth);
    console.log("these are polygon points array", points);
    const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    const polygon = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "polygon"
    );
    svg.appendChild(polygon);
    for (const item of points) {
      const point = svg.createSVGPoint();
      point.x = item.x + 3;
      point.y = item.y + 3;
      // console.log('this is the point created', point);
      polygon.points.appendItem(point);
    }

    console.log("this is created svg", svg);
    console.log("this is created polygon", polygon);
    console.log("this is created polygon", polygon);

    const serializer = new XMLSerializer();
    const svgStr = serializer.serializeToString(svg);

    console.log("this is svg string", svgStr);

    fabric.loadSVGFromString(svgStr, (objects, options) => {
      const obj: any = fabric.util.groupSVGElements(objects, options);
      // @ts-ignore
      // obj.scaleToHeight(this.canvas.getHeight() - 10).set({ left: this.canvas.getWidth / 2, top: this.canvas.getHeight / 2 })
      //   .setCoords();
      // this.canvas.centerObject(obj);
      // obj.setCoords();
      obj.set({
        stroke: "red",
        id: "bg",
        fill: "rgba(255,255,255,0)",
        left: this.size.width / 2 - obj.width / 2,
        top: 0,
        lockScalingX: true, // lock scaling
        lockScalingY: true, // ^
        strokeShadow: true,
        shadow: { blur: 4, offsetX: 3, offsetY: 3 },
      });

      this.canvas.add(
        image.set({
          left: this.size.width / 2 - obj.width / 2,
          top: 20,
          id: "cutImg",
          // globalCompositeOperation: 'source-atop'
        })
      );

      obj.setControlsVisibility({
        tr: false,
        bl: false,
        ml: false,
        mt: false,
        mr: false,
        mb: false,
        mtr: false,
      });

      this.canvas.on("object:modified", (e: any) => {
        if (e.target.id === "bg") {
          console.log("going back", e);
          this.events = e.target;
          this.cropCutting.left = e.target.left;
          this.cropCutting.top = e.target.top;
          this.cropCutting.width = e.target.cacheWidth;
          this.cropCutting.height = e.target.cacheHeight;
          this.sendToBack();
          console.log("this is crop cutting", this.cropCutting);
        } else if (e.target.id === "cutImg") {
          // this.canvas.setActiveObject(e);
        }
      });

      //
      // console.log('this is the height svg', obj.getHeight());
      // console.log('this is the width svg', obj.getWidth());
      // this.canvas.centerObject(obj);

      const size = {
        width: obj.width,
        height: obj.height,
      };

      const scaleX = this.containerWidth / size.width;
      const scaleY = this.containerHeight / size.height;
      const scale = Math.min(scaleX, scaleY);
      console.log("this is scale", scale);

      console.log("svg width", obj.width);
      console.log("svg height", obj.height);
      this.canvas.add(obj);
      // this.canvas.centerObject(obj);
      // obj.setCoords();
      this.canvas.renderAll();
      // this.canvas.add(obj).renderAll();
      const imagesOfCanvas: any = this.canvas.getObjects();
      console.log("these are all objects on canvas", imagesOfCanvas);
      for (const item of imagesOfCanvas) {
        if (item.id === "bg") {
          this.cropCutting.left = item.left;
          this.cropCutting.top = item.top;
          this.cropCutting.width = item.width;
          this.cropCutting.height = item.height;

          obj.scaleToHeight(size.height * scale);
          obj.scaleToWidth(size.width * scale);

          image.scaleToHeight(size.height * scale);
          image.scaleToWidth(size.width * scale);

          const scaledHeight = obj.getScaledHeight();
          const scaledWidth = obj.getScaledWidth();
          this.sizeInCm.height = scaledHeight / this.cm;
          this.sizeInCm.width = scaledWidth / this.cm;

          this.cutOutlineRatio.width = scaledWidth / this.cm;
          this.cutOutlineRatio.height = scaledHeight / this.cm;
          console.log(
            "this is max scaled height and width in cm",
            this.cutOutlineRatio.width,
            this.cutOutlineRatio.height
          );
        }
      }
      this.canvas.setActiveObject(image);

      this.canvas.renderAll();
    });
    this.cutOutline = true;
    console.log("these are cutting coords", this.cropCutting);
  }

  removeWhite(url) {
    this.url = "";
  }

  // Block "Add figure"

  addFigure(figure) {
    let add: any;
    switch (figure) {
      case "rectangle":
        add = new fabric.Rect({
          width: 200,
          height: 100,
          left: 10,
          top: 10,
          angle: 0,
          fill: "#3f51b5",
        });
        break;
      case "square":
        add = new fabric.Rect({
          width: 100,
          height: 100,
          left: 10,
          top: 10,
          angle: 0,
          fill: "#4caf50",
        });
        break;
      case "triangle":
        add = new fabric.Triangle({
          width: 100,
          height: 100,
          left: 10,
          top: 10,
          fill: "#2196f3",
        });
        break;
      case "circle":
        add = new fabric.Circle({
          radius: 50,
          left: 10,
          top: 10,
          fill: "#ff5722",
        });
        break;
    }
    this.extend(add, this.randomId());
    this.canvas.add(add);
    this.selectItemAfterAdded(add);
  }

  /*Canvas*/

  cleanSelect() {
    this.canvas.discardActiveObject().renderAll();
  }

  selectItemAfterAdded(obj) {
    this.canvas.discardActiveObject().renderAll();
    this.canvas.setActiveObject(obj);
  }

  setCanvasFill(sourcebg = null) {
    if (!this.props.canvasImage) {
      // @ts-ignore
      this.builder.stickerDetails?.background = this.props.canvasFill;
      if (!this.cutOutline) {
        this.canvas.setBackgroundImage(
          null,
          this.canvas.renderAll.bind(this.canvas)
        );
        if (sourcebg == "child_sticker") {
          this.canvas.backgroundColor = this.props.canvasFill;
          this.canvas.renderAll();
        }

        const workspace = this.canvas
          .getObjects()
          .find((item: any) => item.id === "workspace");
        if (workspace) {
          workspace.set("fill", this.props.canvasFill);
          if (workspace.type === "rect") {
            workspace.set("backgroundColor", this.props.canvasFill);
          }
        }
        // this.canvas.backgroundColor = this.props.canvasFill;
        this.canvas.renderAll();
      } else {
        const setText =
          this.getLanguage === "en"
            ? "Applied successfully will be visible in final product!"
            : "Valittu onnistuneesti ja lisätään lopputuotteeseen!";
        this.matSnack.open(setText, "", {
          duration: 10000,
        });
      }
    }
  }

  extend(obj, id) {
    obj.toObject = ((toObject) => {
      return function () {
        return fabric.util.object.extend(toObject.call(this), {
          id,
        });
      };
    })(obj.toObject);
  }

  setCanvasImage() {
    console.log("test3 setCanvasImage");

    const self = this;
    if (this.props.canvasImage) {
      this.canvas.setBackgroundColor(
        new fabric.Pattern({
          source: this.props.canvasImage,
          repeat: "repeat",
        }),
        () => {
          self.props.canvasFill = "";
          self.canvas.renderAll();
        }
      );
    }
  }

  randomId() {
    return Math.floor(Math.random() * 999999) + 1;
  }

  /*------------------------Global actions for element------------------------*/

  getActiveStyle(styleName, object) {
    object = object || this.canvas.getActiveObject();
    if (!object) {
      return "";
    }

    if (object.getSelectionStyles && object.isEditing) {
      return object.getSelectionStyles()[styleName] || "";
    } else {
      return object[styleName] || "";
    }
  }

  setActiveStyle(
    styleName,
    value: string | number | boolean,
    object: ExtendedFabricText
  ) {
    object = object || (this.canvas.getActiveObject() as ExtendedFabricText);
    if (!object) {
      return;
    }
    // find duplicate object of active/selected object to apply same style on it
    let selectedDupText = this.canvas
      .getObjects()
      .find((item: ExtendedFabricText) => item.id === object.id + "_dup");
    let newselectedObjDupText = selectedDupText as ExtendedFabricText;
    if (object.setSelectionStyles && object.isEditing) {
      const style = {};
      style[styleName] = value;

      if (typeof value === "string") {
        if (value.includes("underline")) {
          object.setSelectionStyles({ underline: true });
          newselectedObjDupText.setSelectionStyles({ underline: true });
        } else {
          object.setSelectionStyles({ underline: false });
          newselectedObjDupText.setSelectionStyles({ underline: false });
        }

        if (value.includes("overline")) {
          object.setSelectionStyles({ overline: true });
          newselectedObjDupText.setSelectionStyles({ overline: true });
        } else {
          object.setSelectionStyles({ overline: false });
          newselectedObjDupText.setSelectionStyles({ overline: false });
        }

        if (value.includes("line-through")) {
          object.setSelectionStyles({ linethrough: true });
          newselectedObjDupText.setSelectionStyles({ linethrough: true });
        } else {
          object.setSelectionStyles({ linethrough: false });
          newselectedObjDupText.setSelectionStyles({ linethrough: false });
        }
      }

      object.setSelectionStyles(style);
      newselectedObjDupText.setSelectionStyles(style);
      object.setCoords();
      newselectedObjDupText.setCoords();
    } else {
      if (typeof styleName === "string") {
        if (styleName.includes("underline")) {
          if (object.underline == false) {
            object.set("underline", true);
            newselectedObjDupText.set("underline", true);
          } else {
            object.set("underline", false);
            newselectedObjDupText.set("underline", false);
          }
        }

        if (styleName.includes("overline")) {
          if (object.overline == false) {
            object.set("overline", true);
            newselectedObjDupText.set("overline", true);
          } else {
            object.set("overline", false);
            newselectedObjDupText.set("overline", false);
          }
        }

        if (styleName.includes("line-through")) {
          if (object.linethrough == false) {
            object.set("linethrough", true);
            newselectedObjDupText.set("linethrough", true);
          } else {
            object.set("linethrough", false);
            newselectedObjDupText.set("linethrough", false);
          }
        }
      }

      object.set(styleName, value);
      newselectedObjDupText.set(styleName, value);
    }

    newselectedObjDupText.setCoords();
    object.setCoords();
    this.canvas.renderAll();
  }

  getActiveProp(name) {
    const object = this.canvas.getActiveObject();
    if (!object) {
      return "";
    }

    return object[name] || "";
  }

  setActiveProp(name, value) {
    const object = this.canvas.getActiveObject() as ExtendedFabricText;
    if (!object) {
      return;
    }
    // find duplicate object of active/selected object to apply same style on it
    let newselectedObjDupText = this.canvas
      .getObjects()
      .find((item: ExtendedFabricText) => item.id === object.id + "_dup");

    setTimeout(() => {
      newselectedObjDupText.set(name, value).setCoords();
    }, 100);

    setTimeout(() => {
      object.set(name, value).setCoords();
      this.canvas.renderAll();
    }, 300);
  }

  clone() {
    const activeObject = this.canvas.getActiveObject();
    const activeGroup = this.canvas.getActiveObjects();

    if (activeObject) {
      let clone;
      switch (activeObject.type) {
        case "rect":
          clone = new fabric.Rect(activeObject.toObject());
          break;
        case "circle":
          clone = new fabric.Circle(activeObject.toObject());
          break;
        case "triangle":
          clone = new fabric.Triangle(activeObject.toObject());
          break;
        case "i-text":
          clone = new fabric.IText("", activeObject.toObject());
          break;
        case "image":
          clone = fabric.util.object.clone(activeObject);
          break;
      }
      if (clone) {
        clone.set({ left: 10, top: 10 });
        this.canvas.add(clone);
        this.selectItemAfterAdded(clone);
      }
    }
  }

  getId() {
    this.props.id = this.canvas.getActiveObject().toObject().id;
  }

  setId() {
    const val = this.props.id;
    const complete = this.canvas.getActiveObject().toObject();
    console.log(complete);
    this.canvas.getActiveObject().toObject = () => {
      complete.id = val;
      return complete;
    };
  }

  getOpacity() {
    this.props.opacity = this.getActiveStyle("opacity", null) * 100;
  }

  setOpacity() {
    this.setActiveStyle(
      "opacity",
      parseInt(this.props.opacity, 10) / 100,
      null
    );
  }

  getFill() {
    this.props.fill = this.getActiveStyle("fill", null);
  }

  setFill() {
    this.setActiveStyle("fill", this.props.fill, null);
  }

  getLineHeight() {
    this.props.lineHeight = this.getActiveStyle("lineHeight", null);
  }

  setLineHeight() {
    this.setActiveStyle("lineHeight", parseFloat(this.props.lineHeight), null);
  }

  getCharSpacing() {
    this.props.charSpacing = this.getActiveStyle("charSpacing", null);
  }

  setCharSpacing() {
    this.setActiveStyle("charSpacing", this.props.charSpacing, null);
  }

  getFontSize() {
    this.props.fontSize = this.getActiveStyle("fontSize", null);
  }

  setFontSize() {
    this.setActiveStyle("fontSize", parseInt(this.props.fontSize, 10), null);
  }

  getBold() {
    this.props.fontWeight = this.getActiveStyle("fontWeight", null);
  }

  setBold() {
    this.props.fontWeight = !this.props.fontWeight;
    this.setActiveStyle(
      "fontWeight",
      this.props.fontWeight ? "bold" : "",
      null
    );
  }

  setFontStyle() {
    this.props.fontStyle = !this.props.fontStyle;
    if (this.props.fontStyle) {
      this.setActiveStyle("fontStyle", "italic", null);
    } else {
      this.setActiveStyle("fontStyle", "normal", null);
    }
  }

  setTextDecoration(valueType, value: any) {
    var iclass = value;
    if (iclass.includes(value)) {
      iclass = iclass.replace(RegExp(value, "g"), "");
    } else {
      iclass += ` ${value}`;
    }
    if (value === "overline") {
      this.props.overline = !this.props.overline;
      this.setActiveStyle("overline", this.props.overline, null);
    }
    if (value === "underline") {
      this.props.underline = !this.props.underline;
      this.setActiveStyle("underline", this.props.underline, null);
    }
    if (value === "line-through") {
      this.props.linethrough = !this.props.linethrough;
      this.setActiveStyle("linethrough", this.props.linethrough, null);
    }
    this.setActiveStyle(valueType, value, null);
  }

  getTextAlign() {
    this.props.textAlign = this.getActiveProp("textAlign");
  }

  setTextAlign(value) {
    this.props.textAlign = value;
    this.setActiveProp("textAlign", this.props.textAlign);
  }

  /*System*/

  removeSelected() {
    const activeObject = this.canvas.getActiveObject();
    const activeGroup = this.canvas.getActiveObjects();

    if (activeObject) {
      this.canvas.remove(activeObject);
      // when deleting active object need to be delect duplicate object of active object
      this.callToSetPositionScale(activeObject, "remove");
      // this.textString = '';
    } else if (activeGroup) {
      this.canvas.discardActiveObject();
      const self = this;
      activeGroup.forEach((object) => {
        self.canvas.remove(object);
      });
    }
  }

  bringToFront() {
    const activeObject = this.canvas.getActiveObject();
    const activeGroup = this.canvas.getActiveObjects();

    if (activeObject) {
      activeObject.bringToFront();
      activeObject.opacity = 1;
    } else if (activeGroup) {
      this.canvas.discardActiveObject();
      activeGroup.forEach((object) => {
        object.bringToFront();
      });
    }
  }

  sendToBack() {
    const activeObject = this.canvas.getActiveObject();
    const activeGroup = this.canvas.getActiveObjects();

    if (activeObject) {
      this.canvas.sendToBack(activeObject);
      activeObject.sendToBack();
      activeObject.opacity = 1;
    } else if (activeGroup) {
      this.canvas.discardActiveObject();
      activeGroup.forEach((object) => {
        object.sendToBack();
      });
    }
  }

  confirmClear() {
    if (confirm("Are you sure to clear canvas?")) {
      this.canvas.clear();
    }
  }

  //removing last object from canvas
  undo() {
    if (this.canvas._objects?.length > 0) {
      const gotActiveObject = this.canvas.getActiveObject();
      if (gotActiveObject) {
        this.canvas.remove(gotActiveObject);
      } else {
        this.canvas._objects.pop();
      }
      this.canvas.renderAll();
    }
  }

  clear() {
    this.canvas.clear();
  }

  rasterize() {
    const image = new Image();
    image.src = this.canvas.toDataURL({ format: "png" });
    const w = window.open("");
    w.document.write(image.outerHTML);
  }

  rasterizeSVG() {
    const w = window.open("");
    w.document.write(this.canvas.toSVG());
    return "data:image/svg+xml;utf8," + encodeURIComponent(this.canvas.toSVG());
  }

  saveCanvasToJSON() {
    const json = JSON.stringify(this.canvas);
    localStorage.setItem("Kanvas", json);
    console.log("json");
    console.log(json);
  }

  loadCanvasFromJSON() {
    const CANVAS = localStorage.getItem("Kanvas");
    console.log("CANVAS");
    console.log(CANVAS);

    // and load everything from the same json
    this.canvas.loadFromJSON(CANVAS, () => {
      console.log("CANVAS untar");
      console.log(CANVAS);

      // making sure to render canvas at the end
      this.canvas.renderAll();

      // and checking if object's "name" is preserved
      console.log("this.canvas.item(0).name");
      console.log(this.canvas);
    });
  }

  rasterizeJSON() {
    this.json = JSON.stringify(this.canvas, null, 2);
  }

  resetPanels() {
    this.textEditor = false;
    this.imageEditor = false;
    this.figureEditor = false;
  }

  changeShape(type: number) {
    // console.log('Cut canvas to style');
    // if (type === 1) {
    //   this.renderer.addClass(document.getElementById('canvas'), 'square');
    //   this.renderer.removeClass(document.getElementById('canvas'), 'circle');
    //   this.renderer.removeClass(document.getElementById('canvas'), 'round-corner');
    //   this.renderer.removeClass(document.getElementById('canvas'), 'sheet');
    //
    // } else if (type === 2) {
    //   this.renderer.addClass(document.getElementById('canvas'), 'circle');
    //   this.renderer.removeClass(document.getElementById('canvas'), 'square');
    //   this.renderer.removeClass(document.getElementById('canvas'), 'round-corner');
    //   this.renderer.removeClass(document.getElementById('canvas'), 'sheet');
    // } else if (type === 5) {
    //
    // }
  }

  setBackgroundImage(url) {
    this.canvas.setBackgroundImage(
      url,
      this.canvas.renderAll.bind(this.canvas),
      {
        // Optionally add an opacity lvl to the image
        width: this.canvas.getWidth(),
        height: this.canvas.getHeight(),
        originX: "left",
        //   scaleX: this.canvas.getWidth() ,
        //   scaleY: this.canvas.getHeight(),
        originY: "top",
        //   backgroundImageOpacity: 0.5,
        //   // should the image be resized to fit the container?
        //backgroundImageStretch: true
      }
    );
  }

  setMatetial(url) {
    this.builder.stickerDetails.material = url;
  }

  setBackgroundImage1(url) {
    this.builder.stickerDetails.material = url;
    const self = this;
    if (!this.cutOutline || !this.builder.cutOutline) {
      fabric.Image.fromURL(
        AppConstants.BASE_URL + url.attributes.background.data.attributes.url,
        function (img) {
          self.canvas.setBackgroundImage(
            img,
            self.canvas.renderAll.bind(self.canvas),
            {
              // Optionally add an opacity lvl to the image
              // width: 1000,
              // height: 600,
            }
          );
        },
        {
          crossOrigin: "anonymous",
        }
      );
      // this.canvas.setBackgroundImage(
      //   AppConstants.BASE_URL + url.attributes.background.data.attributes.url,
      //   this.canvas.renderAll.bind(this.canvas),
      //   {
      //     // Optionally add an opacity lvl to the image
      //     // width: 1000,
      //     // height: 600,
      //   }
      // );
    } else {
      this.builder.showSuccess(
        "Material",
        "Material will be visible in final product"
      );
    }
  }

  // first attempt
  drawBg(img) {
    const ctx = this.canvas.getContext();
    // tslint:disable-next-line:one-variable-per-declaration prefer-const
    let dArr = [-1, -1, 0, -1, 1, -1, -1, 0, 1, 0, -1, 1, 0, 1, 1, 1], // offset array
      // tslint:disable-next-line:prefer-const
      s = 2, // thickness scale
      i = 0, // iterator
      // tslint:disable-next-line:prefer-const
      x = 5, // final position
      // tslint:disable-next-line:prefer-const
      y = 5;
    const images = new Image();
    images.src = img;

    // draw images at offsets from the array scaled by s
    for (; i < dArr.length; i += 2) {
      ctx.drawImage(images, x + dArr[i] * s, y + dArr[i + 1] * s);
    }

    // fill with color
    ctx.globalCompositeOperation = "source-in";
    ctx.fillStyle = "red";
    // @ts-ignore
    ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
  }

  //  second attempt

  async removeWhitePart(img): Promise<boolean> {
    // console.log('this is url for removal', img);

    return new Promise(async (resolve, reject) => {
      let transparent = false;
      let canvas: any;
      let ctx: any;
      canvas = document.createElement("canvas");
      ctx = canvas.getContext("2d");
      ctx.globalCompositeOperation = "destination-over";
      const image = new Image();
      image.crossOrigin = "anonymous";
      image.src = img;
      console.log("******************* img", img);
      image.onerror = (e) => {
        console.log("Error", e);
        // resolve(false);
      };
      image.onload = (e) => {
        console.log("resolve", e);

        console.log(
          "this is iamge height and width",
          image.height,
          image.width
        );
        canvas.height = image.height;
        canvas.width = image.width;
        ctx.fillRect(0, 0, canvas.height, canvas.width);
        ctx.drawImage(image, 0, 0);
        const imgd = ctx.getImageData(0, 0, canvas.height, canvas.width);
        const pix = imgd.data;
        console.log("these are pixels", pix);
        let alpha = 0;

        const data = ctx.getImageData(0, 0, canvas.width, canvas.height).data;
        let hasAlphaPixels = false;
        for (let i = 3, n = data.length; i < n; i += 4) {
          if (data[i] < 255) {
            hasAlphaPixels = true;
            break;
          }
        }

        console.log("Has alpha", hasAlphaPixels);
        // for (let i = 0, n = pix.length; i < n; i += 4) {
        //   let r: number;
        //   let g: number;
        //   let b: number;
        //   let a: number;
        //   r = pix[i];
        //   g = pix[i + 1];
        //   b = pix[i + 2];
        //   a = pix[i + 3];
        //
        //   if (a < 255) {
        //     alpha++;
        //   }
        // }
        // console.log('This is alpha', alpha);
        // // console.log('this is alpha value', (alpha / (pix.length / 4) * 100));
        // transparent = alpha > 0;

        resolve(hasAlphaPixels);
      };
    });
  }

  readUrl(event) {
    console.log("this is event2", event);
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (readerEvent) => {
        this.url = readerEvent.target.result;
        this.files = event.target.files;
        this.mFile = event.target.files[0];
        console.log("this is M FIle *************", this.url);
        // console.log('entered in if', this.files);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
    console.log("these are files", event);
  }

  resizeBase64Img = (base64, newWidth, newHeight) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = base64;
      img.crossOrigin = "anonymous";

      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = newWidth;
        canvas.height = newHeight;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, newWidth, newHeight);
        resolve(canvas.toDataURL("image/png"));
        // canvas.toBlob(
        //   (resizedBlob) => {
        //     resolve(resizedBlob);
        //   },
        //   "image/png",
        //   1.0
        // );
      };

      img.onerror = (err) => {
        reject(err);
      };
    });
  };

  /**
   * Call this function to save canvas as pdf
   */

  savePdf() {
    // this.sizeToCm();
    // convert to pdf
    // console.log('Timeout starts count upto 5 tiktok');
    // this.api.getOrder().subscribe(data => {
    //   console.log('Order data', data);
    // }, error => {
    //   console.log('Error', error);
    // });

    setTimeout(() => {
      // if (this.canvas.backgroundColor == "") {
      //   console.log("savePdf - in if - backgroundColor empty");
      //   let imageURL = "assets/bg-img/1.jpg";
      //   const image = new Image();
      //   image.crossOrigin = "anonymous";
      //   image.src = imageURL;
      //   const cm = 0.026458333;
      //   const mode = this.canvas.getWidth() > this.canvas.getHeight();
      //   const doc = new JSPDF(mode ? "l" : "p", "cm", [
      //     this.sizeInCm.width,
      //     this.sizeInCm.height,
      //   ]);
      //   doc.addImage(
      //     image,
      //     "png",
      //     0,
      //     0,
      //     this.sizeInCm.width,
      //     this.sizeInCm.height
      //   );
      //   const fd = new FormData(); // To carry on your data
      //   const files = doc.output("blob");
      //   // const blob = new Blob([files], {type: 'application/pdf'});
      //   const file = new File([files], "jai.pdf", {
      //     lastModified: 1534584790000,
      //     type: "application/pdf",
      //   });
      //   fd.append("files", file);
      //   console.log("savePdf - in if - backgroundColor empty - ", fd.get("files"));
      //   this.builder.finalOrder.pdf = fd;
      //   this.builder.stickerDetails.pdf = fd;
      //   this.router.navigateByUrl("/place-order?type=junior-sticker");

      //   // this.canvasArr.push({"data": this.canvas});
      //   // this.canvasArr.push({"url": this.clip});

      //   localStorage.setItem("canvasDetails", JSON.stringify(this.canvas));
      //   // localStorage.setItem("canvasSVGDetails", JSON.stringify(this.canvasArr));
      //   localStorage.setItem(
      //     "stickerData",
      //     JSON.stringify(this.builder.stickerDetails)
      //   );
      // }
      // when we select Diecut we are setting the canvas full width and height so the image can see a big
      // Adjusting canvas size ratio to new height width for the proper image to make an order pdf

      let ratio = this.canvas.getWidth() / this.canvas.getHeight();

      if (this.builder.stickerDetails.name.name === "Square") {
        const workspace = this.canvas
          .getObjects()
          .find((item: any) => item.id === "workspace");
        const { left, top, width, height, scaleX, scaleY } =
          workspace as fabric.Object;
        ratio = width / height;
        let newSizeInCmWidth = this.sizeInCm.width;
        let newSizeInCmHeight = this.sizeInCm.height;

        if (this.sizeInCm.height > this.sizeInCm.width) {
          newSizeInCmWidth = this.sizeInCm.width;
          newSizeInCmHeight = newSizeInCmWidth / ratio;
        } else {
          newSizeInCmHeight = this.sizeInCm.height;
          newSizeInCmWidth = newSizeInCmHeight * ratio;
        }

        const option = {
          name: "New Image",
          format: "png",
          quality: 1,
          width: width * scaleX,
          height: height * scaleY,
          left,
          top,
        };
        // this.canvas.setViewportTransform([scaleX, 0, 0, scaleY, 0, 0]);
        this.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
        let imageData = this.canvas.toDataURL(option);

        imageData = changeDpiDataUrl(imageData, 300);
        console.log("SavePdf - image data", imageData);
        // this.drawStickerBg(imageData);
        // const imageData = this.cropSavedPart();
        // let imageData = this.canvas.toDataURL();
        // console.log("image data********", imageData);
        // const dataUrl = this.canvas.toDataURL({
        //   quality: 1,
        //   format: "png",
        //   multiplier: 1,
        // });
        // console.log("Data URL", dataUrl);
        // this.drawStickerBg(dataUrl);
        const image = new Image();
        image.src = imageData;
        image.crossOrigin = "anonymous";
        const cm = 0.026458333;
        // const cm = 37.7952755906;

        let mode = width * scaleX > height * scaleY;

        console.log("I am here loading Image");
        // const w = window.open('');
        // w.document.write(image.outerHTML);
        const doc = new JSPDF(mode ? "l" : "p", "cm", [
          newSizeInCmWidth,
          newSizeInCmHeight,
        ]);

        let getDateTime = new Date().getTime();
        let randomNum = this.getRandomInt(0, 1000000);

        const fdpreview = new FormData();
        var previewblob = this.dataURItoBlob(imageData);
        const previewfile = new File(
          [previewblob],
          "SW_order" + randomNum + "" + getDateTime + ".png",
          previewblob
        );
        fdpreview.append("files", previewfile);
        this.builder.stickerDetails.pdfpreview = fdpreview;
        console.log("fdpreview" + this.builder.stickerDetails.pdfpreview);

        doc.addImage(image, "png", 0, 0, newSizeInCmWidth, newSizeInCmHeight);
        const fd = new FormData(); // To carry on your data
        const files = doc.output("blob");

        let dynamicName = "SW_order" + randomNum + "" + getDateTime + ".pdf";
        // const blob = new Blob([files], {type: 'application/pdf'});
        const file = new File([files], dynamicName, {
          lastModified: 1534584790000,
          type: "application/pdf",
        });
        console.log("these are files", files);
        fd.append("files", file);
        this.builder.finalOrder.pdf = fd;
        this.builder.stickerDetails.pdf = fd;
        console.log("this is fd", fd);
        console.log("savePdf - get fd - ", fd.get("files"));
        // doc.save();
        // this.builder.finalOrder.height = this.sizeInCm.height;
        // this.builder.finalOrder.width = this.sizeInCm.width;
        // this.api.uploadApi(fd).subscribe(data => {
        //   console.log('this is uploaded data', data);
        //   doc.save();
        // }, error => {
        //   console.log('An error occurred', error);
        // });
        console.log("savePdf - this is the doc", doc);
        // doc.save('saved');
        console.log("savePdf - saved success");
        console.log(
          "savePdf - this is sticker details",
          this.builder.stickerDetails
        );
        console.log("savePdf - this is final order", this.builder.finalOrder);
        this.router.navigateByUrl("/place-order?type=junior-sticker");
        localStorage.setItem("canvasDetails", JSON.stringify(this.canvas));
        localStorage.setItem(
          "stickerData",
          JSON.stringify(this.builder.stickerDetails)
        );
      } else if (this.builder.stickerDetails.name.name === "Round") {
        const workspace = this.canvas
          .getObjects()
          .find((item: any) => item.id === "workspace");
        // if (workspace instanceof fabric.Ellipse) {
        const { left, top, width, height, scaleX, scaleY, rx, ry } =
          workspace as ExtendedFabricEllipse;
        ratio = width / height;
        let newSizeInCmWidth = this.sizeInCm.width;
        let newSizeInCmHeight = this.sizeInCm.height;

        if (this.sizeInCm.height > this.sizeInCm.width) {
          newSizeInCmWidth = this.sizeInCm.width;
          newSizeInCmHeight = newSizeInCmWidth / ratio;
        } else {
          newSizeInCmHeight = this.sizeInCm.height;
          newSizeInCmWidth = newSizeInCmHeight * ratio;
        }

        workspace.strokeWidth = 2;
        workspace.stroke = "black";

        this.canvas.clipPath = workspace;
        const option = {
          name: "New Image",
          format: "png",
          quality: 1,
          width: width * scaleX,
          height: height * scaleY,
          left: (this.canvas.getWidth() - width * scaleX) / 2,
          top: (this.canvas.getHeight() - height * scaleY) / 2,
          rx: rx * scaleX,
          ry: ry * scaleY,
          strokeWidth: 2,
          stroke: "black",
        };

        // this.canvas.setViewportTransform([scaleX, 0, 0, scaleY, 0, 0]);
        this.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
        let imageData = this.canvas.toDataURL(option);

        imageData = changeDpiDataUrl(imageData, 300);
        console.log("SavePdf round - image data", imageData);
        this.canvas.clipPath = null;
        const image = new Image();
        image.src = imageData;
        image.crossOrigin = "anonymous";
        const cm = 0.026458333;
        // const cm = 37.7952755906;

        let mode = width * scaleX > height * scaleY;

        console.log("I am here loading Image");
        // const w = window.open('');
        // w.document.write(image.outerHTML);
        const doc = new JSPDF(mode ? "l" : "p", "cm", [
          newSizeInCmWidth,
          newSizeInCmHeight,
        ]);

        let getDateTime = new Date().getTime();
        let randomNum = this.getRandomInt(0, 1000000);

        const fdpreview = new FormData();
        var previewblob = this.dataURItoBlob(imageData);
        const previewfile = new File(
          [previewblob],
          "SW_order" + randomNum + "" + getDateTime + ".png",
          previewblob
        );
        fdpreview.append("files", previewfile);
        this.builder.stickerDetails.pdfpreview = fdpreview;
        console.log("fdpreview" + this.builder.stickerDetails.pdfpreview);

        doc.addImage(image, "png", 0, 0, newSizeInCmWidth, newSizeInCmHeight);
        const fd = new FormData(); // To carry on your data
        const files = doc.output("blob");

        let dynamicName = "SW_order" + randomNum + "" + getDateTime + ".pdf";
        // const blob = new Blob([files], {type: 'application/pdf'});
        const file = new File([files], dynamicName, {
          lastModified: 1534584790000,
          type: "application/pdf",
        });
        console.log("these are files", files);
        fd.append("files", file);
        this.builder.finalOrder.pdf = fd;
        this.builder.stickerDetails.pdf = fd;
        console.log("this is fd", fd);
        console.log("savePdf - get fd - ", fd.get("files"));
        // doc.save();
        // this.builder.finalOrder.height = this.sizeInCm.height;
        // this.builder.finalOrder.width = this.sizeInCm.width;
        // this.api.uploadApi(fd).subscribe(data => {
        //   console.log('this is uploaded data', data);
        //   doc.save();
        // }, error => {
        //   console.log('An error occurred', error);
        // });
        console.log("savePdf - this is the doc", doc);
        // doc.save('saved');
        console.log("savePdf - saved success");
        console.log(
          "savePdf - this is sticker details",
          this.builder.stickerDetails
        );
        console.log("savePdf - this is final order", this.builder.finalOrder);
        this.router.navigateByUrl("/place-order?type=junior-sticker");
        localStorage.setItem("canvasDetails", JSON.stringify(this.canvas));
        localStorage.setItem(
          "stickerData",
          JSON.stringify(this.builder.stickerDetails)
        );
      } else if (this.builder.stickerDetails.name.name === "nameTag") {
        const ratio = this.canvas.getWidth() / this.canvas.getHeight();
        const dpi = 37.7952755906; // Conversion factor from cm to pixels
        let newSizeInCmWidth = this.sizeInCm.width;
        let newSizeInCmHeight = this.sizeInCm.height;

        let newSizeInPxWidth = this.sizeInCm.width * dpi;
        let newSizeInPxHeight = this.sizeInCm.height * dpi;
        console.log(" size in cm", this.sizeInCm.width);
        console.log(" size in cm", this.sizeInCm.height);
        console.log(" size in PX ", newSizeInPxWidth);
        console.log(" size in PX ", newSizeInPxHeight);

        let imageData = this.canvas.toDataURL();
        const dataUrl = this.canvas.toDataURL({
          quality: 1,
          format: "jpeg",
          multiplier: 1,
        });
        console.log("Data URL", dataUrl);
        imageData = changeDpiDataUrl(imageData, 300);
        this.resizeBase64Img(imageData, newSizeInPxWidth, newSizeInPxHeight)
          .then((resizedBlob) => {
            console.log("Resized image", resizedBlob);
            var previewblob = this.dataURItoBlob(resizedBlob);
            // var previewblob = resizedBlob as Blob; // Explicitly cast to Blob
            let getDateTime = new Date().getTime();
            let randomNum = this.getRandomInt(0, 1000000);

            const fdpreview = new FormData();
            const previewfile = new File(
              [previewblob],
              "SW_order" + randomNum + "" + getDateTime + ".png",
              previewblob
            );
            fdpreview.append("files", previewfile);
            // Now you can use previewfile as needed
            this.builder.stickerDetails.pdfpreview = fdpreview;

            // this.builder.stickerDetails.pdfpreview = fdpreview;

            console.log("SavePdf - image data", imageData);
            // const imageData = this.cropSavedPart();
            // console.log('image data********', imageData);

            const image = new Image();
            image.src = imageData;
            image.crossOrigin = "anonymous";
            const cm = 0.026458333;
            // const cm = 37.7952755906;
            const mode = this.canvas.getWidth() > this.canvas.getHeight();
            console.log("I am here loading Image");
            // const w = window.open('');
            // w.document.write(image.outerHTML);
            const doc = new JSPDF(mode ? "l" : "p", "cm", [
              newSizeInCmWidth,
              newSizeInCmHeight,
            ]);

            // const fdpreview = new FormData();
            // var previewblob = this.dataURItoBlob(imageData);
            // const previewfile = new File(
            //   [previewblob],
            //   "SW_order" + randomNum + "" + getDateTime + ".png",
            //   previewblob
            // );
            // fdpreview.append("files", previewfile);
            // this.builder.stickerDetails.pdfpreview = fdpreview;
            // console.log("fdpreview" + this.builder.stickerDetails.pdfpreview);
            console.log("final this is image", image);

            doc.addImage(
              image,
              "png",
              0,
              0,
              newSizeInCmWidth,
              newSizeInCmHeight
            );
            const fd = new FormData(); // To carry on your data
            const files = doc.output("blob");
            let dynamicName =
              "SW_order" + randomNum + "" + getDateTime + ".pdf";
            // const blob = new Blob([files], {type: 'application/pdf'});
            const file = new File([files], dynamicName, {
              lastModified: 1534584790000,
              type: "application/pdf",
            });
            console.log("these are files", files);
            fd.append("files", file);
            this.builder.finalOrder.pdf = fd;
            this.builder.stickerDetails.pdf = fd;
            console.log("this is fd", fd);
            console.log("savePdf - get fd - ", fd.get("files"));
            // doc.save();
            // this.builder.finalOrder.height = this.sizeInCm.height;
            // this.builder.finalOrder.width = this.sizeInCm.width;
            // this.api.uploadApi(fd).subscribe(data => {
            //   console.log('this is uploaded data', data);
            //   doc.save();
            // }, error => {
            //   console.log('An error occurred', error);
            // });
            console.log("savePdf - this is the doc", doc);
            // doc.save('saved');
            console.log("savePdf - saved success");
            console.log(
              "savePdf - this is sticker details",
              this.builder.stickerDetails
            );
            console.log(
              "savePdf - this is final order",
              this.builder.finalOrder
            );

            this.router.navigateByUrl("/place-order?type=junior-sticker");
            localStorage.setItem("canvasDetails", JSON.stringify(this.canvas));
            localStorage.setItem(
              "stickerData",
              JSON.stringify(this.builder.stickerDetails)
            );
          })
          .catch((err) => {
            console.error("Error resizing image:", err);
          });
      } else {
        const workspace = this.canvas
          .getObjects()
          .find((item: any) => item.id === "workspace");
        const { left, top, width, height, scaleX, scaleY } =
          workspace as fabric.Object;
        let imageRatio = width / height;

        let newSizeInCmWidth = this.sizeInCm.width;
        let newSizeInCmHeight = this.sizeInCm.height;

        if (this.sizeInCm.height > this.sizeInCm.width) {
          newSizeInCmWidth = this.sizeInCm.width;
          newSizeInCmHeight = newSizeInCmWidth / imageRatio;
        } else {
          newSizeInCmHeight = this.sizeInCm.height;
          newSizeInCmWidth = newSizeInCmHeight * imageRatio;
        }

        // this.canvas.setBackgroundColor(
        //   "transparent",
        //   this.canvas.renderAll.bind(this.canvas)
        // );
        const option = {
          name: "New Image",
          format: "png",
          quality: 1,
          width: width * scaleX,
          height: height * scaleY,

          left: (this.canvas.getWidth() - width * scaleX) / 2,
          top: (this.canvas.getHeight() - height * scaleY) / 2,
        };

        // this.canvas.setViewportTransform([scaleX, 0, 0, scaleY, 0, 0]);
        this.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
        let imageData = this.canvas.toDataURL(option);
        const dataUrl = this.canvas.toDataURL({
          quality: 1,
          format: "png",
          multiplier: 1,
        });
        console.log("Data URL", dataUrl);
        imageData = changeDpiDataUrl(imageData, 300);
        console.log("SavePdf - image data", imageData);

        // const imageData = this.cropSavedPart();
        // console.log('image data********', imageData);

        const image = new Image();
        image.src = imageData;
        image.crossOrigin = "anonymous";
        const cm = 0.026458333;
        // const cm = 37.7952755906;
        const mode = width > height;
        console.log("test diecut mode ", mode);
        console.log("I am here loading Image");
        // const w = window.open('');
        // w.document.write(image.outerHTML);

        let getDateTime = new Date().getTime();
        let randomNum = this.getRandomInt(0, 1000000);

        const fdpreview = new FormData();
        var previewblob = this.dataURItoBlob(imageData);
        const previewfile = new File(
          [previewblob],
          "SW_order" + randomNum + "" + getDateTime + ".png",
          previewblob
        );
        fdpreview.append("files", previewfile);
        this.builder.stickerDetails.pdfpreview = fdpreview;
        console.log("fdpreview" + this.builder.stickerDetails.pdfpreview);

        const doc = new JSPDF(mode ? "l" : "p", "cm", [
          newSizeInCmWidth,
          newSizeInCmHeight,
        ]);
        doc.addImage(image, "png", 0, 0, newSizeInCmWidth, newSizeInCmHeight);
        const fd = new FormData(); // To carry on your data
        const files = doc.output("blob");
        let dynamicName = "SW_order" + randomNum + "" + getDateTime + ".pdf";
        // const blob = new Blob([files], {type: 'application/pdf'});
        const file = new File([files], dynamicName, {
          lastModified: 1534584790000,
          type: "application/pdf",
        });
        console.log("these are files", files);
        fd.append("files", file);
        this.builder.finalOrder.pdf = fd;
        this.builder.stickerDetails.pdf = fd;
        console.log("this is fd", fd);
        console.log("savePdf - get fd - ", fd.get("files"));
        // doc.save();
        // this.builder.finalOrder.height = this.sizeInCm.height;
        // this.builder.finalOrder.width = this.sizeInCm.width;
        // this.api.uploadApi(fd).subscribe(data => {
        //   console.log('this is uploaded data', data);
        //   doc.save();
        // }, error => {
        //   console.log('An error occurred', error);
        // });
        console.log("savePdf - this is the doc", doc);
        // doc.save('saved');
        console.log("savePdf - saved success");
        console.log(
          "savePdf - this is sticker details",
          this.builder.stickerDetails
        );
        console.log("savePdf - this is final order", this.builder.finalOrder);

        this.router.navigateByUrl("/place-order?type=junior-sticker");
        localStorage.setItem("canvasDetails", JSON.stringify(this.canvas));
        localStorage.setItem(
          "stickerData",
          JSON.stringify(this.builder.stickerDetails)
        );
      }
    }, 1000);
  }
  dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0)
      byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }
  getRandomInt(min, max) {
    const minCeiled = Math.ceil(min);
    const maxFloored = Math.floor(max);
    return Math.floor(Math.random() * (maxFloored - minCeiled) + minCeiled); // The maximum is exclusive and the minimum is inclusive
  }

  changeUnit(px) {
    return (px / 96).toFixed(2);
  }

  canvasResizeFromInput(event, stopCanvasResizeFromInput) {
    console.log("canvasResizeFromInput - event", event);
    // console.log('this is container height', this.containerHeight);
    // console.log('this is container width', this.containerWidth / 37.7952755906);
    // // First check bounds
    // const conditionBounds = (event.width < this.containerWidth) && (event.height < this.containerHeight);
    // if (conditionBounds) {
    //   this.canvas.setDimensions({width: event.width, height: event.height});
    //
    //   //  Do nothing if bounds condition meets
    // } else {
    //   //  Change canvas size if condition doesn't meet
    //   //  1. Find ratio of new size
    //   const newSizeRatio = this.calculateRatio(event.width, event.height);
    //   const aspect = newSizeRatio.width / newSizeRatio.height;
    //   let resizedWidth = event.width / aspect;
    //   let resizedHeight = event.height / aspect;
    //   const condition = (resizedHeight < this.containerHeight) && (resizedWidth < this.containerWidth);
    //   console.log('this is condition', condition);
    //   // for (let i = 0; condition; i++) {
    //   //   resizedWidth = resizedWidth +  37.7952755906;
    //   //   resizedHeight = resizedHeight +  37.7952755906;
    //   //   console.log('this is condition', condition);
    //   // }
    //
    //   while ((resizedHeight < this.containerHeight) && (resizedWidth < this.containerWidth)) {
    //     console.log('this is runnung height', resizedHeight / 37.7952755906);
    //     console.log('this is runnung width', resizedWidth / 37.7952755906);
    //     resizedWidth++;
    //     resizedHeight++;
    //   }
    //   console.log('this is resized', resizedHeight / 37.7952755906, resizedWidth / 37.7952755906);
    //   this.canvas.setDimensions({width: resizedWidth, height: resizedHeight});
    //   console.log('this is new size ratio', newSizeRatio);
    // }

    // console.log('Calling to resize', event);
    // const outerCanvasContainer = document.getElementById('canvas');

    // const ratio = this.canvas.getWidth() / this.canvas.getHeight();
    // const containerWidth = event.width;
    // const scale = event.width / this.canvas.getWidth();
    // const zoom = this.canvas.getZoom() * scale;
    // // console.log('this is ratio', ratio, containerWidth, scale, zoom);
    // // console.log('changing size', event.width);
    // // this.canvas.setWidth(event.width);
    // // this.size.width = event.width;
    // // this.size.height = containerWidth / ratio;
    // // this.changeSize();
    // this.canvas.setHeight(containerWidth / ratio);
    // this.canvas.setWidth(event.width);
    // this.sizeInCm.width = Math.round(event.width / 37.7952755906);
    // this.sizeInCm.height = Math.round((containerWidth / ratio) / 37.7952755906);
    // this.canvas.setDimensions({width: event.width, height: containerWidth / ratio});
    // this.canvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0]);
    console.log("oldEventWidth - ", this.oldEventWidth);
    console.log("oldEventHeight - ", this.oldEventHeight);

    // check if the event is triggered already
    if (
      this.oldEventWidth === event.width &&
      this.oldEventHeight === event.height &&
      stopCanvasResizeFromInput
    ) {
      return;
    } else {
      this.oldEventWidth = event.width;
      this.oldEventHeight = event.height;
    }

    const scaleX = this.containerWidth / event.width;
    const scaleY = this.containerHeight / event.height;
    console.log(" --this.containerWidth ", this.containerWidth);
    console.log(" --this.containerHeight ", this.containerHeight);

    const scale = Math.min(scaleX, scaleY);

    if (this.builder.stickerDetails.name.name === "Square") {
      console.log("call if square");
      this.canvasResizeChange.width = (event.width - 10) * scale;
      this.canvasResizeChange.height = (event.height - 10) * scale;
      this.squreCanvasResizeInput(event, scale);
    } else if (this.builder.stickerDetails.name.name === "Round") {
      this.canvasResizeChange.width = (event.width - 10) * scale;
      this.canvasResizeChange.height = (event.height - 10) * scale;
      this.roundCanvasResizeInput(event, scale);
    } else {
      this.canvasResizeChange.width = event.width * scale;
      this.canvasResizeChange.height = event.height * scale;
      console.log(" call else cutting or round");

      // Store the old canvas dimensions
      let oldWidth = this.canvas.getWidth();
      let oldHeight = this.canvas.getHeight();

      this.canvas.setDimensions({
        width: event.width * scale,
        height: event.height * scale,
      });

      // Calculate the scale factor based on the smallest dimension
      let newScale =
        this.canvas.getWidth() < this.canvas.getHeight()
          ? (event.width * scale) / oldWidth
          : (event.height * scale) / oldHeight;

      // Iterate over all objects (including images) in the canvas
      this.canvas.forEachObject((obj) => {
        console.log("canvas - obj.type - ", obj.type);

        // Calculate the new scale while maintaining the aspect ratio
        if (obj.type === "image") {
          let aspectRatio = obj.width / obj.height;
          let newWidth = newScale * obj.width;
          let newHeight = newWidth / aspectRatio;

          // if canvas width is smaller than canvas height set new width accordingly
          if (this.canvas.getHeight() > this.canvas.getWidth()) {
            // If the new width is greater than the canvas width, adjust it accordingly
            if (newWidth > this.canvas.getWidth()) {
              newWidth = this.canvas.getWidth();
              newHeight = newWidth / aspectRatio;
              // if newHeight is greater than canvas height, adjust it accordingly
              if (newHeight > this.canvas.getHeight()) {
                newHeight = this.canvas.getHeight();
                newWidth = newHeight * aspectRatio;
              }
            }
            // If the new width is less than the canvas width, adjust it
            // else if (newWidth < this.canvas.getWidth()) {
            //   debugger;
            //   newWidth = this.canvas.getWidth();
            //   newHeight = newWidth / aspectRatio;
            //   if (newHeight > this.canvas.getHeight()) {
            //     debugger;
            //     newHeight = this.canvas.getHeight();
            //     newWidth = newHeight * aspectRatio;
            //   }
            // }
          } else {
            // If the new height is greater than the canvas height, adjust it accordingly
            if (newHeight > this.canvas.getHeight()) {
              newHeight = this.canvas.getHeight();
              newWidth = newHeight * aspectRatio;
              // if newWidth is greater than canvas width, adjust it accordingly
              if (newWidth > this.canvas.getWidth()) {
                newWidth = this.canvas.getWidth();
                newHeight = newWidth / aspectRatio;
              }
            }
            // If the new height is less than the canvas height, adjust it
            // else if (newHeight < this.canvas.getHeight()) {
            //   debugger;
            //   newHeight = this.canvas.getHeight();
            //   newWidth = newHeight * aspectRatio;
            //   if (newWidth > this.canvas.getWidth()) {
            //     debugger;
            //     newWidth = this.canvas.getWidth();
            //     newHeight = newWidth / aspectRatio;
            //   }
            // }
          }

          // Set the new scale
          obj.scaleX = newWidth / obj.width;
          obj.scaleY = newHeight / obj.height;
        }

        // the objects reposition it according to the size of the new canvas
        obj.left = obj.left * (this.canvas.getWidth() / oldWidth);
        obj.top = obj.top * (this.canvas.getHeight() / oldHeight);
        // Calculate the new position
        // let newLeft = obj.left * (this.canvas.getWidth() / oldWidth);
        // let newTop = obj.top * (this.canvas.getHeight() / oldHeight);

        // // If the image width is equal to the canvas width, set the left position to 0
        // if (obj.getScaledWidth() === this.canvas.getWidth()) {
        //   obj.left = 0;
        // }
        // // Otherwise, check if the new left position is within the canvas dimensions
        // else if (newLeft + obj.getScaledWidth() <= this.canvas.getWidth()) {
        //   obj.left = newLeft;
        // }

        // // If the image height is equal to the canvas height, set the top position to 0
        // if (obj.getScaledHeight() === this.canvas.getHeight()) {
        //   obj.top = 0;
        // }
        // // Otherwise, check if the new top position is within the canvas dimensions
        // else if (newTop + obj.getScaledHeight() <= this.canvas.getHeight()) {
        //   obj.top = newTop;
        // }
        // Set the object's coordinates
        obj.setCoords();
      });
      this.canvas.renderAll();
    }

    // Render the canvas again to reflect the changes
  }
  roundCanvasResizeInput(event, scale) {
    const workspace = this.canvas
      .getObjects()
      .find((item: any) => item.id === "workspace");

    if (workspace instanceof fabric.Ellipse) {
      workspace.width = (event.width - 10) * scale;
      workspace.height = (event.height - 10) * scale;
      workspace.rx = ((event.width - 10) * scale) / 2;
      workspace.ry = ((event.height - 10) * scale) / 2;
    }
    this.canvas.renderAll();
  }

  squreCanvasResizeInput(event, scale) {
    const workspace = this.canvas
      .getObjects()
      .find((item: any) => item.id === "workspace");
    // Store the old canvas dimensions
    // let oldWidth = this.canvas.getWidth();
    // let oldHeight = this.canvas.getHeight();
    let oldWidth = workspace.width;
    let oldHeight = workspace.height;

    workspace.width = (event.width - 10) * scale;
    workspace.height = (event.height - 10) * scale;
    //  this.canvas.setDimensions({
    //   width: event.width * scale,
    //   height: event.height * scale,
    // });
    console.log("workspace.width - ", workspace.width);
    console.log("workspace.height - ", workspace.height);
    setTimeout(() => {
      workspace.centerH();
      workspace.centerV();
      workspace.setCoords;
      this.canvas.renderAll();
    }, 10);
    // Calculate the scale factor based on the smallest dimension
    let newScale =
      workspace.width < workspace.height
        ? (event.width * scale) / oldWidth
        : (event.height * scale) / oldHeight;

    // Iterate over all objects (including images) in the canvas
    this.canvas.forEachObject((obj) => {
      console.log("canvas - obj.type - ", obj.type);

      // Calculate the new scale while maintaining the aspect ratio
      if (obj.type === "image") {
        let aspectRatio = obj.width / obj.height;
        let newWidth = newScale * obj.width;
        let newHeight = newWidth / aspectRatio;

        // if canvas width is smaller than canvas height set new width accordingly
        if (workspace.height > workspace.width) {
          // If the new width is greater than the canvas width, adjust it accordingly
          if (newWidth > workspace.width) {
            newWidth = workspace.width;
            newHeight = newWidth / aspectRatio;
            // if newHeight is greater than canvas height, adjust it accordingly
            if (newHeight > workspace.height) {
              newHeight = workspace.height;
              newWidth = newHeight * aspectRatio;
            }
          }

          // }
        } else {
          // If the new height is greater than the canvas height, adjust it accordingly
          if (newHeight > workspace.height) {
            newHeight = workspace.height;
            newWidth = newHeight * aspectRatio;
            // if newWidth is greater than canvas width, adjust it accordingly
            if (newWidth > workspace.width) {
              newWidth = workspace.width;
              newHeight = newWidth / aspectRatio;
            }
          }
        }

        // Set the new scale
        obj.scaleX = newWidth / obj.width;
        obj.scaleY = newHeight / obj.height;
      }

      // Calculate the new position of the object relative to the center of the canvas
      const oldCenterX = oldWidth / 2;
      const oldCenterY = oldHeight / 2;
      const newCenterX = workspace.width / 2;
      const newCenterY = workspace.height / 2;

      const deltaX = obj.left - oldCenterX;
      const deltaY = obj.top - oldCenterY;
      //  const deltaX = (obj.left - oldCenterX) / oldWidth;
      //  const deltaY = (obj.top - oldCenterY) / oldHeight;

      obj.left = newCenterX + deltaX * (workspace.width / oldWidth);
      obj.top = newCenterY + deltaY * (workspace.height / oldHeight);
      // obj.left = newCenterX + deltaX * workspace.width;
      // obj.top = newCenterY + deltaY * workspace.height;

      // Set the object's coordinates
      obj.setCoords();
    });
    this.canvas.renderAll();
  }
  sampleResize() {
    this.canvas.setWidth(500);
  }

  changeOutlineCanvas() {
    // // console.log('***************************************', this.builder.stickerDetails?.name?.name);
    // if (this.builder.stickerDetails?.name?.name === 'Cutting') {
    //   // if (this.cutOutline) {
    //   setTimeout(() => {
    //     return 'non-bordered-canvas';
    //   }, 300);
    //
    // } else if (this.builder.stickerDetails?.name?.name === 'Round') {
    //   setTimeout(() => {
    //     this.canvas.backgroundColor = '#ebebeba6';
    //     this.canvas.renderAll();
    //   }, 300);
    //   return 'bordered-canvas circle';
    //
    // } else {
    //   return 'bordered-canvas';
    //
    // }
  }

  growSize(grow: number) {
    const time = new Date().getTime();
    console.log("Stateted?", time);
    setTimeout(() => console.log("All bad!", new Date().getTime() - time), 10);
    this.builder.disableBtn = true;
    this.canvas.clear();
    if (this.grow > 120) {
      this.builder.showError("Error", "Cannot increase further");
      this.drawStickerBg(this.src, 120);
    } else if (this.grow < 30) {
      this.drawStickerBg(this.src, 30);
      this.builder.showError("Error", "Cannot decrease further");
    } else if (this.grow >= 30 && this.grow <= 120) {
      this.grow = grow * 30;
      this.drawStickerBg(this.src, this.grow);
    }
    this.builder.disableBtn = false;
  }
  changeCanvasShape(type, index, cutStyle) {
    const changeInShape =
      this.getLanguage === "en" ? "Change in shape" : "Leikkaus";
    const ShapeChanged =
      this.getLanguage === "en" ? "Shape Changed" : "Muoto muuttunut";

    const ShapeChangedSquare =
      this.getLanguage === "en"
        ? "Shape Changed to Square"
        : "Leikkaus muutettu suorakulmaksi";

    const ShapeChangedCircle =
      this.getLanguage === "en"
        ? "Shape changed to circle"
        : "Leikkaus muutettu pyöreäksi";
    for (const item of cutStyle) {
      // @ts-ignore
      item.value === type ? (item.selected = true) : (item.selected = false);
    }
    let shadow = new fabric.Shadow({
      color: "rgba(0, 0, 0, 0.09)",
      blur: 10,
      offsetX: 0,
      offsetY: 0,
    });
    const oldWorkspace = this.canvas
      .getObjects()
      .find((item: any) => item.id === "workspace");
    // Get the current dimensions of the workspace
    let workspaceWidth = this.canvasResizeChange.width;
    let workspaceHeight = this.canvasResizeChange.height;
    let workspaceIndex = 0;

    if (oldWorkspace) {
      workspaceWidth = oldWorkspace.width;
      workspaceHeight = oldWorkspace.height;
      workspaceIndex = this.canvas.getObjects().indexOf(oldWorkspace);

      // Remove the existing workspace from the canvas
      this.canvas.remove(oldWorkspace);
    }
    this.cutOutline = false;
    this.builder.cutOutline = false;
    // Cut to outline shape
    if (type === "5") {
      let imageUrl = "";

      this.canvas.getObjects().forEach((item) => {
        // if (item.type === "image") {
        let newItem = item as ExtendedFabricObject;
        // In all canvas object if any object has id includes '_dup' then skip this iteration so real object will be considered for diecut image
        if (
          newItem.id &&
          typeof newItem.id === "string" &&
          newItem.id.includes("_dup")
        ) {
          return; // Skip this iteration if id includes '_dup'
        }
        // Check if the current object has the largest area to be diecut image
        if (
          this.largestImageObjectToDiecut === null ||
          newItem.width * newItem.scaleX * (newItem.height * newItem.scaleY) >
            this.largestImageObjectToDiecut.width *
              this.largestImageObjectToDiecut.scaleX *
              (this.largestImageObjectToDiecut.height *
                this.largestImageObjectToDiecut.scaleY)
        ) {
          this.largestImageObjectToDiecut = newItem;
        }
        // }
      });
      this.largestImageObjectToDiecut.set({ clipPath: undefined });
      if (this.largestImageObjectToDiecut !== null) {
        imageUrl = this.largestImageObjectToDiecut.toDataURL();

        let diecutImageDuplicateId =
          this.largestImageObjectToDiecut.id + "_dup";
        // find the duplicate object of the largest image object
        let diecutImageDuplicateObject = this.canvas
          .getObjects()
          .find((item: any) => item.id === diecutImageDuplicateId);
        // remove the largest image object from the canvas
        this.canvas.remove(this.largestImageObjectToDiecut);
        // also remove the duplicate object of the largest image object
        this.canvas.remove(diecutImageDuplicateObject);
      }
      console.log(
        "diecut this.largestImageObjectToDiecut ",
        this.largestImageObjectToDiecut
      );
      console.log("diecut imageUrl ", imageUrl);

      const fd = new FormData();
      const img = document.createElement("img");
      var startTime = performance.now();
      img.src = imageUrl;
      // When the image has loaded, resize it
      img.onload = () => {
        // Create a canvas and get its context
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const ratio = img.width / img.height;
        console.log("call img.width img.height - ", img.width, img.height);

        let newHeight = img.height;
        let newWidth = img.width;

        if (img.height > 1000) {
          newHeight = 1000;
          newWidth = newHeight * ratio;
        } else if (img.width > 1000) {
          newWidth = 1000;
          newHeight = newWidth / ratio;
        }
        // Set the canvas dimensions to the desired size
        canvas.width = newWidth;
        canvas.height = newHeight;
        console.log(
          "call canvas.width canvas.height - ",
          canvas.width,
          canvas.height
        );
        // Draw the image onto the canvas
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        // Convert the canvas back to a Blob
        canvas.toBlob((blob) => {
          // Create a new File object with the same name as the original file
          const files = new File([blob], this.mFile.name, {
            type: "image/png",
          });
          console.log("call files", files);

          // Now you can use this newFile object
          // Append the file to the FormData instance
          fd.append("files", files);

          var endTime = performance.now();
          console.log(
            `Call to removeBgApi-png resize took ${
              endTime - startTime
            } milliseconds`
          );
          this.builder.loadingCanvas = true;
          // Upload pdf file to get its Link
          this.api.uploadApi(fd).subscribe(
            (data) => {
              this.builder.loadingCanvas = true;
              const body = {
                link: AppConstants.BASE_URL + data[0].url,
              };
              var endTime = performance.now();
              console.log(
                `Call to removeBgApi-uploadApi took ${
                  endTime - startTime
                } milliseconds`
              );
              // Get PNG file from uploaded PDF
              this.api.removeBg(body).subscribe(
                (bgImage: any) => {
                  if (bgImage.result_b64) {
                    const base64 =
                      this.builder.extension === "svg"
                        ? "data:image/svg+xml;base64," + bgImage.result_b64
                        : "data:image/png;base64," + bgImage.result_b64;
                    this.src = base64;
                    console.log(
                      "Calling sticker BG extension ",
                      this.extension,
                      this.builder.extension
                    );
                    this.drawStickerBg(base64);
                    var endTime = performance.now();
                    console.log(
                      `Call to removeBgApi-removeBg took ${
                        endTime - startTime
                      } milliseconds`
                    );
                    this.builder.loadingCanvas = false;
                    delete this.builder.fileEvent;
                  } else {
                    this.builder.loadingCanvas = false;
                    this.builder.showError(
                      "Error",
                      "Unable to load this image"
                    );
                    delete this.builder.fileEvent;
                    this.router.navigateByUrl("/");
                  }
                },
                (error) => {
                  console.log("this is error pdf", error);
                  this.builder.loadingCanvas = false;
                  delete this.builder.fileEvent;
                }
              );
            },
            (error) => {
              console.log("An error occurred", error);
              this.builder.loadingCanvas = false;
              delete this.builder.fileEvent;
            }
          );
        }, "image/png");
      };
      this.builder.loadingCanvas = false;
      console.log("log img  - ", img);

      // if (this.builder.stickerDetails.fileEvent) {

      // this.builder.canvas.removeBgApi();
      // this.renderer.removeClass(
      //   document.getElementById("canvas-cont"),
      //   "fab-canvas-cont-white"
      // );

      // this.renderer.removeClass(
      //   document.getElementsByClassName("upper-canvas")[0],
      //   "bordered-canvas"
      // );
      // this.renderer.removeClass(
      //   document.getElementsByClassName("lower-canvas")[0],
      //   "bordered-canvas"
      // );
      // this.renderer.addClass(
      //   document.getElementById("canvas-cont"),
      //   "fab-canvas-cont-grey"
      // );
      this.builder.cutStyle = cutStyle[index];
      this.builder.stickerDetails.name = cutStyle[index];
      // this.builder.canvas.clear();
      this.builder.showSuccess(changeInShape, ShapeChanged);
      // } else {
      //   this.builder.showError('Add image', 'Add image for cutting');
      //   this.builder.cutOutline = false;
      // }
      this.sheet.dismiss();
      return;
    } else if (type === "1") {
      console.log("Changing shape square");
      this.builder.showSuccess(changeInShape, ShapeChangedSquare);
      // if (this.builder.cutOutline) {
      //   this.builder.cutOutline = false;
      //   // this.builder.canvas.clear();
      // this.builder.canvas.setImageOnChangeSHape();
      //   this.builder.stickerDetails.fileEvent = undefined;
      // }

      // ExtendedFabricEllipse;
      // Create a new rectangle workspace
      const workspace = new ExtendedFabricRect({
        width: workspaceWidth,
        height: workspaceHeight,
        fill: "rgba(255,255,255,1)",
        originX: "center",
        originY: "center",
        shadow,
        rx: 0,
        absolutePositioned: true,
        ry: 0,
      });
      workspace.id = "workspace";
      workspace.set("selectable", false);
      workspace.set("hasControls", false);
      workspace.hoverCursor = "default";
      this.canvas.centerObject(workspace);
      // Add the new rectangle to the canvas at the same position as the removed workspace
      this.canvas.insertAt(workspace, workspaceIndex, false);
      // Add the new workspace object to the canvas
      let clonedObjectDuplicat;
      if (this.largestImageObjectToDiecut !== null) {
        // Clone the largestImageObjectToDiecut
        clonedObjectDuplicat = fabric.util.object.clone(
          this.largestImageObjectToDiecut
        );

        // Set a new id for the cloned object
        clonedObjectDuplicat.set({
          opacity: 0.5,
          selectable: false,
          id: this.largestImageObjectToDiecut.id + "_dup",
          clipPath: undefined,
        });

        // Add the cloned object to the canvas
        this.canvas.add(clonedObjectDuplicat);
        this.largestImageObjectToDiecut.set({
          clipPath: workspace,
        });

        this.canvas.add(this.largestImageObjectToDiecut);
      }
      // set new canvas workspace as clip path for all objects
      this.setClipPathForObjects(workspace);
      this.canvas.renderAll();
      this.builder.cutOutline = false;
      this.builder.cutStyle = cutStyle[index];
      this.builder.stickerDetails.name = cutStyle[index];
      this.changeSizeInCm(1);
      this.changeSizeInCm(2);
      if (
        this.largestImageObjectToDiecut.id === "workspace_sw" &&
        clonedObjectDuplicat &&
        this.largestImageObjectToDiecut
      ) {
        this.canvas.centerObject(clonedObjectDuplicat);
        this.canvas.centerObject(this.largestImageObjectToDiecut);
        this.largestImageObjectToDiecut = null;
      }
      if (this.largestImageObjectToDiecut !== null) {
        this.largestImageObjectToDiecut = null;
      }
      // this.setImageOnChangeSHape();
    } else if (type === "2") {
      console.log("Changing shape Round");
      const workspace = new ExtendedFabricEllipse({
        width: workspaceWidth,
        height: workspaceHeight,
        fill: "rgba(255,255,255,1)",
        shadow,
        originX: "center",
        originY: "center",
        rx: workspaceWidth / 2,
        ry: workspaceHeight / 2,
        absolutePositioned: true,
      });
      workspace.id = "workspace";
      workspace.set("selectable", false);
      workspace.set("hasControls", false);
      workspace.hoverCursor = "default";
      this.canvas.centerObject(workspace);
      let clonedObjectDuplicat;
      // Add the new rectangle to the canvas at the same position as the removed workspace
      this.canvas.insertAt(workspace, workspaceIndex, false);
      if (this.largestImageObjectToDiecut !== null) {
        // Clone the largestImageObjectToDiecut
        clonedObjectDuplicat = fabric.util.object.clone(
          this.largestImageObjectToDiecut
        );

        // Set a new id for the cloned object
        clonedObjectDuplicat.set({
          opacity: 0.5,
          id: this.largestImageObjectToDiecut.id + "_dup",
          clipPath: undefined,
        });

        // Add the cloned object to the canvas
        this.canvas.add(clonedObjectDuplicat);
        this.largestImageObjectToDiecut.set({
          clipPath: workspace,
        });
        this.canvas.add(this.largestImageObjectToDiecut);
      }
      // set new canvas workspace as clip path for all objects
      this.setClipPathForObjects(workspace);
      this.canvas.renderAll();
      this.builder.cutOutline = false;
      this.builder.cutStyle = cutStyle[index];
      this.builder.stickerDetails.name = cutStyle[index];
      this.changeSizeInCm(1);
      this.changeSizeInCm(2);
      if (
        this.largestImageObjectToDiecut.id === "workspace_sw" &&
        clonedObjectDuplicat &&
        this.largestImageObjectToDiecut
      ) {
        this.canvas.centerObject(clonedObjectDuplicat);
        this.canvas.centerObject(this.largestImageObjectToDiecut);
        this.largestImageObjectToDiecut = null;
      }
      if (this.largestImageObjectToDiecut !== null) {
        this.largestImageObjectToDiecut = null;
      }
    }
    this.sheet.dismiss();
  }
  // Add or change clipPart as new canvas when changing canvas shape
  setClipPathForObjects(newClipPath) {
    this.canvas.getObjects().forEach((obj) => {
      if (obj.clipPath && obj.clipPath !== undefined) {
        obj.clipPath = newClipPath;
      }
    });
  }

  removeTransparent() {
    // MIT http://rem.mit-license.org
    const ctx = this.canvas.getContext();
    const copy = document.createElement("canvas").getContext("2d") as any;
    const width = this.canvas.getWidth();
    const height = this.canvas.getHeight();
    const pixels = ctx.getImageData(0, 0, width, height);
    const l = pixels.data.length;
    let i = 0;
    const bound = {
      top: null,
      left: null,
      right: null,
      bottom: null,
    };
    let x = 0;
    let y = 0;

    // Iterate over every pixel to find the highest
    // and where it ends on every axis ()
    for (i = 0; i < l; i += 4) {
      if (pixels.data[i + 3] !== 0) {
        x = (i / 4) % width;
        // tslint:disable-next-line:no-bitwise
        y = ~~(i / 4 / height);

        if (bound.top === null) {
          bound.top = y;
        }

        if (bound.left === null) {
          bound.left = x;
        } else if (x < bound.left) {
          bound.left = x;
        }

        if (bound.right === null) {
          bound.right = x;
        } else if (bound.right < x) {
          bound.right = x;
        }

        if (bound.bottom === null) {
          bound.bottom = y;
        } else if (bound.bottom < y) {
          bound.bottom = y;
        }
      }
    }

    // Calculate the height and width of the content
    // tslint:disable-next-line:one-variable-per-declaration
    let trimHeight: number, trimWidth: number, trimmed: ImageData;
    trimHeight = bound.bottom - bound.top;
    trimWidth = bound.right - bound.left;
    trimmed = ctx.getImageData(bound.left, bound.top, trimWidth, trimHeight);

    copy.canvas.width = trimWidth;
    copy.canvas.height = trimHeight;
    copy.putImageData(trimmed, 0, 0);
    const imgInstance = new fabric.Image(copy.canvas, {});

    // Return trimmed canvas
    this.canvas.add(imgInstance);
    return copy.canvas;
  }

  setFontFamily() {
    this.setActiveProp("fontFamily", this.props.fontFamily);
  }

  // ********************* UNUSED CODE **********************************

  /**
   * Get Ratio of two numbers
   */
  calculateRatio(width, height) {
    for (let num = height; num > 1; num--) {
      if (width % num === 0 && height % num === 0) {
        width = width / num;
        height = height / num;
      }
    }
    return { width, height };
  }

  cropSavedPart() {
    console.log("these are events", this.events);
    return this.canvas.toDataURL({
      left: this.events.left,
      top: this.events.top,
      width: this.events.width,
      height: this.events.height,
    });
  }

  /**
   * Convert Data URL to file
   * @param dataurl Base 64 data URL
   * @param filename name of file
   */

  dataURLtoFile(dataurl, filename) {
    const arr = dataurl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    console.log("array1 ", arr);
    const bStr = atob(arr[1]);
    let n = bStr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bStr.charCodeAt(n);
    }
    this.mFile = new File([u8arr], filename, { type: mime });
    return new File([u8arr], filename, { type: mime });
  }

  saveJuniorPdf() {
    const imageData = this.canvas.toDataURL();
    // const imageData = this.cropSavedPart();

    const image = new Image();
    image.src = imageData;
    const w = window.open("");
    w.document.write(image.outerHTML);
    const doc = new JSPDF("l", "cm");

    console.log("this is size", this.size);
    doc.addImage(image, "png", 0, 0, 30, 15);

    const fd = new FormData(); // To carry on your data
    const files = doc.output("blob");
    // const blob = new Blob([files], {type: 'application/pdf'});
    let getDateTime = new Date().getTime();
    let randomNum = this.getRandomInt(0, 1000000);
    let dynamicName = "SW_order" + randomNum + "" + getDateTime + ".pdf";
    // const blob = new Blob([files], {type: 'application/pdf'});

    const file = new File([files], dynamicName, {
      lastModified: 1534584790000,
      type: "application/pdf",
    });

    console.log("these are files", files);
    fd.append("files", file);
    console.log("this is fd", fd);
    this.api.uploadApi(fd).subscribe(
      (data) => {
        console.log("this is uploaded data", data);
        doc.save();
      },
      (error) => {
        console.log("An error occurred", error);
      }
    );
    console.log("this is the doc", doc);
    // doc.save('saved');
    console.log("saved success");
  }

  getBase64Pdf(imageUrl) {
    // const image = document.createElement('img');
    // image.src = imageUrl;
    // //
    // // const pdfCanvas = document.createElement('canvas');
    // const pdfCanvas = document.getElementById('canvas2') as HTMLCanvasElement;
    // // console.log('this is image **************', image.naturalHeight);
    // pdfCanvas.width = 1500;
    // pdfCanvas.height = 1500;
    // const ctx = pdfCanvas.getContext('2d');
    // image.onload = () => {
    //   setTimeout(() => {
    //     ctx.drawImage(image, 0, 0);
    //     // this.builder.canvas.url = pdfCanvas.toDataURL('image/png');
    //     console.log('this is image link', imageUrl);
    //     const dataURL = pdfCanvas.toDataURL('image/png');
    //     // const base64Code = dataURL.replace(/^data:image\/(png|jpg);base64,/, '');
    //     console.log('this is the base 64 code generated', dataURL);
    //   }, 300);
    // };

    // const newImg = document.createElement('img');
    const newImg = this.renderer.createElement("img");
    const canvasEl = document.createElement("canvas");
    // newImg.crossOrigin = "anonymous";
    newImg.onload = () => {
      console.log(
        "LOADED IMAGE",
        newImg.naturalHeight,
        newImg.naturalWidth,
        newImg.height,
        newImg.width
      );
      const fabCanvas = new fabric.Canvas(this.htmlCanvas2.nativeElement, {
        hoverCursor: "pointer",
        selection: false, // Disable group selection
        selectionBorderColor: "blue",
      });
      fabCanvas.setWidth(newImg.naturalWidth);
      fabCanvas.setHeight(newImg.naturalHeight);
      fabric.Image.fromURL(imageUrl, (img) => {
        img.height = newImg.naturalHeight;
        img.width = newImg.naturalWidth;
        fabCanvas.add(img);
        const dataUrl = fabCanvas.toDataURL({ format: "image/png" });
        console.log("this is data URl >>>>>>>>>>>>>>>>>>", dataUrl);
      });
    };
    newImg.src =
      "https://admin.tarravelho.fi/uploads/captain_removebg_preview_29b2ddedba.png?updated_at=2022-03-11T10:20:47.571Z";

    // console.log('dataurl after adding', dataUrl);
    // newImg.onload  = () => {
    //   setTimeout(() => {
    //     console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');
    //     ctx.drawImage(newImg, 0, 0);
    //   }, 1000);
    //   newImg.src = 'https://cdn.sstatic.net/stackexchange/img/logos/so/so-icon.png';
    // };
  }

  // removeBg(){
  //   this.http.post('https://cb78ca4ab890875fed834f7b8e2d5101.m.pipedream.net', {hello: 'world!'}).subscribe(data => {
  //     console.log('Got it!');
  //   }, error =>  {
  //     console.log('Phat gayi!');
  //   });
  // }

  // async removeBg() {
  //   console.log('BG********', this.files);
  //   console.log('BG********', this.mFile.name);
  //   const formData = new FormData();
  //   formData.append('image_file', this.mFile);
  //   console.log('this is form data', formData);
  //
  //   const a = 'https://cb78ca4ab890875fed834f7b8e2d5101.m.pipedream.net';
  //   const b = 'https://sdk.photoroom.com/v1/segment';
  //   const headers = new HttpHeaders(({
  //     // 'x-api-key': '9d4f78f63d5580980eb5af21cd89a6a928ed0999',
  //     'Content-Type': 'multipart/form-data;'
  //   }));
  //
  //   const header = {
  //     'x-api-key': '9d4f78f63d5580980eb5af21cd89a6a928ed0999'
  //   };
  //   /** In Angular 5, including the header Content-Type can invalidate your request */
  //   // headers.append('Content-Type', 'multipart/form-data');
  //   this.http.post(b, formData, {headers: header, responseType: 'blob' as const}).subscribe(data => {
  //     console.log('this is the data', data);
  //     const objectURL = URL.createObjectURL(data);
  //     this.image = this.sanitizer.bypassSecurityTrustUrl(objectURL);
  //     console.log('this is image', this.image);
  //     const newImage = document.createElement('img');
  //     newImage.id = 'newImage';
  //     newImage.src = this.image;
  //
  //     // this.addImageOnCanvas(this.image);
  //     // this.drawStickerBg(this.image);
  //
  //
  //     const reader = new FileReader();
  //     reader.readAsDataURL(data);
  //     reader.onloadend = () => {
  //       const base64data = reader.result;
  //       console.log('base 64 data', base64data);
  //       const img = new Image();
  //       if (typeof base64data === 'string') {
  //         console.log('yes string found!!'
  //         );
  //         img.src = base64data;
  //         this.imageSrc = base64data;
  //       }
  //       // const newUrl = 'https://litcode.io/assets/logo.png';
  //       //
  //       // const reader = new FileReader();
  //       // reader.readAsDataURL(newUrl);
  //       // reader.onloadend = () => {
  //       //     const base64data = reader.result;
  //       //     console.log('base 64 data', base64data);
  //       //     const img = new Image();
  //       //     if (typeof base64data === 'string') {
  //       //       console.log('yes string found!!'
  //       //       );
  //       //       img.src = base64data;
  //       //     }
  //       this.cutToOutline(base64data);
  //       // this.addImageOnCanvas(newUrl);
  //       // this.removeWhitePart(base64data);
  //     };
  //
  //
  //   }, error => {
  //     console.log('this is the error', error);
  //   });
  // }

  // async removeBg() {
  //   console.log('BG********', this.files);
  //   console.log('BG********', this.mFile.name);
  //   const formData = new FormData();
  //   formData.append('image_file', this.mFile);
  //   console.log('this is form data', formData);
  //
  //   const a = 'https://cb78ca4ab890875fed834f7b8e2d5101.m.pipedream.net';
  //   const b = 'https://sdk.photoroom.com/v1/segment';
  //   const headers = new HttpHeaders(({
  //     // 'x-api-key': '9d4f78f63d5580980eb5af21cd89a6a928ed0999',
  //     'Content-Type': 'multipart/form-data;'
  //   }));
  //
  //   const header = {
  //     'x-api-key': '9d4f78f63d5580980eb5af21cd89a6a928ed0999'
  //   };
  //   /** In Angular 5, including the header Content-Type can invalidate your request */
  //   // headers.append('Content-Type', 'multipart/form-data');
  //   this.http.post(b, formData, {headers: header, responseType: 'blob' as const}).subscribe(data => {
  //     console.log('this is the data', data);
  //     const objectURL = URL.createObjectURL(data);
  //     this.image = this.sanitizer.bypassSecurityTrustUrl(objectURL);
  //     console.log('this is image', this.image);
  //     // const newImage = document.createElement('img');
  //     // newImage.id = 'newImage';
  //     // newImage.src = this.image;
  //     //
  //     // this.addImageOnCanvas(this.image);
  //     // this.drawStickerBg(this.image);
  //
  //
  //     const reader = new FileReader();
  //     reader.readAsDataURL(data);
  //     reader.onloadend = () => {
  //       const base64data = reader.result;
  //       console.log('base 64 data', base64data);
  //       const img = new Image();
  //       if (typeof base64data === 'string') {
  //         console.log('yes string found!!'
  //         );
  //         img.src = base64data;
  //       }
  //       // this.cutToOutline(base64data);
  //       this.canvas.clear();
  //       this.addImageOnCanvas(base64data);
  //       // this.removeWhitePart(base64data);
  //     };
  //
  //
  //   }, error => {
  //     console.log('this is the error', error);
  //   });
  // }

  testStickerEffect(img, grow) {
    // console.log('ticker effect');
    // console.log('this is image height', img.width);
    // console.log('this is image height', img.height);
    const canvas1 = document.getElementById("1");
    console.log("this is canvas 1", canvas1);
    // @ts-ignore
    const ctx1 = canvas1.getContext("2d");
    const canvas2 = document.getElementById("2");
    // @ts-ignore
    const ctx2 = canvas2.getContext("2d");
    console.log("this is canvas 2", canvas2);
    // @ts-ignore
    canvas1.width = canvas2.width = 650 + grow * 2;
    // @ts-ignore
    canvas1.height = canvas2.height = 430 + grow * 2;
    ctx1.drawImage(img, grow, grow);
    ctx2.shadowColor = "white";
    ctx2.shadowBlur = 2;
    for (let i = 0; i < grow; i++) {
      if (i === grow - 2) {
        ctx2.shadowColor = "#1d1d1d";
        ctx2.shadowBlur = 2;
      }
      ctx2.drawImage(canvas1, 0, 0);
      ctx1.drawImage(canvas2, 0, 0);
    }
    ctx2.shadowColor = "rgba(0,0,0,0)";
    console.log("this is image before returning", img);
    setTimeout(() => {
      ctx2.drawImage(img, 0, 0);
    }, 500);
    console.log("this is canvas 2", canvas2);
    return canvas2;
  }

  setFontFamilyJunior(value) {
    this.props.fontFamily = value;
    this.setActiveProp("fontFamily", this.props.fontFamily);
  }
  getFontFamily() {
    this.props.fontFamily = this.getActiveProp("fontFamily");
  }

  // addDeleteIcon() {
  //   // create a rect object
  //   // tslint:disable-next-line:max-line-length
  //   const deleteIcon = 'data:image/svg+xml,%3C%3Fxml version=\'1.0\' encoding=\'utf-8\'%3F%3E%3C!DOCTYPE svg PUBLIC
  //   \'-//W3C//DTD SVG 1.1//EN\' \'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd\'%3E%3Csvg version=\'1.1\' id=\'Ebene_1\' xmlns=\
  //   'http://www.w3.org/2000/svg\' xmlns:xlink=\'http://www.w3.org/1999/xlink\' x=\'0px\' y=\'0px\' width=\'595.275px\' height=\'595
  //   .275px\' viewBox=\'200 215 230 470\' xml:space=\'preserve\'%3E%3Ccircle style=\'fill:%23F44336;\' cx=\'299.76\' cy=\'439.067\' r=
  //   \'218.516\'/%3E%3Cg%3E%3Crect x=\'267.162\' y=\'307.978\' transform=\'matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)\' st
  //   yle=\'fill:white;\' width=\'65.545\' height=\'262.18\'/%3E%3Crect x=\'266.988\' y=\'308.153\' transform=\'matrix(0.7071 0.7071 -0
  //   .7071 0.7071 398.3889 -83.3116)\' style=\'fill:white;\' width=\'65.544\' height=\'262.179\'/%3E%3C/g%3E%3C/svg%3E';
  //
  //   const img = document.createElement('img');
  //   img.src = deleteIcon;
  //
  //   fabric.Object.prototype.transparentCorners = false;
  //   fabric.Object.prototype.cornerColor = 'blue';
  //   fabric.Object.prototype.cornerStyle = 'circle';
  //
  //   // function Add() {
  //   //   var rect = new fabric.Rect({
  //   //     left: 100,
  //   //     top: 50,
  //   //     fill: 'yellow',
  //   //     width: 200,
  //   //     height: 100,
  //   //     objectCaching: false,
  //   //     stroke: 'lightgreen',
  //   //     strokeWidth: 4,
  //   //   });
  //   //
  //   //   canvas.add(rect);
  //   //   canvas.setActiveObject(rect);
  //   // }
  //   fabric.Object.prototype.controls.deleteControl = new fabric.Control({
  //     x: 0.5,
  //     y: -0.5,
  //     offsetY: 16,
  //     cursorStyle: 'pointer',
  //     mouseUpHandler: (event, transform) => {
  //       const target = transform.target;
  //       const canvas = target.canvas;
  //       canvas.remove(target);
  //       canvas.requestRenderAll();
  //     },
  //     render: (ctx, left, top, styleOverride, fabricObject) => {
  //       const size = 24;
  //       ctx.save();
  //       ctx.translate(left, top);
  //       ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
  //       ctx.drawImage(img, -size / 2, -size / 2, size, size);
  //       ctx.restore();
  //     },
  //     cornerSize: 24
  //   });
  //
  //   // Add();
  //
  // }

  checkTransparency(image) {
    let whitePixels = 0;
    let alphaPixels = 0;
    let totalPixels = 0;
    for (let y = 0; y < image.getHeight(); y++) {
      for (let x = 0; x < image.getWidth(); x++) {
        const red = image.getIntComponent0(x, y);
        const green = image.getIntComponent1(x, y);
        const blue = image.getIntComponent2(x, y);
        const alpha = image.getAlphaComponent(x, y);
        totalPixels++;
        // Is white?
        if (red >= 250 && green >= 250 && blue >= 250 && alpha > 0) {
          whitePixels++;
        }

        if (alpha === 0) {
          alphaPixels++;
          // We can either break loop here or can loop on every pixel
          // if we want to continue we can check at the end of the program
          // how many pixels are transparent out of total
        }
      }
    }
    // Just a random number can use
    if (alphaPixels > 10) {
      return true;
    }
  }

  testClipping(url) {
    let clipPart;

    fabric.Image.fromURL(url, (image) => {
      const polygon = getImageOutline(image.getElement());
      console.log("this is polygon", polygon);
      clipPart = new fabric.Polygon(polygon, {
        stroke: "black",
        strokeWidth: 1,
        fill: "rgb(134,124,124)",
        left: Math.round(this.canvas.getWidth() / 2),
        top: 0,
        selectable: false,
      });

      this.createSvg(polygon, image);
      // setTimeout(() => {
      //   this.canvas.backgroundColor = 'rgba(246,246,246,0.5)';
      //   // this.canvas.setBackgroundImage(clipPart, () => {
      //   //   this.canvas.renderAll();
      //   // });
      //   this.canvas.add(clipPart);
      //   console.log('this is canvas polygon', clipPart);
      //   console.log('this is height of clippart', clipPart._calcDimensions());
      //
      // }, 3000);

      // this.canvas.centerObject(image);
    });
    // this.canvas.setZoom(this.canvas.getZoom() / 2);

    // fabric.Image.fromURL('http://placeimg.com/640/480/any', (fimg) => {
    //   this.canvas.add(fimg.set({
    //     left: 0,
    //     top: 0,
    //     width: this.canvas.getWidth(),
    //     height: this.canvas.getHeight(),
    //     globalCompositeOperation: 'source-atop'
    //   }));
    // });

    // this.canvas;
  }
  saveCutPdf() {
    console.log("Timeout starts count upto 5 tiktok", this.sizeInCm);
    setTimeout(() => {
      const imageData = this.canvas.toDataURL(this.cropCutting);
      // const imageData = this.cropSavedPart();
      console.log("this is image data", imageData);
      console.log("this is cutting options", this.cropCutting);
      const image = new Image();
      image.src = imageData;
      const cm = 0.026458333;
      // const cm = 37.7952755906;
      const mode = this.sizeInCm.width > this.sizeInCm.height;
      console.log("I am here loading Image");
      const w = window.open("");
      w.document.write(image.outerHTML);
      const doc = new JSPDF(mode ? "l" : "p", "cm", [
        this.sizeInCm.width,
        this.sizeInCm.height,
      ]);
      console.log("this is size", this.size);
      doc.addImage(
        image,
        "png",
        0,
        0,
        this.sizeInCm.width,
        this.sizeInCm.height
      );
      const fd = new FormData(); // To carry on your data
      const files = doc.output("blob");
      let getDateTime = new Date().getTime();
      let randomNum = this.getRandomInt(0, 1000000);
      let dynamicName = "SW_order" + randomNum + "" + getDateTime + ".pdf";
      // const blob = new Blob([files], {type: 'application/pdf'});
      const file = new File([files], dynamicName, {
        lastModified: 1534584790000,
        type: "application/pdf",
      });
      console.log("these are files", files);
      fd.append("files", file);
      console.log("this is fd", fd);
      doc.save();

      // this.api.uploadApi(fd).subscribe(data => {
      //   console.log('this is uploaded data', data);
      //   doc.save();
      // }, error => {
      //   console.log('An error occurred', error);
      // });
      console.log("this is the doc", doc);
      // doc.save('saved');
      console.log("saved success");
    }, 1000);
  }

  drawEllipse() {
    const workspace = this.canvas
      .getObjects()
      .find((item: any) => item.id === "workspace");
    // if (workspace instanceof fabric.Ellipse) {
    const { left, top, width, height, scaleX, scaleY, rx, ry } =
      workspace as ExtendedFabricEllipse;
    console.log("canvas", this.canvas.getHeight());
    const ellipse = new ExtendedFabricEllipse({
      strokeWidth: 2,
      stroke: "black",
      fill: "transparent",
      selectable: true,
      originX: "center",
      originY: "center",
      rx: width / 2,
      ry: height / 2,
    });
    this.canvas.centerObject(ellipse);

    this.canvas.add(ellipse);
  }

  // Function to show centering buttons
  showCenterButtons() {
    var btnHorizontally = document.getElementById("centerHorizontallyBtn");
    var btnVertically = document.getElementById("centerVerticallyBtn");
    var btnObjDelete = document.getElementById("objectDeleteBtn");
    btnHorizontally.style.display = "flex";
    btnVertically.style.display = "flex";
    btnObjDelete.style.display = "flex";
    // document.querySelectorAll("button").forEach(function (button) {
    //   button.style.display = "inline-block";
    // });
  }

  // Function to hide centering buttons
  hideCenterButtons() {
    var btnHorizontally = document.getElementById("centerHorizontallyBtn");
    var btnVertically = document.getElementById("centerVerticallyBtn");
    var btnObjDelete = document.getElementById("objectDeleteBtn");
    btnHorizontally.style.display = "none";
    btnVertically.style.display = "none";
    btnObjDelete.style.display = "none";
  }

  // Function to position buttons near the selected object

  positionButtons() {
    var activeObject = this.canvas.getActiveObject();
    if (activeObject) {
      /*var boundingBox = activeObject.getBoundingRect();
      var topRightX = boundingBox.left + boundingBox.width;
      var topRightY = boundingBox.top;

      var btnHorizontally = document.getElementById("centerHorizontallyBtn");
      var btnVertically = document.getElementById("centerVerticallyBtn");
      var btnObjDelete = document.getElementById("objectDeleteBtn");

      btnHorizontally.style.left = "75%";
      btnHorizontally.style.top = 30 +"px";

      btnVertically.style.left = "75%";
      btnVertically.style.top = btnVertically.clientHeight + 40 + "px";

      btnObjDelete.style.left = "75%";
      btnObjDelete.style.top = btnVertically.clientHeight + 80 + "px";*/
    }
  }

  // Function to center the selected object horizontally
  centerHorizontally() {
    console.log("calling centerHorizontally");

    var activeObject = this.canvas.getActiveObject();
    if (activeObject) {
      activeObject.centerH();
      this.canvas.renderAll();
      this.positionButtons(); // Re-position buttons after centering
      this.callToSetPositionScale(activeObject);
    }
  }

  // Function to center the selected object vertically
  centerVertically() {
    console.log("calling centerVertically");

    var activeObject = this.canvas.getActiveObject();
    if (activeObject) {
      activeObject.centerV();
      this.canvas.renderAll();
      this.positionButtons(); // Re-position buttons after centering
      this.callToSetPositionScale(activeObject);
    }
  }
}
